import { REFS } from 'enums/external-refs'
import { ROUTE_NAME } from 'enums/routes'
import scss from '@/utils/scss'

const { breakpoints } = scss

function isSideBannerVisible(width) {
  return width >= breakpoints.tablet + 1 // desktopSm start point
}

const HOMEPAGE_SIDE_BANNERS_SETTINGS = {
  refsToWatch: [
    REFS.LAYOUT_PAGE_WRAP,
    REFS.HOMEPAGE_COMBINED_SECTION,
    REFS.HOMEPAGE_VIDEOS_SECTION
  ],
  watchCondition: ({ windowWidth, route } = {}) => {
    if (!process.client) return false

    if (!windowWidth || !route) {
      console.error(
        'sideBannersMovement - watchCondition() - windowWidth and route are not passed. Do not watch data-refs.'
      )
      return false
    }

    const isRouteAllowed = route.name === ROUTE_NAME.HOMEPAGE
    const isWindowWidthAllowed = isSideBannerVisible(windowWidth)

    return isRouteAllowed && isWindowWidthAllowed
  }
}

const ARTICLE_ROUTE_NAMES = [
  ROUTE_NAME.CATEGORY_SUBCATEGORY,
  ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE,
  ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE
]

const ARTICLE_PAGE_SIDE_BANNERS_SETTINGS = {
  refsToWatch: [
    REFS.ARTICLE_PAGE_CONTENT,
    REFS.ARTICLE_PAGE_MORE_FROM_THE_AUTHOR,
    REFS.ARTICLE_PAGE_CATEGORY_NEWS
  ],
  watchCondition: ({ windowWidth, route } = {}) => {
    if (!process.client) return false

    if (!windowWidth || !route) {
      console.error(
        'sideBannersMovement - watchCondition() - windowWidth and route are not passed. Do not watch data-refs.'
      )
      return false
    }

    const isRouteAllowed = ARTICLE_ROUTE_NAMES.includes(route.name)
    const isWindowWidthAllowed = isSideBannerVisible(windowWidth)

    return isRouteAllowed && isWindowWidthAllowed
  }
}

const ARTICLE_PAGE_NEWSLETTER_SETTINGS = {
  refsToWatch: [REFS.HEADER_MOBILE_EXPANDED_MENU],
  watchCondition: ({ windowWidth, route, userDetails } = {}) => {
    if (!process.client || !windowWidth || !userDetails) return false

    const isWindowWidthAllowed = windowWidth <= breakpoints.mobile
    const isRouteAllowed = ARTICLE_ROUTE_NAMES.includes(route.name)
    const isUserLoggedIn = !!userDetails.Id
    const isUserSubscribed = userDetails.HasFmNewsletterSubscriptions

    return (
      isRouteAllowed &&
      isWindowWidthAllowed &&
      (!isUserLoggedIn || !isUserSubscribed)
    )
  }
}

export const DATA_REFS_WATCH_SETTINGS = [
  HOMEPAGE_SIDE_BANNERS_SETTINGS,
  ARTICLE_PAGE_SIDE_BANNERS_SETTINGS,
  ARTICLE_PAGE_NEWSLETTER_SETTINGS
]
