import { checkIfMiddlewareShouldBeSkipped } from '@/middleware/helpers'
import { importRedirectSettings } from '@/utils/helpers/migratedRedirects/cache'
import { MIDDLEWARE } from 'enums/middleware'
import { ROUTE } from 'enums/routes'
import { asyncRedirectHandler } from '@/plugins/helper'
import { REDIRECT_TYPE_CODES } from 'enums/seo-shared'

export default async function(ctx) {
  const { app, store, route, redirect } = ctx
  try {
    const isMiddlewareSkipped =
      app.$helper.isMiddlewareSkipped(MIDDLEWARE.MIGRATED_REDIRECTS, route) ||
      checkIfMiddlewareShouldBeSkipped(route.path, route)

    if (isMiddlewareSkipped) {
      return redirect()
    }

    const fullPath = route.fullPath || ROUTE.HOMEPAGE

    let redirectSettings

    if (process.client) {
      redirectSettings = await store.dispatch(
        'seo/requestMigratedRedirectSettings',
        {
          queryParams: { fullPath }
        }
      )
    } else {
      const getRedirectSettings = await importRedirectSettings()

      redirectSettings = await getRedirectSettings({ fullPath })
    }

    if (!redirectSettings) {
      return redirect()
    }

    const { to, type } = redirectSettings

    await asyncRedirectHandler(ctx, {
      type: REDIRECT_TYPE_CODES[type],
      redirectToUrl: to
    })
  } catch (err) {
    redirect()
  }
}
