import { removeEndingSlashes } from '@fmpedia/helpers'
import { HTTP_STATUS_CODE, ERROR_MESSAGE } from 'enums/errorCodes'

const PATH_TO_SHOW_410_ERROR_PAGE = [
  '/career/financial-news-editor-institutional'
]

export default function(ctx) {
  const path = removeEndingSlashes(ctx.route.path).toLowerCase()

  if (PATH_TO_SHOW_410_ERROR_PAGE.includes(path)) {
    ctx.error({
      statusCode: HTTP_STATUS_CODE.GONE,
      userMessage: ERROR_MESSAGE.PAGE_NOT_FOUND
    })
  }
}
