export const SET_AUTH_COOKIE_PATH = '/api/set-auth-cookie'
export const SET_OTHER_COOKIE_PATH = '/api/set-cookie'
export const PROLONGATE_GA_COOKIE_LIFETIME =
  '/api/prolongate-ga-cookie-lifetime'
export const SET_DEVICE_ID = '/api/set-device-id'

/**
 * Server middleware configuration
 * */
export default [
  '~/server/api',
  '~/server/api/error-handler',
  '~/server/api/auth.js',
  '~/server/api/gtm-analytics.js',
  '~/server/api/amp-analytics.js',
  '~/server/api/robots-txt.js',
  {
    path: PROLONGATE_GA_COOKIE_LIFETIME,
    handler: '~/server/api/prolongate-ga-cookie-lifetime.js'
  },
  {
    path: SET_AUTH_COOKIE_PATH,
    handler: '~/server/api/cross-domain-auth-cookie.js'
  },
  {
    path: SET_OTHER_COOKIE_PATH,
    handler: '~/server/api/cross-domain-other-cookie.js'
  },
  {
    path: SET_DEVICE_ID,
    handler: '~/server/api/set-device-id.js'
  },
  '~/server/api/feed.js',
  '~/server/api/tv-feed.js',
  '~/server/api/migrated-redirects.js',
  '~/server/api/cache-control-no-cache.js',
  '~/server/api/submit-lead.js'
]
