import { checkIfMiddlewareShouldBeSkipped } from '@/middleware/helpers'
import { compareRoutes } from '@/plugins/helper'

export default function({ store: { dispatch }, redirect, from, route }) {
  if (process.server || checkIfMiddlewareShouldBeSkipped(from, route)) {
    return redirect()
  }

  const { isOnlyHashChanged } = compareRoutes(from, route)

  if (isOnlyHashChanged) return

  dispatch('requestHeaderMenuItems')
  dispatch('requestFooterMenuItems')
  dispatch('requestStayConnected')
}
