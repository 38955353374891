/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'newsletter-rect-blue': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data:
      '<defs><linearGradient id="svgicon-newsletter-rect-blue-a" x1="50%" x2="50%" y1="0%" y2="99.021%"><stop offset="0%" stop-opacity="0"/><stop offset="80%" stop-opacity=".02"/><stop offset="100%" stop-opacity=".04"/></linearGradient><linearGradient id="svgicon-newsletter-rect-blue-b" x1="50%" x2="50%" y1="0%" y2="100%"><stop offset="0%" stop-color="#FFF" stop-opacity=".12"/><stop offset="20%" stop-color="#FFF" stop-opacity=".06"/><stop offset="100%" stop-color="#FFF" stop-opacity="0"/></linearGradient></defs><g _fill="none" fill-rule="evenodd" stroke-linejoin="square" stroke-width=".5"><path pid="0" _fill="#69F" _stroke="url(#svgicon-newsletter-rect-blue-a)" d="M.25.25v34.5h34.5V.25H.25zm23.313 24.82a2.683 2.683 0 0 0 2.68 2.68v.5H10.93a3.183 3.183 0 0 1-3.18-3.18V7.75h15.813v17.32zm-8.743.297v-.672h-4.226v.672h4.226zm0-2.344v-5.359h-4.226v5.36h4.226zm0-7.03v-.673h-4.226v.672h4.226zm5.899 9.374v-.672h-4.227v.672h4.227zm0-2.344v-.671h-4.227v.671h4.227zm0-2.343v-.672h-4.227v.672h4.227zm0-2.344v-.672h-4.227v.672h4.227zm0-2.344v-.672h-4.227v.672h4.227zm0-2.344v-.671H10.594v.671h10.125zm0-2.343v-.672H10.594v.672h10.125zm5.187 13.32h.672V8.922h1.672V25.07a2.01 2.01 0 0 1-2.008 2.008 2.01 2.01 0 0 1-2.008-2.008V8.922h1.672v15.703zm-14.64-6.29h2.882v4.017h-2.882v-4.016z"/><path pid="1" _stroke="url(#svgicon-newsletter-rect-blue-b)" d="M.25.25v34.5h34.5V.25H.25zm23.313 24.82a2.683 2.683 0 0 0 2.68 2.68v.5H10.93a3.183 3.183 0 0 1-3.18-3.18V7.75h15.813v17.32zm-8.743.297v-.672h-4.226v.672h4.226zm0-2.344v-5.359h-4.226v5.36h4.226zm0-7.03v-.673h-4.226v.672h4.226zm5.899 9.374v-.672h-4.227v.672h4.227zm0-2.344v-.671h-4.227v.671h4.227zm0-2.343v-.672h-4.227v.672h4.227zm0-2.344v-.672h-4.227v.672h4.227zm0-2.344v-.672h-4.227v.672h4.227zm0-2.344v-.671H10.594v.671h10.125zm0-2.343v-.672H10.594v.672h10.125zm5.187 13.32h.672V8.922h1.672V25.07a2.01 2.01 0 0 1-2.008 2.008 2.01 2.01 0 0 1-2.008-2.008V8.922h1.672v15.703zm-14.64-6.29h2.882v4.017h-2.882v-4.016z"/></g>'
  }
})
