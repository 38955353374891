const directoryMe = {
  slug: 'd/me',
  followCompanies: () => `${directoryMe.slug}/followings/companies`,
  unfollowCompanies: () => `${directoryMe.slug}/followings/companies`,
  deleteDirectorySavedArticle: articleId =>
    `${directoryMe.slug}/saved-articles/${articleId}`,
  getCompanies: () => `${directoryMe.slug}/companies`
}

export default directoryMe
