import { ROUTE_NAME } from 'enums/routes'

const OMITTED_ROUTES = [
  ROUTE_NAME.INTELLIGENCE_PRODUCTS_QIR,
  ROUTE_NAME.TAG,
  ROUTE_NAME.AUTHOR
]

const BREADCRUMB_BY_ROUTE_NAME = {
  [ROUTE_NAME.TERMS_OF_USE]: 'Terms, Cookies and Privacy Notice',
  [ROUTE_NAME.PRIVACY_POLICY]: 'Privacy Notice',
  [ROUTE_NAME.TAG_SEARCH_RESULTS]: 'Tag Search results'
}

function generateRouteObject(isDynamic, prevRoute, routeName, value) {
  return {
    routeName: (prevRoute ? `${prevRoute.routeName}-` : '') + routeName,
    name: BREADCRUMB_BY_ROUTE_NAME[routeName] || value.replace(/-/g, ' '),
    params: {
      ...(prevRoute ? prevRoute.params : {}),
      ...(isDynamic ? { [routeName]: value } : {})
    }
  }
}

function generateBreadcrumbs(route) {
  if (!route.matched.length) return []

  const { path } = route.matched[route.matched.length - 1]

  const pathsList = route.fullPath
    .slice(1)
    .replace(/\?.*$/g, '')
    .split('/')

  return path
    .replace(/\?$/, '')
    .split('/')
    .filter(Boolean)
    .reduce((arr, val, index) => {
      const isDynamic = val.charAt(0) === ':'
      const routeName = isDynamic ? val.slice(1) : val
      const prevRoute = arr.length ? arr[arr.length - 1] : null
      const route = generateRouteObject(
        isDynamic,
        prevRoute,
        routeName,
        pathsList[index]
      )

      return [...arr, route]
    }, [])
}

export default function({ to, store: { dispatch } }) {
  try {
    const breadcrumbs = generateBreadcrumbs(to).filter(({ routeName }) => {
      const routeRegEx = new RegExp(`^${routeName}\\??$`, 'gi')
      return !OMITTED_ROUTES.some(route => route.match(routeRegEx))
    })
    dispatch('breadcrumbs/setupBreadcrumbs', breadcrumbs)
  } catch (e) {
    console.error('breadcrumbs', e)
  }
}
