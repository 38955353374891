import breadcrumbsAfterEach from '@/utils/afterEach/breadcrumbs'
import triggerGaPageViewEvent from '@/utils/afterEach/trigger-ga-page-view-event'
import setPageNavigationEnd from '@/utils/afterEach/set-page-navigation-end'
import setHeaderDataToStateAfterEach from '@/utils/afterEach/set-header-data-to-state'

export default function({ app, store }) {
  app.router.afterEach((to, from) => {
    breadcrumbsAfterEach({ to, store })
    triggerGaPageViewEvent({ app, store, to, from })
    setPageNavigationEnd({ store })
    setHeaderDataToStateAfterEach({ store })
  })
}
