import { mapActions } from 'vuex'
import { EBOOK_LOCATION } from 'shared/AEbook/enums'

export default {
  data() {
    return {
      ebookDataFetch: {
        actionsByEbookLocation: {
          [EBOOK_LOCATION.ARTICLE]: this.requestArticleEbook
        }
      }
    }
  },
  methods: {
    ...mapActions({
      requestArticleEbook: 'articles/requestArticleEbook'
    }),
    async $_ebookDataFetch_getEbook(location, payload) {
      try {
        if (!location) return null

        const getEbookHandler = this.$_ebookDataFetch_getEbookAction(location)
        const ebookData = await getEbookHandler(...(payload ? [payload] : []))

        return this.$_ebookDataFetch_formatEbookData(ebookData)
      } catch (err) {
        return null
      }
    },
    $_ebookDataFetch_getEbookAction(location) {
      return this.ebookDataFetch.actionsByEbookLocation[location]
    },
    $_ebookDataFetch_formatEbookData(ebook) {
      if (!ebook) return null

      const formSettings = {
        id: ebook.Id,
        isFirstNameVisible: ebook.IsFirstNameVisible,
        isFirstNameRequired: ebook.IsFirstNameRequired,
        isLastNameVisible: ebook.IsLastNameVisible,
        isLastNameRequired: ebook.IsLastNameRequired,
        buttonText: ebook.FormButtonText,
        gdpr: ebook.Gdpr,
        sponsorGdpr: ebook.SponsorGdpr,
        newsletterSubscriptionText: ebook.NewsletterSubscriptionText,
        ringCaptchaEnabled: ebook.RingCaptchaEnabled
      }

      const successMessage = {
        Title: ebook.SuccessMessageTitle,
        Subtitle: ebook.SuccessMessageText
      }

      return {
        title: ebook.Title,
        subTitle: ebook.SubTitle,
        description: ebook.Description,
        image: ebook.Image,
        video: ebook.Video,
        videoUrl: ebook.VideoUrl,
        logo: ebook.Logo,
        buttonText: ebook.ButtonText,
        formSettings,
        successMessage,
        ...this.$attrs
      }
    }
  }
}
