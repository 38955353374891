const articles = {
  slug: 'f/articles',
  getArticleBySlug: () => `${articles.slug}`,
  getArticlePreview: previewId => `${articles.slug}/preview/${previewId}`,
  deleteArticlePreview: previewId => `${articles.slug}/preview/${previewId}`,
  getRelatedArticlesBySlug: () => `${articles.slug}/related`,
  getPreviewArticleEbook: previewId =>
    `${articles.slug}/preview/${previewId}/ebook`,
  getFmArticles: () => `${articles.slug}/homepage`
}

export default articles
