export const REFS = {
  CONTENT_WRAPPER: 'content-wrapper',
  CONTENT_BODY: 'content-body',
  CONTACT_WIDGET: 'contact-widget-item',
  BANNER: 'banner',
  ONE_SIGNAL_DESKTOP: 'one-signal-desktop',
  ONE_SIGNAL_MOBILE: 'one-signal-mobile',
  ONE_SIGNAL_ICON: 'one-signal-icon',
  LOGIN_ICON_WRAPPER: 'login-icon-wrapper',
  EBOOK_WRAPPER: 'ebook-wrapper',
  EBOOK_DUMMY_WRAPPER: 'ebook-dummy-wrapper',
  TERM_WRAPPER: 'term-wrapper',
  NEWSLETTER_BUTTON: 'newsletter-button',
  ASSISTANT_BUTTON_HEADER_WRAPPER: 'assistant-button-header-wrapper',
  NEWSLETTER_HEADER_BUTTON_WRAPPER: 'newsletter-header-button-wrapper',
  ASSISTANT_BUTTON_HOMEPAGE_WRAPPER: 'assistant-button-homepage-wrapper',
  DIRECTORY_SECTION_COMPANIES: 'directory-section-companies',
  TOP_VIDEOS_SECTION: 'top-videos-sections',
  HEADER_ITEM_EDUCATION: 'header-item-education',
  HEADER_BURGER_MENU_BUTTON: 'header-burger-menu-button',
  HEADER_MOBILE_EXPANDED_MENU: 'header-mobile-expanded-menu',
  LAYOUT_PAGE_WRAP: 'layout-page-wrap',
  LAYOUT_TOP_STICKY_ELEMENT: 'layout-top-sticky-element',
  HOMEPAGE_COMBINED_SECTION: 'homepage-combined-section',
  HOMEPAGE_VIDEOS_SECTION: 'homepage-videos-section',
  ARTICLE_PAGE_SIDEBAR: 'article-page-sidebar',
  ARTICLE_PAGE_SIDEBAR_LATEST_NEWS: 'article-page-sidebar-latest-news',
  ARTICLE_PAGE_FEATURED_VIDEOS: 'article-page-featured-videos',
  ARTICLE_PAGE_DISQUS_WIDGET: 'article-page-disqus-widget',
  ARTICLE_PAGE_CONTENT: 'article-page-content',
  ARTICLE_PAGE_MORE_FROM_THE_AUTHOR: 'article-page-more-from-the-author',
  ARTICLE_PAGE_CATEGORY_NEWS: 'article-page-category-news',
  AUTH_FORM_SOCIAL_FACEBOOK_BTN: 'auth-form-social-facebook-btn',
  ARTICLE_PAGE_NEWSLETTER_WIDGET: 'article-page-newsletter-widget'
}
