import * as types from '@/store/mutation-types'

const state = () => ({
  isFacebookApiInitialized: false,
  isGoogleApiInitialized: false,
  isSwiperInstalled: false
})

const getters = {
  isFacebookApiInitialized: state => state.isFacebookApiInitialized,
  isGoogleApiInitialized: state => state.isGoogleApiInitialized,
  isSwiperInstalled: state => state.isSwiperInstalled
}

const mutations = {
  [types.SET_FACEBOOK_API_INITIALIZED](state) {
    state.isFacebookApiInitialized = true
  },
  [types.SET_SWIPER_AS_INSTALLED](state) {
    state.isSwiperInstalled = true
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
