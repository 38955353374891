/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope-rect-blue': {
    width: 35,
    height: 35,
    viewBox: '0 0 35 35',
    data:
      '<path pid="0" _fill="#69F" fill-rule="evenodd" d="M0 0h35v35H0V0zm8.396 11.154c2.602 2.204 7.17 6.082 8.511 7.29.18.164.374.247.574.247.2 0 .394-.082.573-.245 1.344-1.21 5.91-5.088 8.513-7.292a.395.395 0 0 0 .056-.545 1.57 1.57 0 0 0-1.24-.609H9.58a1.57 1.57 0 0 0-1.24.609.396.396 0 0 0 .056.545zm18.338 1.194a.394.394 0 0 0-.421.057c-1.705 1.445-3.95 3.354-5.644 4.811a.392.392 0 0 0-.138.306.398.398 0 0 0 .148.302c1.574 1.26 3.946 2.994 5.658 4.226a.392.392 0 0 0 .411.031.395.395 0 0 0 .215-.351v-9.023a.396.396 0 0 0-.23-.359zM8.626 22.05c1.713-1.232 4.085-2.966 5.658-4.226a.395.395 0 0 0 .011-.608c-1.694-1.457-3.94-3.366-5.645-4.81a.393.393 0 0 0-.65.301v9.023a.394.394 0 0 0 .625.32zm17.853 1.075c-1.654-1.183-4.7-3.39-6.52-4.865a.395.395 0 0 0-.508.009c-.357.31-.655.573-.867.764-.65.587-1.554.587-2.206-.001-.21-.19-.51-.453-.866-.764a.393.393 0 0 0-.508-.008c-1.813 1.47-4.863 3.68-6.52 4.865a.399.399 0 0 0-.163.282.4.4 0 0 0 .104.308c.3.322.72.507 1.155.507h15.803c.435 0 .855-.185 1.155-.507a.396.396 0 0 0-.059-.59z"/>'
  }
})
