import { DFP_BANNER, DFP_BANNER_MOCKED } from 'enums/banners/dfp-banner'
import { printScssBannerSettings } from '@/utils/helpers/banners'

/**
 * This breakpoint was introduced by the requirement to display sky banners of 160px width where
 * it is possible considering the obligatory 50px of distance between banner and the content.
 * It was decided not to put this breakpoint into "breakpoint" enum since it is only related
 * to sky banners.
 */
export const WIDE_SKY_BANNERS_BREAKPOINT_NAME = 'wide-sky-banners'
export const WIDE_SKY_BANNERS_BREAKPOINT_VALUE = 1970

export const getInsideListBannerSettingsKey = number =>
  `INSIDE_LIST_BANNER_${number}`

export const getBetweenRelatedQirBannerSettingsKey = number =>
  `BETWEEN_RELATED_QIR_BANNER_${number}`

const TOP_SIDE = {
  mobile: DFP_BANNER.TOP_SIDE_320x100,
  tablet: DFP_BANNER.TOP_SIDE_750x280,
  desktopSm: DFP_BANNER.TOP_SIDE_980x280,
  desktopMd: DFP_BANNER.TOP_SIDE_1140x280,
  desktopLg: DFP_BANNER.TOP_SIDE_1140x280,
  desktopXl: DFP_BANNER.TOP_SIDE_1140x280,
  aboveDesktopXl: DFP_BANNER.TOP_SIDE_1140x280,
  [WIDE_SKY_BANNERS_BREAKPOINT_NAME]: DFP_BANNER.TOP_SIDE_1920x368
}

const RIGHT_SIDE_1 = {
  desktopSm: DFP_BANNER.RIGHT_SIDE_1_160x600,
  desktopMd: DFP_BANNER.RIGHT_SIDE_1_300x600,
  desktopLg: DFP_BANNER.RIGHT_SIDE_1_300x600,
  desktopXl: DFP_BANNER.RIGHT_SIDE_1_300x600,
  aboveDesktopXl: DFP_BANNER.RIGHT_SIDE_1_300x600
}

const RIGHT_SIDE_2 = {
  desktopSm: DFP_BANNER.RIGHT_SIDE_2_160x600,
  desktopMd: DFP_BANNER.RIGHT_SIDE_2_300x600,
  desktopLg: DFP_BANNER.RIGHT_SIDE_2_300x600,
  desktopXl: DFP_BANNER.RIGHT_SIDE_2_300x600,
  aboveDesktopXl: DFP_BANNER.RIGHT_SIDE_2_300x600
}

export const BANNER_SETTINGS = {
  HOMEPAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    CATEGORY_1: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50
    },
    CATEGORY_2: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50
    },
    LATEST_NEWS: {
      mobile: DFP_BANNER.CUSTOM_BOX_1_300x250,
      tablet: DFP_BANNER.CUSTOM_BOX_1_250x180,
      desktopSm: DFP_BANNER.CUSTOM_BOX_1_250x180,
      desktopMd: DFP_BANNER.CUSTOM_BOX_1_250x180,
      desktopLg: DFP_BANNER.CUSTOM_BOX_1_215x155,
      desktopXl: DFP_BANNER.CUSTOM_BOX_1_250x180,
      aboveDesktopXl: DFP_BANNER.CUSTOM_BOX_1_250x180
    },
    MOST_VIEWED: {
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    DIRECTORY: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50
    },
    TOP_VIDEOS: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    INTERSTITIAL: {
      mobile: DFP_BANNER.INTERSTITIAL_300x250
    },
    MPU_BANNER: {
      mobile: DFP_BANNER.MPU_1_300x250,
      tablet: DFP_BANNER.MPU_1_300x250,
      desktopSm: DFP_BANNER.MPU_1_300x250,
      desktopMd: DFP_BANNER.MPU_1_300x250,
      desktopLg: DFP_BANNER.MPU_1_300x250,
      desktopXl: DFP_BANNER.MPU_1_300x250,
      aboveDesktopXl: DFP_BANNER.MPU_1_300x250
    }
  },
  ALL_NEWS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_4_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_728x90
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_5_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_728x90
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    }
  },
  ABOUT_US: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    // not implemented section
    CAREERS_SECTION_BANNER: {
      mobile: DFP_BANNER_MOCKED.MOCKED_320X50
    },
    OFFICE_LOCATION_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50
    },
    BEFORE_OUR_TEAM: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    CONTACT_US_TOP_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50
    },
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    }
  },
  TERMS_HP: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    }
  },
  TERMS_LETTER: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    }
  },
  TERM_PAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    MIDDLE_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_468x60,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_4_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_728x90
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_5_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_728x90
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    }
  },
  AUTHOR_PAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    STICKY_TO_TOP: {
      mobile: DFP_BANNER.TOP_LEADERBOARD_320x50
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_4_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_728x90
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_5_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_728x90
    }
  },
  EVENTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    }
  },
  CONTACT_US: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  SEARCH_RESULTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    STICKY_TO_TOP: {
      mobile: DFP_BANNER.TOP_LEADERBOARD_320x50
    },
    BEFORE_NEWS: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    BEFORE_TERMS: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    AFTER_TERMS: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    }
  },
  BECOME_AN_AUTHOR: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50
    }
  },
  TAG_PAGE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    STICKY_TO_TOP: {
      mobile: DFP_BANNER.TOP_LEADERBOARD_320x50
    },
    [getInsideListBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getInsideListBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    },
    [getInsideListBannerSettingsKey(3)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50,
      tablet: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_3_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_3_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_3_728x90
    },
    [getInsideListBannerSettingsKey(4)]: {
      mobile: DFP_BANNER.LEADERBOARD_4_320x50,
      tablet: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_4_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_4_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_4_728x90
    },
    [getInsideListBannerSettingsKey(5)]: {
      mobile: DFP_BANNER.LEADERBOARD_5_320x50,
      tablet: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_5_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_5_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_5_728x90
    }
  },
  TAG_SEARCH_RESULTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  TERMS_SEARCH_RESULTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  PERSONAL_AREA: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2
  },
  ARTICLE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BOTTOM_BANNER: {
      tablet: DFP_BANNER.LEADERBOARD_1_320x50,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    MPU_BANNER_INSIDE_1_ARTICLE: {
      mobile: DFP_BANNER.MPU_1_300x250,
      tablet: DFP_BANNER.MPU_1_300x250,
      desktopSm: DFP_BANNER.MPU_1_300x250,
      desktopMd: DFP_BANNER.MPU_1_300x250,
      desktopLg: DFP_BANNER.MPU_1_300x250,
      desktopXl: DFP_BANNER.MPU_1_300x250,
      aboveDesktopXl: DFP_BANNER.MPU_1_300x250
    },
    MPU_BANNER_INSIDE_2_ARTICLE: {
      mobile: DFP_BANNER.MPU_2_300x250,
      tablet: DFP_BANNER.MPU_2_300x250,
      desktopSm: DFP_BANNER.MPU_2_300x250,
      desktopMd: DFP_BANNER.MPU_2_300x250,
      desktopLg: DFP_BANNER.MPU_2_300x250,
      desktopXl: DFP_BANNER.MPU_2_300x250,
      aboveDesktopXl: DFP_BANNER.MPU_2_300x250
    },
    STICKY_TO_BOTTOM: {
      mobile: DFP_BANNER.MOBILE_STICKY_320x50
    },
    INTERSTITIAL: {
      mobile: DFP_BANNER.INTERSTITIAL_300x250,
      tablet: DFP_BANNER.INTERSTITIAL_300x250,
      desktopSm: DFP_BANNER.INTERSTITIAL_970x330,
      desktopMd: DFP_BANNER.INTERSTITIAL_970x330,
      desktopLg: DFP_BANNER.INTERSTITIAL_970x330,
      desktopXl: DFP_BANNER.INTERSTITIAL_970x330,
      aboveDesktopXl: DFP_BANNER.INTERSTITIAL_970x330
    },
    LOCKER_DOME_BANNER_BELOW_CONTENT: {
      mobile: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      tablet: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopSm: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopMd: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopLg: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      desktopXl: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID,
      aboveDesktopXl: DFP_BANNER.ARTICLE_BELOW_CONTENT_FLUID
    }
  },
  EDUCATION_CATEGORY: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    MIDDLE_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    BOTTOM_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50,
      tablet: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_2_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_2_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_2_728x90
    }
  },
  INTELLIGENCE: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    MIDDLE_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    }
  },
  INTELLIGENCE_ANNUAL_SUBSCRIPTION: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BEFORE_RELATED_QIR_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    },
    [getBetweenRelatedQirBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50
    },
    [getBetweenRelatedQirBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50
    }
  },
  INTELLIGENCE_PRODUCTS: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    MIDDLE_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50,
      tablet: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopSm: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopMd: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopLg: DFP_BANNER.LEADERBOARD_1_728x90,
      desktopXl: DFP_BANNER.LEADERBOARD_1_728x90,
      aboveDesktopXl: DFP_BANNER.LEADERBOARD_1_728x90
    }
  },
  INTELLIGENCE_SINGLE_QIR: {
    TOP_SIDE,
    RIGHT_SIDE_1,
    RIGHT_SIDE_2,
    BEFORE_RELATED_QIR_BANNER: {
      mobile: DFP_BANNER.LEADERBOARD_1_320x50
    },
    [getBetweenRelatedQirBannerSettingsKey(1)]: {
      mobile: DFP_BANNER.LEADERBOARD_2_320x50
    },
    [getBetweenRelatedQirBannerSettingsKey(2)]: {
      mobile: DFP_BANNER.LEADERBOARD_3_320x50
    }
  }
  //   TEST: page =>
  //     Object.keys(DFP_BANNER[page]).reduce(
  //       (acc, bannerKey) => ({
  //         ...acc,
  //         [bannerKey]: {
  //           mobile: DFP_BANNER[page][bannerKey],
  //           tablet: DFP_BANNER[page][bannerKey],
  //           desktopSm: DFP_BANNER[page][bannerKey],
  //           desktopMd: DFP_BANNER[page][bannerKey],
  //           desktopLg: DFP_BANNER[page][bannerKey],
  //           desktopXl: DFP_BANNER[page][bannerKey],
  //           aboveDesktopXl: DFP_BANNER[page][bannerKey]
  //         }
  //       }),
  //       {}
  //     )
}

const isDevelopment = process.env.NODE_ENV === 'development'
if (isDevelopment && process.client) {
  printScssBannerSettings(BANNER_SETTINGS)
}

/** The code below allows to verify all the needed banners class names **/
// const bannerClassesSet = new Set()
// const BANNER_CLASSES = Object.entries(BANNER_SETTINGS).reduce(
//   (acc, [page, pageSettings]) => ({
//     ...acc,
//     [page]: Object.entries(pageSettings).reduce(
//       (acc, [banner, bannerSettings]) => ({
//         ...acc,
//         [banner]: Object.entries(bannerSettings).reduce(
//           (acc, [breakpoint, breakpointBannerSetting]) => {
//             const [bannerWidth, bannerHeight] = breakpointBannerSetting.sizes
//
//             const className = `${breakpoint}_${bannerWidth}${
//               bannerHeight ? `x${bannerHeight}` : ''
//             }`
//             bannerClassesSet.add(className)
//
//             return `${acc} ${className}`
//           },
//           ''
//         )
//       }),
//       {}
//     )
//   }),
//   {}
// )
// console.log('allUniqueBannerClasses', bannerClassesSet)
