import * as types from '@/store/mutation-types'

export const state = () => ({
  isArticleOnboardingInProgress: false,
  isHomepageOnboardingInProgress: false
})

export const getters = {
  isArticleOnboardingInProgress: state => state.isArticleOnboardingInProgress,
  isHomepageOnboardingInProgress: state => state.isHomepageOnboardingInProgress,
  isOnboardingInProgress: state =>
    [
      state.isArticleOnboardingInProgress,
      state.isHomepageOnboardingInProgress
    ].some(inProgress => inProgress)
}

export const mutations = {
  [types.SET_IS_ARTICLE_ONBOARDING_IN_PROGRESS](state, value) {
    state.isArticleOnboardingInProgress = value
  },
  [types.SET_IS_HOMEPAGE_ONBOARDING_IN_PROGRESS](state, value) {
    state.isHomepageOnboardingInProgress = value
  }
}
