export const initialState = {
  profileSummary: {
    AnnouncementCount: null,
    ContributionCount: null,
    DirectoryBusinessNotificationCount: null,
    FollowedTagCount: null,
    FollowedAuthorCount: null,
    FollowedCompanyCount: null,
    FollowedCategoryCount: null,
    NotificationCount: null,
    SavedArticleCount: null
  },
  profileSettings: {
    Authors: [],
    Companies: [],
    IsSubscribedToAnnouncement: false,
    ParentCategories: [],
    Tags: []
  }
}

export const getters = {
  profileSummary: state => state.profileSummary,
  profileSettings: state => state.profileSettings
}
