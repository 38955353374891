import { SPECIAL_CATEGORY } from 'enums/special-categories'

export const getters = {
  checkIfThoughtLeadershipSlug: () => slug =>
    SPECIAL_CATEGORY.THOUGHT_LEADERSHIP === (slug || '').toLowerCase()
  /**
   * catName (catName_FMpedia) is a key for google banner targeting.
   * We use it to assign category to banners. It depends on article parent category.
   * @returns {String}
   */
}
