import _throttle from 'lodash.throttle'
import { pathOr } from 'ramda'
import { mapGetters } from 'vuex'

import errorMessages from '@/utils/validators/error-messages'
import {
  getPasswordValidationMessage,
  isValidYoutubeVideoUrl
} from '@fmpedia/validators'
import { ROUTE_NAME } from 'enums/routes'
import { DEFAULT_FALLBACK_LINK } from '@/utils/enums/links'
import { findMigratedCategorySlug } from 'enums/fl/fl-categories'
import { processResponse } from '@/plugins/helper'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { YOUTUBE_URL } from 'enums/youtube'
import { THROTTLE_DEFAULT_DURATION } from 'enums/settings'
import { setHeaderDataToState } from '@/utils/helpers/getHeaderData'
import { HEADER_SCROLL_OFFSET } from 'enums/header-scroll-offset'
import {
  generateArticlePath,
  generateCategoryPath,
  generateAuthorPath,
  generateFmDirCompanyUrl,
  generateFlArticlePath,
  generateFlAuthorPath,
  generateFlCompanyPath,
  generateFlUrl,
  generateFmDirArticlePath,
  generateFmDirUrl,
  generateFmUrl,
  generateTagPath
} from '@/utils/helpers/url-generators'

export const windowWidth = {
  mixins: [hydrationHelpers],
  data() {
    return {
      throttledWindowSizeCheck: null,
      throttledOnScrollHandler: null
    }
  },
  computed: {
    ...mapGetters({
      isAppleBrowser: 'isAppleBrowser',
      previousScrollTop: 'previousScrollTop'
    }),
    currentMobileBottomPadding() {
      if (!this.$_hydrationHelpers_isLayoutMobileOrTablet) {
        return 0
      }

      const iosBottomPadding = 75
      const androidBottomPadding = 56
      return this.isAppleBrowser ? iosBottomPadding : androidBottomPadding
    }
  },
  methods: {
    checkWindowWidthAndHeight() {
      if (process.browser) {
        const windowWidth = this.$helper.getWindowWidth()
        const windowHeight = this.$helper.getWindowHeight()
        this.$store.dispatch('setWindowWidth', windowWidth)
        this.$store.dispatch('setWindowHeight', windowHeight)
        this.updateMobileBottomPadding()
      }
    },
    onScrollHandler() {
      const scrollTop = this.$helper.getWindowScrollTop()
      this.$store.dispatch('setScrollTop', scrollTop)

      setHeaderDataToState({ store: this.$store })
      sessionStorage.setItem('savedPosition', scrollTop)
    },
    updateMobileBottomPadding() {
      this.$store.dispatch(
        'setMobileBottomPadding',
        this.currentMobileBottomPadding
      )
    },
    handleFullScreenElements() {
      const fullscreenElement = [
        document.fullscreenElement,
        document.fullscreen,
        document.webkitIsFullScreen,
        document.webkitFullscreenElement,
        document.msFullscreenElement
      ].filter(v => v !== undefined)[0]

      if (!fullscreenElement) {
        /**
         * previousScrollTop value is used to restore scroll position after
         * exiting from YouTube fullscreen mode (or any other fullscreen DOM element)
         * Details: Youtube scrolls the page to top when entering fullscreen mode.
         * We handle "fullscreenchange" event, but when we get scroll position after
         * fullscreen is emitted, scroll position is already 0.
         * That's why we save previous value to restore scroll position after exiting
         * fullscreen mode (it is handled by windowWidth mixin).
         */
        window.scrollTo(0, this.previousScrollTop)
      }
    }
  },
  mounted() {
    this.throttledWindowSizeCheck = _throttle(
      this.checkWindowWidthAndHeight,
      THROTTLE_DEFAULT_DURATION
    )
    this.throttledOnScrollHandler = _throttle(
      this.onScrollHandler,
      THROTTLE_DEFAULT_DURATION
    )

    this.throttledWindowSizeCheck()
    this.throttledOnScrollHandler()

    window.addEventListener('resize', this.throttledWindowSizeCheck, {
      passive: true
    })
    window.addEventListener(
      'scroll',
      () => {
        this.throttledOnScrollHandler()
      },
      true
    )
    window.addEventListener('fullscreenchange', this.handleFullScreenElements)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.throttledWindowSizeCheck, true)
    window.removeEventListener('scroll', this.throttledOnScrollHandler, true)
    window.removeEventListener(
      'fullscreenchange',
      this.handleFullScreenElements
    )
  }
}

export const urlGenerators = {
  methods: {
    generateArticlePath,
    generateArticleUrl(article, ctx) {
      return this.generateFmUrl(generateArticlePath(article))
    },
    generateFlArticleUrl(article) {
      return this.generateFlUrl(generateFlArticlePath(article))
    },
    generateFlCategoryUrl(categorySlug) {
      if (!categorySlug) return '#'

      const oldCategorySlug = findMigratedCategorySlug(categorySlug)
      const link = oldCategorySlug ? `/${oldCategorySlug}` : `/${categorySlug}/`

      return this.generateFlUrl(link)
    },
    generateFlCompanyUrl(company) {
      return this.generateFlUrl(generateFlCompanyPath(company))
    },
    generateFlAuthorUrl(authorSlug) {
      return this.generateFlUrl(generateFlAuthorPath(authorSlug))
    },
    generateFmDirArticleUrl(article) {
      return this.generateFmDirUrl(generateFmDirArticlePath(article))
    },
    generateCategoryPath,
    generateTagPath,
    generateTermRoute(term = {}) {
      if (!term) return DEFAULT_FALLBACK_LINK

      const { slug } = processResponse(term)
      if (!slug) return DEFAULT_FALLBACK_LINK

      return {
        name: ROUTE_NAME.TERMS_LETTER_SLUG,
        params: { letter: slug.charAt(0), slug }
      }
    },
    generateTermUrl(term = {}) {
      if (!term) return DEFAULT_FALLBACK_LINK

      const { Slug: slug } = term
      if (!slug) return DEFAULT_FALLBACK_LINK

      return `${this.$env.DOMAIN_URL}/terms/${slug.charAt(0)}/${slug}/`
    },
    generateAuthorPath,
    generateCompanyUrl(company) {
      return generateFmDirCompanyUrl(company, this)
    },
    generateFmDirSearchResultUrl({ URL }) {
      if (!URL) return null

      return `${this.$env.FM_DIR_DOMAIN_URL}${this.$helper.addStartingSlash(
        URL
      )}`
    },
    generateFlDirSearchResultUrl({ URL }) {
      if (!URL) return null

      return `${this.$env.FL_DOMAIN_URL}${this.$helper.addStartingSlash(URL)}`
    },
    generateFmUrl(link) {
      return generateFmUrl(link, this)
    },
    generateFmDirUrl(link) {
      return generateFmDirUrl(link, this)
    },
    generateFlUrl(link) {
      return generateFlUrl(link, this)
    }
  }
}
/* eslint-disable */

const YOUTUBE_ID_LENGTH = 11

function getYoutubeVideoId(url) {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|shorts\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  const id = (match && match[2]) || ''

  return id.length === YOUTUBE_ID_LENGTH ? id : null
}

function formatIdToEmbedUrl(id) {
  return `https://www.youtube.com/embed/${id}`
}

function isYoutubeUrl(url) {
  return YOUTUBE_URL.URL_REGEXP.test(url)
}

export const urlFormatters = {
  methods: {
    formatIdToEmbedUrl,
    isYoutubeUrl,
    isValidYoutubeVideoUrl,
    convertYoutubeUrlToEmbedUrl(url) {
      if (isYoutubeUrl(url)) {
        const id = getYoutubeVideoId(url)
        return formatIdToEmbedUrl(id)
      }
      return url
    },
    getYoutubeVideoId,
    /**
     * This method is used to refresh images with the same url (i.e. user Photo)
     * It can work inside computed property together with getter of PhotoUrl
     * @param url
     * @returns {*|string}
     */
    getActualPhotoUrl(url) {
      return url && !this.$helper.isFile(url)
        ? this.$helper.forceUrlFetch(url)
        : url
    }
  }
}

function getMessage({ errorType }) {
  /**
   * searchResult contains array of results.
   * From developer.mozilla.org:
   * If the string matches the expression, it will return an Array containing the entire
   * matched string as the first element, followed by any results captured in parentheses.
   * If there were no matches, null is returned.
   */
  const searchResult = errorType.match(this.regExp)
  return searchResult ? this.message.replace('${}', searchResult[1]) : ''
}

function getPasswordMessage({ errorType, error }) {
  if (errorType === 'password') {
    const password = pathOr('', ['$params', errorType, 'password'], error)
    return getPasswordValidationMessage(password)
  }
  return ''
}

/* eslint-enable */
export const validation = {
  data() {
    return {
      dynamicValidationTypes: [
        {
          regExp: /minLength\(([^)]+)\)/,
          message: 'This field should not be less than ${} characters',
          getMessage
        },
        {
          regExp: /maxLength\(([^)]+)\)/,
          message: 'This field should not exceed ${} characters',
          getMessage
        },
        {
          regExp: /minCount\(([^)]+)\)/,
          message: 'This field should contain at least ${} elements',
          getMessage
        },
        {
          regExp: /maxCount\(([^)]+)\)/,
          message: 'This field should not contain more than ${} elements',
          getMessage
        },
        {
          getMessage: getPasswordMessage
        }
      ]
    }
  },
  computed: {},
  methods: {
    $_validation_hasValidationError(error = this.error) {
      return error && error.$error
    },
    $_validation_errorMessages(error = this.error) {
      if (error && !this.$_validation_hasValidationError(error)) {
        return []
      }
      const errorParams = []
      /* eslint-disable */
      if (error.$params !== undefined && Object.keys(error.$params).length) {
        for (const param in error.$params) {
          if (error.$params.hasOwnProperty(param) && error[param] === false) {
            errorParams.push(param)
          }
        }
      }
      /* eslint-enable */
      return this.$_validation_generateErrors({ errorParams, error })
    },
    $_validation_generateErrors({ errorParams = [], error }) {
      const errors = errorMessages
      const staticErrorMessages = errors
        .filter(m => errorParams.indexOf(m.type) > -1)
        .map(e => e.message)
      let dynamicErrorMessages = []
      errorParams.forEach(errorType => {
        const result = this.$_validation_generateDynamicErrorMessage({
          errorType,
          error
        })
        if (result.length) {
          dynamicErrorMessages = [...dynamicErrorMessages, ...result]
        }
      })
      return [...staticErrorMessages, ...dynamicErrorMessages]
    },
    $_validation_generateDynamicErrorMessage({ errorType, error }) {
      return this.dynamicValidationTypes
        .map(dynamicError => dynamicError.getMessage({ errorType, error }))
        .filter(searchResult => searchResult !== '')
    }
  }
}

export const setPageLoaded = {
  methods: {
    $_setPageLoaded_setStatus(status) {
      this.$store.dispatch('setPageLoaded', status)
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$_setPageLoaded_setStatus(true)
    })
  }
}

export const initialScroll = {
  mounted() {
    const selector = this.$route.hash || this.$route.params.scrollTo
    if (selector) {
      const offset = this.$route.params.offset || HEADER_SCROLL_OFFSET
      setTimeout(() => {
        this.$scrollTo(selector, 1000, {
          offset
        })
      }, 1000)
    }
  }
}
