import * as types from '~/store/mutation-types'
import {
  COOKIE_CATEGORY,
  OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME
} from 'enums/oneTrust'
import { isArray, isString } from '@/plugins/helper'
import Cookies from 'js-cookie'

function wasConsentOptedOut(newConsent, oldConsent) {
  if (!oldConsent || !newConsent) return false

  /**
   * We do not reload a page in case if rejected category is relevant
   * to IAB Vendors
   */
  const oldConsentFormatted = oldConsent.replace(COOKIE_CATEGORY.STACK_42, '')
  const newConsentFormatted = newConsent.replace(COOKIE_CATEGORY.STACK_42, '')

  return oldConsentFormatted
    .split(',')
    .some(group => !newConsentFormatted.includes(group))
}

export const state = () => ({
  optanonActiveGroups: COOKIE_CATEGORY.STRICTLY_NECESSARY,
  previousOptanonActiveGroups: COOKIE_CATEGORY.STRICTLY_NECESSARY,
  executeHandlersOnConsentChange: [],
  wasOptanonAlertBoxClosed: false
})

export const getters = {
  optanonActiveGroups: state => state.optanonActiveGroups,
  previousOptanonActiveGroups: state => state.previousOptanonActiveGroups,
  executeHandlersOnConsentChange: state => state.executeHandlersOnConsentChange,
  wasOptanonAlertBoxClosed: state => state.wasOptanonAlertBoxClosed
}

export const actions = {
  updateOptanonConsentActiveGroups({ dispatch, commit }, groupsArr) {
    if (!isArray(groupsArr)) return

    const groupsString =
      groupsArr.join(',') || COOKIE_CATEGORY.STRICTLY_NECESSARY

    commit(
      `one-trust/${types.UPDATE_OPTANON_CONSENT_ACTIVE_GROUPS}`,
      groupsString,
      { root: true }
    )
    commit(
      `one-trust/${types.UPDATE_OPTANON_ALERT_BOX_IS_CLOSED_STATE}`,
      !!Cookies.get(OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME),
      { root: true }
    )

    dispatch(`one-trust/executeHandlersOnConsentChange`, null, {
      root: true
    })
  },
  executeHandlersOnConsentChange({ commit, getters }) {
    const wasPreviouslyAcceptedCookieRejected = wasConsentOptedOut(
      getters.optanonActiveGroups,
      getters.previousOptanonActiveGroups
    )

    /**
     * We purposefully reload a page when there was an opt-out action for any
     * cookie category. The referrer is going to be lost in this case due to the
     * programmatic reload.
     * (Approved by the client)
     */
    if (wasPreviouslyAcceptedCookieRejected) {
      window.location.reload()
      return
    }

    const {
      handlersDataToExecute,
      handlersDataToKeep
    } = getters.executeHandlersOnConsentChange.reduce(
      (acc, oneTrustHandler) => {
        if (oneTrustHandler.isConsentGiven()) {
          acc.handlersDataToExecute.push(oneTrustHandler)
        } else {
          acc.handlersDataToKeep.push(oneTrustHandler)
        }

        return acc
      },
      {
        handlersDataToExecute: [],
        handlersDataToKeep: []
      }
    )

    handlersDataToExecute.forEach(oneTrustHandler => oneTrustHandler.execute())

    commit(
      `one-trust/${types.UPDATE_HANDLERS_TO_EXECUTE_ON_CONSENT_CHANGE}`,
      handlersDataToKeep,
      { root: true }
    )
  },
  registerHandlerThatRequiresConsent({ commit }, oneTrustHandler) {
    if (oneTrustHandler.isConsentGiven()) {
      oneTrustHandler.execute()
      return
    }

    commit(
      `one-trust/${types.REGISTER_HANDLER_TO_EXECUTE_ON_CONSENT_CHANGE}`,
      oneTrustHandler,
      { root: true }
    )
  }
}

export const mutations = {
  [types.REGISTER_HANDLER_TO_EXECUTE_ON_CONSENT_CHANGE](
    state,
    oneTrustHandler
  ) {
    state.executeHandlersOnConsentChange.push(oneTrustHandler)
  },
  [types.UPDATE_HANDLERS_TO_EXECUTE_ON_CONSENT_CHANGE](state, handlersData) {
    state.executeHandlersOnConsentChange = handlersData
  },
  [types.REMOVE_LOCAL_HANDLERS_ON_ROUTE_CHANGE](state) {
    state.executeHandlersOnConsentChange = state.executeHandlersOnConsentChange.filter(
      oneTrustHandler => !oneTrustHandler.isLocal
    )
  },
  [types.REMOVE_HANDLERS_FOR_ENTITY_TYPE](state, entityType) {
    state.executeHandlersOnConsentChange = state.executeHandlersOnConsentChange.filter(
      oneTrustHandler => oneTrustHandler.entityType !== entityType
    )
  },
  [types.SET_OPTANON_CONSENT_ACTIVE_GROUPS_FROM_COOKIE](state, cookieValue) {
    if (!cookieValue || !isString(cookieValue)) return

    const consentData = cookieValue?.split('&').reduce((acc, curVal) => {
      const [key, val] = curVal.split('=') || []
      acc[key] = val

      return acc
    }, {})

    const groupsData = consentData.groups

    if (!groupsData) return

    /**
     * When groups value is extracted, it has the following format:
     * "C0001:1,C0002:1,C0003:0,C0004:1,C0005:1,V2STACK42:1"
     * Based on this information, we have to save groups the
     * consent has been given to.
     * (where :1 - consent was given; :0 - consent was not given)
     */
    const activeGroups = groupsData
      .split(',')
      .map(group => {
        if (!group) return null

        const groupValueWithConsent = group.split(':')
        const groupValue = groupValueWithConsent[0]
        const isConsentGivenForGroup = !!+groupValueWithConsent[1]

        if (isConsentGivenForGroup) {
          return groupValue
        } else {
          return null
        }
      })
      .filter(group => group)

    if (!activeGroups.length) return

    state.optanonActiveGroups = activeGroups.join(',')
    state.previousOptanonActiveGroups = state.optanonActiveGroups
  },
  [types.UPDATE_OPTANON_CONSENT_ACTIVE_GROUPS](state, value) {
    state.previousOptanonActiveGroups = state.optanonActiveGroups
    state.optanonActiveGroups = value
  },
  [types.UPDATE_OPTANON_ALERT_BOX_IS_CLOSED_STATE](state, value) {
    state.wasOptanonAlertBoxClosed = value
  }
}
