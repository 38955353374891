import { pathOr, pipe } from 'ramda'
import axios from 'axios'
import https from 'https'

import {
  isString,
  addEndingSlash,
  removeStartingSlash,
  removeEndingSlashes
} from '@fmpedia/helpers'
import { isHrefValid } from '@fmpedia/validators'

import { checkIfLinkHasProtocol, guid, parseUrl } from '../utils/helpers/index'

export { guid, parseUrl, checkIfLinkHasProtocol }

/* @fmpedia/helpers */
export { isString }

export function getUrlHost(url) {
  if (!url) return null

  try {
    return new URL(url).host
  } catch (err) {
    return null
  }
}

export function generateFmUrl(link) {
  if (!link) return process.env.FM_DOMAIN_URL

  const linkWithoutStartSlash = removeStartingSlash(link)
  return checkIfLinkHasProtocol(linkWithoutStartSlash)
    ? link
    : `${process.env.FM_DOMAIN_URL}/${linkWithoutStartSlash}`
}

const AXIOS_OPTIONS = {
  responseType: 'document',
  httpsAgent: new https.Agent({
    rejectUnauthorized: false
  })
}
const AXIOS_HEADER = { 'Accept-Language': 'en-US' }

export function axiosGet(url, params) {
  const fullApiUrl = `${process.env.API_URL}/api/${url}`

  return axios.get(fullApiUrl, { ...AXIOS_OPTIONS, params }, AXIOS_HEADER)
}

export function removePaginationPartFromPath(path) {
  return path.replace(/\/page\/\d+\/*$/i, '')
}

export function removeAmpSuffix(str = '') {
  if (!str) return ''

  const { address: path, hash, query } = parseUrl(str)

  const pathWithoutAmpSuffix = addEndingSlash(path).replace(/\/amp\/$/, '/')

  return `${pathWithoutAmpSuffix}${query || ''}${hash || ''}`
}

function getPathParamsArrayFromString(str) {
  return pipe(
    removePaginationPartFromPath,
    removeAmpSuffix,
    removeEndingSlashes,
    removeStartingSlash
  )(str).split('/')
}

export function getPreLastPathParam(str) {
  const pathParamsArray = getPathParamsArrayFromString(str)

  if (pathParamsArray.length < 2) return null

  return pathParamsArray[pathParamsArray.length - 2]
}

export function getLastPathParam(str) {
  return getPathParamsArrayFromString(str).pop()
}

export function randomIntFromInterval(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export function generateGaCookie() {
  return `GA1.2.${generateClientId()}`
}

function generateClientId() {
  const MIN_RANDOM_INT = 100000000
  const MAX_RANDOM_INT = 999999999

  const randomInt = randomIntFromInterval(MIN_RANDOM_INT, MAX_RANDOM_INT)
  const dateInSeconds = Math.floor(+new Date() / 1000)

  return `${randomInt}.${dateInSeconds}`
}

export function parseClientIdFromGaCookie(gaCookie) {
  const COMMON_GA_COOKIE_PARTS_NUMBER = 4

  const arr = gaCookie.split('.')

  if (arr.length === COMMON_GA_COOKIE_PARTS_NUMBER) {
    return arr.slice(2).join('.')
  }

  return gaCookie
}

export function generateDeviceId() {
  return `${guid()}-${+new Date()}`
}

export function encodeUrl(url) {
  if (!url) return url

  return encodeURI(decodeURI(url).replace(/\u200e|\u200f/g, ''))
}

function testHrefValidator() {
  const hrefValidationTests = [
    { href: 'https://google.com', isValid: true },
    { href: 'http://google.com', isValid: true },
    { href: 'ftp://google.com', isValid: true },
    { href: '//google.com', isValid: true },
    { href: '/all-news', isValid: true },
    { href: '#', isValid: true },
    { href: '#anchor', isValid: true },
    { href: 'mailto:some@email.com', isValid: true },
    { href: 'mailto:some@gibberish', isValid: false },
    { href: 'mailto:', isValid: false },
    { href: 'discord://some-data', isValid: true },
    { href: 'all-news', isValid: false },
    { href: 'google.com', isValid: false },
    { href: 'Minister: how are you', isValid: false },
    { href: '', isValid: false }
  ]
  const results = hrefValidationTests.reduce((acc, { href, isValid }) => {
    const isValidJsLib = isHrefValid(href)
    return {
      ...acc,
      [href]: {
        isValidExpected: isValid,
        isValidActual: isValidJsLib,
        Passed: isValidJsLib === isValid
      }
    }
  }, {})
  if (Object.values(results).some(({ Passed }) => Passed === false)) {
    console.log('== server/helper.js - isHrefValid() helper test(s) failed ==')
    console.table(results)
  }
}

if (process.env.NODE_ENV === 'development') {
  testHrefValidator()
}

export function getBackendErrorCode(err) {
  return (
    pathOr(null, ['response', 'data', 'ErrorCode'], err) ||
    pathOr(null, ['response', 'data', 'Code'], err)
  )
}

export function getBackendErrorEvidence(err) {
  return pathOr(null, ['response', 'data', 'Evidence'], err)
}
