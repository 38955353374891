/**
 * Before the component is mounted, modifying DOM based on windowWidth or
 * windowHeight will lead to DOM mismatch errors.
 * To avoid that, always use helpers provided in this mixin
 **/

import { mapGetters, mapMutations } from 'vuex'
import scss from '@/utils/scss'
import { isArray, isObject } from '@/plugins/helper'
import { isNil } from 'ramda'
import {
  HEADER_HEIGHT,
  HEADER_STATE,
  TOP_HEADER_STRIP_HEIGHT
} from 'enums/header'
import {
  SET_IS_SCROLLBAR_HIDDEN,
  SET_SCROLL_TOP_ON_HIDE_SCROLL
} from '@/store/mutation-types'
import { setHeaderDataToState } from '@/utils/helpers/getHeaderData'

const { breakpoints, breakpointNames } = scss

const INITIAL_WIDTH = 0
const INITIAL_HEIGHT = 0

const NO_SCROLL_CLASS_NAME = 'noscroll'

const LAYOUTS = {
  MOBILE: 'mobile',
  TABLET: 'tablet',
  DESKTOP: 'desktop'
}

const MENU_HEIGHT_BY_LAYOUT = {
  [HEADER_STATE.COLLAPSED]: {
    [LAYOUTS.MOBILE]: HEADER_HEIGHT.MOBILE,
    [LAYOUTS.TABLET]: HEADER_HEIGHT.TABLET + TOP_HEADER_STRIP_HEIGHT,
    [LAYOUTS.DESKTOP]: HEADER_HEIGHT.DESKTOP_COLLAPSED + TOP_HEADER_STRIP_HEIGHT
  },
  [HEADER_STATE.EXPANDED]: {
    [LAYOUTS.MOBILE]: HEADER_HEIGHT.MOBILE,
    [LAYOUTS.TABLET]: HEADER_HEIGHT.TABLET + TOP_HEADER_STRIP_HEIGHT,
    [LAYOUTS.DESKTOP]: HEADER_HEIGHT.DESKTOP_EXPANDED + TOP_HEADER_STRIP_HEIGHT
  }
}

export const hydrationHelpers = {
  data() {
    return {
      isMounted: false
    }
  },
  computed: {
    ...mapGetters({
      _windowWidth: 'windowWidth',
      _windowHeight: 'windowHeight',
      _currentHeaderState: 'currentHeaderState',
      _scrollTopOnHideScroll: 'scrollTopOnHideScroll',
      _scrollTop: 'scrollTop',
      _isHeaderAtTop: 'isHeaderAtTop',
      _isScrollbarHidden: 'isScrollbarHidden'
    }),
    $_hydrationHelpers_windowWidth() {
      return this.isMounted ? this._windowWidth : INITIAL_WIDTH
    },
    $_hydrationHelpers_windowHeight() {
      return this.isMounted ? this._windowHeight : INITIAL_HEIGHT
    },
    $_hydrationHelpers_currentLayout() {
      if (this.$_hydrationHelpers_isLayoutMobile) return LAYOUTS.MOBILE

      if (this.$_hydrationHelpers_isVisibleOnRange([breakpoints.tablet])) {
        return LAYOUTS.TABLET
      }

      return LAYOUTS.DESKTOP
    },
    $_hydrationHelpers_menuHeight() {
      return MENU_HEIGHT_BY_LAYOUT[this._currentHeaderState][
        this.$_hydrationHelpers_currentLayout
      ]
    },
    $_hydrationHelpers_isLayoutMobile() {
      return process.browser && this.$_hydrationHelpers_windowWidth
        ? this.$_hydrationHelpers_windowWidth <= scss.breakpoints.mobile
        : true
    },
    $_hydrationHelpers_isLayoutPortrait() {
      return (
        this.$_hydrationHelpers_windowHeight >
        this.$_hydrationHelpers_windowWidth
      )
    },
    $_hydrationHelpers_isLayoutMobileOrTablet() {
      return this.$_hydrationHelpers_isVisibleOnRange([
        breakpoints.mobile,
        breakpoints.tablet
      ])
    }
  },
  methods: {
    ...mapMutations({
      setScrollTopOnHideScroll: SET_SCROLL_TOP_ON_HIDE_SCROLL,
      setIsScrollbarHidden: SET_IS_SCROLLBAR_HIDDEN
    }),
    $_hydrationHelpers_mobileOrDesktopValue(mobileVal, desktopVal) {
      return this.$_hydrationHelpers_isLayoutMobile ? mobileVal : desktopVal
    },
    $_hydrationHelpers_showScroll() {
      const htmlClassList = document.querySelector('html').classList
      const bodyClassList = document.querySelector('body').classList
      const classList = [...htmlClassList, ...bodyClassList]

      if (!classList.includes(NO_SCROLL_CLASS_NAME)) return

      this.setIsScrollbarHidden(false)

      const isMobile = this.$_hydrationHelpers_isLayoutMobile
      document.querySelector('html').classList.remove(NO_SCROLL_CLASS_NAME)
      document.querySelector('body').classList.remove(NO_SCROLL_CLASS_NAME)

      const globalWrapper = document.querySelector('.global-wrapper')
      if (!globalWrapper) return

      globalWrapper.style.marginTop = 0
      if (isMobile) {
        window.scrollTo(0, this._scrollTopOnHideScroll)
      }
    },
    $_hydrationHelpers_hideScroll() {
      if (this._isScrollbarHidden) return

      setHeaderDataToState({ store: this.$store })

      this.setIsScrollbarHidden(true)

      this.$nextTick(
        this.$_hydrationHelpers_handlePageWrapperPositionOnHideScroll
      )
    },
    $_hydrationHelpers_handlePageWrapperPositionOnHideScroll() {
      this.setScrollTopOnHideScroll(this._scrollTop)
      document.querySelector('body').classList.add('noscroll')

      if (this.$_hydrationHelpers_isLayoutMobile) {
        document.querySelector('html').classList.add('noscroll')

        const globalWrapper = document.querySelector('.global-wrapper')
        if (!globalWrapper) return

        globalWrapper.style.marginTop = `${-this._scrollTop}px`
      }
    },
    $_hydrationHelpers_getCurrentBreakpoint() {
      return Object.entries(breakpoints).reduceRight(
        (acc, [k, v]) => {
          if (this.$_hydrationHelpers_windowWidth <= v) {
            acc = {
              name: k,
              value: v
            }
          }

          return acc
        },
        {
          name: breakpointNames.aboveDesktopXl,
          value: breakpoints.aboveDesktopXl
        }
      )
    },
    $_hydrationHelpers_isVisibleFrom(breakpointValue) {
      if (isNil(breakpointValue)) {
        throw Error('Wrong breakpoint type')
      }

      return (
        this.$_hydrationHelpers_getCurrentBreakpoint().value >= breakpointValue
      )
    },
    $_hydrationHelpers_isHiddenFrom(breakpointValue) {
      return !this.$_hydrationHelpers_isVisibleFrom(breakpointValue)
    },
    $_hydrationHelpers_isVisibleOnRange(breakpointRange) {
      if (!isArray(breakpointRange)) {
        throw Error('Wrong breakpoint range type')
      }

      return breakpointRange.includes(
        this.$_hydrationHelpers_getCurrentBreakpoint().value
      )
    },
    /**
     * This function can be used to implement more flexible requirements per specific breakpoint
     * than "mobileOrDesktopValue" offers
     * Example options:
     * 1)
     * {
     *   [this.$breakpoint.desktopXl]: '1',
     *   [this.$breakpoint.mobile]: '2'
     * }
     * this is the equivalent of using mobileOrDesktopValue('2', '1')
     *
     * 2)
     * {
     *   [this.$breakpoint.desktopLg]: '1',
     *   [this.$breakpoint.desktopMd]: '2',
     *   [this.$breakpoint.mobile]: '3'
     * }
     *
     * rendered result per breakpoint/range:
     * - desktopXl          | null
     * - desktopLg          | '1'
     * - tablet - desktopMd | '2'
     * - mobile             | '3'
     *
     * The expected result of output corresponds to the way we operate with CSS media queries (desktop first)
     */
    $_hydrationHelpers_getValueByBreakpoint(options) {
      if (
        !options ||
        !isObject(options) ||
        !process.client ||
        !this.$_hydrationHelpers_windowWidth
      ) {
        return null
      }

      const optionKeys = Object.keys(options)
      const isOptionsInvalid = optionKeys
        .map(option => Object.values(breakpoints).includes(+option))
        .some(v => !v)

      if (isOptionsInvalid) return null

      const currentBreakpoint = this.$_hydrationHelpers_getCurrentBreakpoint()
        .value
      const targetBreakpointsSortedDesc = optionKeys
        .sort((a, b) => b - a)
        .map(b => +b)

      const maxTargetBreakpoint = targetBreakpointsSortedDesc[0]

      // check if current breakpoint is not covered by the options
      if (currentBreakpoint > maxTargetBreakpoint) return null

      const resultingBreakpoint = targetBreakpointsSortedDesc.reduce(
        (acc, curVal) => (curVal >= currentBreakpoint ? curVal : acc)
      )

      return options[resultingBreakpoint] || null
    }
  },
  mounted() {
    this.isMounted = true
  }
}
