import { MEDIA_CENTER_SCOPE } from 'enums/media-center'

export const COMPONENT_TYPE = {
  FM: 'FM',
  FM_DIR: 'FMDirectory',
  FL: 'FL',
  FL_DIR: 'FLDirectory'
}

export const COMPONENT_TYPE_BY_MEDIA_CENTER_SCOPE = {
  [MEDIA_CENTER_SCOPE.FM]: COMPONENT_TYPE.FM,
  [MEDIA_CENTER_SCOPE.FM_DIR]: COMPONENT_TYPE.FM_DIR,
  [MEDIA_CENTER_SCOPE.FL]: COMPONENT_TYPE.FL,
  [MEDIA_CENTER_SCOPE.FL_DIR]: COMPONENT_TYPE.FL_DIR
}
