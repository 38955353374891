import apiV2 from '@/utils/apiV2'
import * as types from './mutation-types'
import { GROUP_TYPE, STATIC_PAGE } from '~/utils/enums/seo'
import { cachedRequest } from '@/utils/jobRequestApiMethods'
import { ROUTE } from 'enums/routes'
import { prettifyPath } from '@/plugins/helper'
import { generateHomepageTitle } from '@/plugins/custom-generate-head'
import { API_VERSION } from 'enums/api'

const ROUTES_WITH_CACHED_SEO_REDIRECT = [ROUTE.HOMEPAGE, ROUTE.ALL_NEWS]
const ROUTE_PATHS_WITH_CACHED_SEO_REDIRECT = ROUTES_WITH_CACHED_SEO_REDIRECT.map(
  route => prettifyPath(route)
)

const ROUTES_WITH_CACHED_SEO = [ROUTE.ALL_NEWS]
const ROUTE_PATHS_WITH_CACHED_SEO = ROUTES_WITH_CACHED_SEO.map(route =>
  prettifyPath(route)
)

const PAGE_SEO_MODEL = {
  MetaTitle: '',
  MetaDescription: '',
  Directives: '',
  Keyphrases: []
}

export const generateInternalApiUrl = (ctx, path) => {
  return `${ctx.$env.DOMAIN_URL}/${path}`
}

export const state = () => ({
  pageSeo: { ...PAGE_SEO_MODEL },
  errorPageSeo: { ...PAGE_SEO_MODEL },
  homepageSeo: { ...PAGE_SEO_MODEL },
  ogSiteName: ''
})

export const getters = {
  pageSeo: state => state.pageSeo,
  errorPageSeo: state => state.errorPageSeo,
  homepageSeo: state => state.homepageSeo,
  ogSiteName: state => state.ogSiteName
}

export const actions = {
  async requestRedirect(_, { queryParams }) {
    try {
      const isCachedRequest = ROUTE_PATHS_WITH_CACHED_SEO_REDIRECT.includes(
        queryParams.from
      )

      let res
      if (isCachedRequest) {
        res = await cachedRequest({
          method: this.$axiosV2.get,
          args: [apiV2.seo.getPageRedirect(), { params: queryParams }],
          apiVersion: API_VERSION.V2
        })
      } else {
        res = await this.$axiosV2.get(apiV2.seo.getPageRedirect(), {
          params: queryParams
        })
      }

      return res.data || {}
    } catch (err) {
      throw err
    }
  },
  async requestPageSeo({ commit }, { queryParams }) {
    try {
      const isCachedRequest =
        queryParams.possibleTypes[0] === GROUP_TYPE.STATIC &&
        ROUTE_PATHS_WITH_CACHED_SEO.includes(prettifyPath(queryParams.value))

      let res
      if (isCachedRequest) {
        res = await cachedRequest({
          method: this.$axiosV2.get,
          args: [apiV2.seo.getPageSettings(), { params: queryParams }],
          apiVersion: API_VERSION.V2
        })
      } else {
        res = await this.$axiosV2.get(apiV2.seo.getPageSettings(), {
          params: queryParams
        })
      }

      commit(types.SET_PAGE_SEO, res.data)
    } catch (err) {
      throw err
    }
  },
  async requestErrorPageSeo({ commit }) {
    try {
      const queryParams = {
        possibleTypes: [GROUP_TYPE.STATIC],
        value: STATIC_PAGE.ERROR
      }

      const { data } = await cachedRequest({
        method: this.$axiosV2.get,
        args: [
          apiV2.seo.getPageSettings(),
          {
            params: queryParams
          }
        ],
        apiVersion: API_VERSION.V2
      })

      commit(types.SET_ERROR_PAGE_SEO, data)
    } catch (err) {
      throw err
    }
  },
  async requestHomepageSeo({ commit }) {
    try {
      const queryParams = {
        possibleTypes: [GROUP_TYPE.STATIC],
        value: STATIC_PAGE.HOMEPAGE
      }

      const { data } = await cachedRequest({
        method: this.$axiosV2.get,
        args: [
          apiV2.seo.getPageSettings(),
          {
            params: queryParams
          }
        ],
        apiVersion: API_VERSION.V2
      })

      commit(types.SET_HOMEPAGE_SEO, data)
      commit(types.SET_OG_SITE_NAME, data.MetaTitle)
    } catch (err) {
      throw err
    }
  },
  async requestMigratedRedirectSettings(_, { queryParams }) {
    if (!process.client) {
      throw new Error(
        'seo/requestMigratedRedirectSettings action must not be called from the server-side!'
      )
    }

    try {
      const requestUrl = generateInternalApiUrl(
        this,
        'api/get-migrated-redirect-by-path'
      )

      const { data } = await this.$axiosV2.get(requestUrl, {
        params: queryParams
      })

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_PAGE_SEO](state, seo) {
    state.pageSeo = seo || {}
  },
  [types.SET_ERROR_PAGE_SEO](state, seo) {
    state.errorPageSeo = seo || {}
  },
  [types.SET_HOMEPAGE_SEO](state, seo) {
    state.homepageSeo = seo || {}
  },
  [types.SET_OG_SITE_NAME](state, metaTitle) {
    state.ogSiteName = generateHomepageTitle(metaTitle)
  }
}
