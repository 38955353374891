export const SPECIAL_CATEGORY = {
  THOUGHT_LEADERSHIP: 'thought-leadership',
  CRYPTOCURRENCY: 'cryptocurrency',
  FINTECH: 'fintech',
  FOREX: 'forex',
  EXECUTIVES: 'executives',
  INSTITUTIONAL: 'institutional-forex',
  BINARY_OPTIONS: 'binary-options',
  TRADING_ROOM: 'trading-room',
  TRENDING: 'trending'
}

export const SPECIAL_SUBCATEGORY = {
  EDUCATION_CENTER: 'education-centre',
  INTERVIEW: 'interview'
}
