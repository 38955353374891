export const aspectRatios = {
  article: 1.8,
  articleFace: 1,
  flArticle: 4 / 3, // 1.333,
  flArticleFeaturedVideo: 1.78,
  thoughtLeadershipLogo: 2.15,
  homepageMainEvent: 1.95,
  homepageEventTestimonial: 1,
  homepageUpcomingEventsVideo: 1.95,
  homepageTopVideo: 1.88,
  homepageMockedEventBanner: 3.88,
  sticker: 1,
  sendMeAnOffer: 2.5,
  footerStayConnected: 1,
  footerRightColumnLogo: 5.31,
  eventsMainEvent: 1.8,
  eventsUpcomingEvent: 2.22,
  eventsAboutEvents: 1.8,
  eventsTestimonials: 1,
  eventsPhotoGallery: 2.15,
  eventsVideoGallery: 1.8,
  eventsMainSponsor: 2.5,
  eventsSocialLogo: 1,
  educationCenterNewsPreview: 1.8,
  eventsAwardsWinnersMain: 1.8,
  eventsAwardsWinnersSlide: 1.8,
  aboutUsTopSection: 1.57,
  aboutUsOurTeamMember: 1,
  fmDirectoryCompanyLogo: 2.06,
  flDirectoryCompanyLogo: 2.06,
  flLogo: 4.67,
  intelligenceQirSlide: 1.8,
  intelligenceTestimonial: 1,
  intelligenceInfographic: 1.29,
  intelligenceQirListImage: 1.1,
  intelligenceOurCustomersImage: 2.5,
  companyVideo: 1.78,
  userPhoto: 1,
  noRatio: 0,
  robots: 0.851
}

export const REQUIRED_DIMENSIONS_BY_RATIO = {
  '1.8': {
    minWidth: 900,
    minHeight: 500
  }
}

export function getDimensionsByRatio(ratio) {
  if (!ratio) return null

  return REQUIRED_DIMENSIONS_BY_RATIO[ratio.toString()] || null
}
