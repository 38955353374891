export const SLIDE = {
  WELCOME: 'WELCOME',
  CATEGORY: 'CATEGORY',
  TOPIC: 'TOPIC',
  COMPANY: 'COMPANY',
  USER_DETAILS: 'USER_DETAILS',
  FINAL: 'FINAL'
}

export const SLIDE_NUMBER = {
  WELCOME: 0,
  CATEGORY: 1,
  TOPIC: 2,
  COMPANY: 3,
  USER_DETAILS: 4
}
