/**
 * https://docs.prebid.org/
 */

export const PREBID_TIMEOUT = 1000

export const CURRENCY_MODULE_CONFIG = {
  priceGranularity: 'low',
  currency: {
    adServerCurrency: 'ILS',
    granularityMultiplier: 1,
    defaultRates: { USD: { ILS: 3.2 } }
  }
}

export const BIDS = [
  {
    bidder: 'appnexus',
    params: {
      placementId: 24313292
    }
  },
  {
    bidder: '33across',
    params: {
      siteId: 'dYUe_MJCur7ioFrkHcnnVW'
    }
  },
  {
    bidder: 'openx',
    params: {
      unit: '556670110',
      delDomain: 'adnimation-d.openx.net'
    }
  },
  {
    bidder: 'ix',
    params: {
      siteId: '776787'
    }
  },
  {
    bidder: 'criteo',
    params: {
      networkId: '7314'
    }
  },
  {
    bidder: 'amx',
    params: {
      tagId: 'YWRuaW1hdGlvbi5jb20'
    }
  },
  {
    bidder: 'pubmatic',
    params: {
      publisherId: '160685'
    }
  }
]
