import { mapActions, mapGetters } from 'vuex'

import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { DATA_REFS_WATCH_SETTINGS } from '@/utils/enums/data-refs-in-dom'

export default {
  mixins: [hydrationHelpers],
  data() {
    return {
      isResizeInProgress: false
    }
  },
  computed: {
    ...mapGetters({
      rectDataByDataRef: 'dom/rectDataByDataRef',
      userDetails: 'auth/userDetails'
    }),
    $_dataRefsInDom_updateTrigger() {
      const userDetailsString = Object.values(this.userDetails).join('-')

      return `${this.$_hydrationHelpers_windowWidth}-${this.$route.path}-${userDetailsString}`
    }
  },
  watch: {
    $_dataRefsInDom_updateTrigger: {
      handler() {
        this.$nextTick(() => {
          try {
            this.$_dataRefsInDom_handleDataRefsInDom()
          } catch (err) {
            console.log('err: ', err)
          }
        })
      }
    }
  },
  methods: {
    ...mapActions({
      watchDataRefs: 'dom/watchDataRefs',
      unwatchDataRefs: 'dom/unwatchDataRefs',
      unwatchAllDataRefs: 'dom/unwatchAllDataRefs',
      handleMutationObserver: 'dom/handleMutationObserver'
    }),
    $_dataRefsInDom_handleDataRefsInDom() {
      const payload = {
        windowWidth: this.$_hydrationHelpers_windowWidth,
        route: this.$route,
        userDetails: this.userDetails
      }

      const dataRefsToWatch = this.$_dataRefsInDom_getDataRefsToWatch(payload)

      if (dataRefsToWatch.length === 0) {
        this.unwatchAllDataRefs()
        this.handleMutationObserver()
        return
      }

      const dataRefsToUnwatch = this.$_dataRefsInDom_getDataRefsToUnwatch(
        dataRefsToWatch
      )

      this.watchDataRefs(dataRefsToWatch)

      if (dataRefsToUnwatch.length) {
        this.unwatchDataRefs(dataRefsToUnwatch)
      }
      this.handleMutationObserver()
    },
    $_dataRefsInDom_getDataRefsToWatch(payload) {
      const dataRefsToWatchSet = DATA_REFS_WATCH_SETTINGS.reduce(
        (acc, settings) => {
          const { refsToWatch, watchCondition } = settings

          if (watchCondition(payload)) {
            refsToWatch.forEach(dataRef => {
              acc.add(dataRef)
            })
          }

          return acc
        },
        new Set()
      )

      return [...dataRefsToWatchSet]
    },
    $_dataRefsInDom_getAllDataRefs() {
      const allDataRefsSet = DATA_REFS_WATCH_SETTINGS.reduce(
        (acc, settings) => {
          const { refsToWatch } = settings

          refsToWatch.forEach(dataRef => {
            acc.add(dataRef)
          })

          return acc
        },
        new Set()
      )

      return [...allDataRefsSet]
    },
    $_dataRefsInDom_getDataRefsToUnwatch(dataRefsToWatch) {
      const allDataRefs = this.$_dataRefsInDom_getAllDataRefs()

      return allDataRefs.filter(dataRef => !dataRefsToWatch.includes(dataRef))
    }
  }
}
