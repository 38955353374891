<template>
  <div class="global-wrapper" :class="globalWrapDynamicClass">
    <a-410-page v-if="is410Page" />

    <template v-else>
      <a-sticky
        :id="$options.consts.TOP_STICKY_CONTAINER_ID"
        :data-ref="$options.consts.REFS.LAYOUT_TOP_STICKY_ELEMENT"
        class="top-sticky-container"
      >
        <a-visibility hide :on="[$breakpoint.mobile]">
          <top-header-strip />
        </a-visibility>
        <a-header :last-modified="headerMenuItemsLastModified" />
      </a-sticky>
      <div class="page-wrap" :data-ref="$options.consts.REFS.LAYOUT_PAGE_WRAP">
        <div
          class="content-wrapper"
          :data-ref="$options.consts.REFS.CONTENT_WRAPPER"
        >
          <nuxt ref="nuxt" />
        </div>
        <div class="widgets-wrapper">
          <a-visibility show :from="$breakpoint.desktopMd">
            <a-layout-widgets
              :display-widget-buttons="false"
              :style="contentTopPadding"
            />
          </a-visibility>
        </div>
      </div>
      <!--      <a-cookies-pop-up />-->
      <a-footer />
      <a-auth-form />
      <a-pedia-assistant />
      <client-only>
        <a-error-handler-notifier v-if="isDevelopment" />
      </client-only>
      <a-cross-domain-cookies />
      <a-device-id-cookie-iframe />
      <a-google-analytics-cookies-iframe />
      <a-visibility show :to="$breakpoint.desktopSm">
        <a-news-letter-modal />
      </a-visibility>
      <gtm-no-script />

      <!-- Use this component to define styles with "!important" modifier
           Previously was used for "iubenda". -->
      <!--      <a-non-amp-important-styles v-if="$helper.isNotAmpPage($route.name)" />-->
    </template>
  </div>
</template>

<script>
import defaultLayout from './default'
import { mapGetters } from 'vuex'

export default {
  name: 'ErrorLayout',
  extends: defaultLayout,
  components: {
    A410Page: () => import('@/components/_layout/Error/A410Page')
  },
  computed: {
    ...mapGetters({
      is410Page: 'errors/is410Page',
      isPreviewMode: 'isPreviewMode'
    }),
    globalWrapDynamicClass() {
      return {
        'preview-mode': this.isPreviewMode
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.global-wrapper__scroll-to-top-wrapper {
  position: absolute;
  top: 0;
  right: 60px;
  height: 100%;
  padding-bottom: 40px;

  @include tablet {
    right: 0;
  }

  @include mobile {
    padding-bottom: 20px;
  }
}

.global-wrapper__scroll-to-top {
  position: fixed;
  right: 135px;
  bottom: 100px;

  @include desktop-sm {
    right: 15px;
  }
}
</style>
