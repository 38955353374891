<template>
  <component
    :is="wrapperComponent"
    v-bind="wrapperProps"
    class="a-button__general-style"
    :class="dynamicClass"
    v-click="onClick"
    @blur="onBlur"
  >
    <div class="a-button__slot-wrapper">
      <slot v-if="!inProgress" />
      <a-spinner
        v-else
        class="a-button__spinner"
        :size="SPINNER_SIZE"
        :radius="SPINNER_RADIUS"
        :stroke="2"
        :color="spinnerColor"
      />
      <a-validation-message :error="error" />
    </div>
  </component>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import ASpinner from 'shared/ASpinner'
import AValidationMessage from 'shared/AValidationMessage'
import { REL_ATTRIBUTE_BACKEND_ENUM } from '@fmpedia/enums'

export const BUTTON_STYLE = {
  BLUE: 'BLUE',
  RED: 'RED',
  BLACK: 'BLACK',
  WHITE: 'WHITE',
  GREEN: 'GREEN',
  NO_STYLE: 'NO_STYLE', // without styles, just button
  RING_CAPTCHA_SEND_PIN: 'RING_CAPTCHA_SEND_PIN'
}

const CLASS_BY_BUTTON_STYLE = {
  [BUTTON_STYLE.BLUE]: 'a-button_blue',
  [BUTTON_STYLE.RED]: 'a-button_red',
  [BUTTON_STYLE.BLACK]: 'a-button_black',
  [BUTTON_STYLE.WHITE]: 'a-button_white',
  [BUTTON_STYLE.GREEN]: 'a-button_green',
  [BUTTON_STYLE.RING_CAPTCHA_SEND_PIN]: 'a-button_ring-captcha-send-pin'
}

const SPINNER_SIZE = 48
const SPINNER_RADIUS = SPINNER_SIZE / 4
const SPINNER_COLOR = {
  GENERAL: 'white',
  RING_CAPTCHA: 'blue'
}

const BUTTON_STYLE_VALUES = Object.values(BUTTON_STYLE)

const WRAPPER = {
  LINK: 'a-link',
  BUTTON: 'button'
}

export default {
  name: 'AButton',
  components: { ASpinner, AValidationMessage },
  props: {
    href: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false),
    type: propValidator([PROP_TYPES.STRING], false, 'button'),
    disabled: propValidator([PROP_TYPES.BOOLEAN], false),
    inProgress: propValidator([PROP_TYPES.BOOLEAN], false, false),
    directive: propValidator(
      [PROP_TYPES.STRING],
      false,
      REL_ATTRIBUTE_BACKEND_ENUM.NOFOLLOW
    ),
    removeDirective: propValidator([PROP_TYPES.BOOLEAN], false, false),
    openInNewTab: propValidator([PROP_TYPES.BOOLEAN], false, true),
    buttonStyle: propValidator(
      [PROP_TYPES.STRING],
      false,
      BUTTON_STYLE.BLUE,
      value => BUTTON_STYLE_VALUES.includes(value)
    ),
    uppercase: propValidator([PROP_TYPES.BOOLEAN], false, false),
    error: propValidator([PROP_TYPES.OBJECT], false, () => ({}))
  },
  data() {
    return {
      SPINNER_SIZE,
      SPINNER_RADIUS,
      SPINNER_COLOR
    }
  },
  computed: {
    wrapperComponent() {
      return this.href ? WRAPPER.LINK : WRAPPER.BUTTON
    },
    linkProps() {
      return {
        to: this.href,
        directive: this.directive,
        removeDirective: this.removeDirective,
        openInNewTab: this.openInNewTab,
        disabled: this.disabled
      }
    },
    buttonProps() {
      return {
        disabled: this.disabled,
        type: this.type
      }
    },
    wrapperProps() {
      return this.wrapperComponent === WRAPPER.LINK
        ? this.linkProps
        : this.buttonProps
    },
    dynamicClass() {
      return {
        [CLASS_BY_BUTTON_STYLE[this.buttonStyle]]: true,
        'a-button_uppercase': this.uppercase,
        'a-button_in-progress': this.inProgress,
        'a-button_disabled': this.disabled
      }
    },
    spinnerColor() {
      return this.buttonStyle === BUTTON_STYLE.RING_CAPTCHA_SEND_PIN
        ? SPINNER_COLOR.RING_CAPTCHA
        : SPINNER_COLOR.GENERAL
    }
  },
  methods: {
    onClick() {
      if (this.disabled || this.inProgress) return

      this.$emit('click')
    },
    onBlur(event) {
      this.$emit('blur', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.a-button__general-style {
  position: relative;
  display: block;
  width: 140px;
  height: 40px;
  color: $c--gray-main;

  font-size: 14px;
  font-weight: 600;
  line-height: 16px;

  /deep/ * {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  .a-button__slot-wrapper {
    width: 100%;
    height: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    vertical-align: top;
    padding: 0 10px;
    text-align: center;
    overflow: hidden;
  }

  .a-button__spinner {
    position: absolute;
  }

  &:hover {
    opacity: 0.7;
  }

  &.a-button_disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  &.a-button_disabled:hover,
  &.a-button_white:hover,
  &.a-button_green {
    opacity: 1;
  }

  &.a-button_uppercase * {
    text-transform: uppercase;
  }
}

.a-button_blue {
  background: $c--main;
  color: $c--white;
}

.a-button_black {
  background: $c--black;
  color: $c--white;
}

.a-button_red {
  background: $c--red;
  color: $c--white;
}

.a-button_white {
  background: $c--white;
  color: $c--main;
  border: 2px solid $c--main;

  &:not(.a-button_disabled):hover {
    background: $c--main;
    color: $c--white;
  }

  &.a-button_disabled {
    background: $c--main;
    color: $c--white;
  }
}

.a-button_green {
  color: $c--upload-button;
  border: 1px solid $c--upload-button;
  height: 30px;

  .a-button__slot-wrapper {
    font-weight: 700;

    /deep/ * {
      font-weight: 700;
    }
  }

  &:not(.a-button_disabled):hover {
    background: $c--upload-button;
    color: $c--white;
  }

  &.a-button_disabled {
    background: $c--main;
    color: $c--white;
  }
}

.a-button_ring-captcha-send-pin {
  width: 100%;
  height: 40px;
  color: $c--gray-main;
  background: #fff;
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;

  @include mobile {
    height: 40px;
    line-height: 40px;
  }
}

.a-button_in-progress {
  cursor: not-allowed;
}

.input-group__error {
  position: absolute;
  top: 100%;
  left: 5px;
  color: $c--gray-main;
}
</style>
