import { ROUTE_NAME } from 'enums/routes'
import { BANNER_SETTINGS } from 'enums/banners/banner-settings'

const PERSONAL_AREA_BANNER_SETTINGS = Object.entries(ROUTE_NAME)
  .filter(([key]) => key.match(/^PERSONAL_PROFILE/))
  .reduce(
    (acc, [key, value]) => ({ ...acc, [value]: BANNER_SETTINGS.PERSONAL_AREA }),
    {}
  )

export const BANNER_SETTINGS_BY_ROUTE_NAME = {
  [ROUTE_NAME.HOMEPAGE]: BANNER_SETTINGS.HOMEPAGE,
  [ROUTE_NAME.ALL_NEWS]: BANNER_SETTINGS.ALL_NEWS,
  [ROUTE_NAME.CATEGORY]: BANNER_SETTINGS.ALL_NEWS,
  [ROUTE_NAME.TAG_SLUG]: BANNER_SETTINGS.ALL_NEWS,
  [ROUTE_NAME.CATEGORY_SUBCATEGORY]: BANNER_SETTINGS.ALL_NEWS,
  [ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE]: BANNER_SETTINGS.ARTICLE,
  [ROUTE_NAME.LEGACY_ARTICLE_ON_CATEGORY_PATH]: BANNER_SETTINGS.ARTICLE,
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER]: BANNER_SETTINGS.ALL_NEWS,
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE]: BANNER_SETTINGS.ARTICLE,
  [ROUTE_NAME.ABOUT_US]: BANNER_SETTINGS.ABOUT_US,
  [ROUTE_NAME.TERMS_LETTER]: BANNER_SETTINGS.TERMS_LETTER,
  [ROUTE_NAME.TERMS]: BANNER_SETTINGS.TERMS_HP,
  [ROUTE_NAME.TERMS_LETTER_SLUG]: BANNER_SETTINGS.TERM_PAGE,
  [ROUTE_NAME.AUTHOR_SLUG]: BANNER_SETTINGS.AUTHOR_PAGE,
  [ROUTE_NAME.TAG_SLUG]: BANNER_SETTINGS.TAG_PAGE,
  [ROUTE_NAME.TAG_SEARCH_RESULTS]: BANNER_SETTINGS.TAG_SEARCH_RESULTS,
  [ROUTE_NAME.SEARCH_RESULTS]: BANNER_SETTINGS.SEARCH_RESULTS,
  [ROUTE_NAME.TERMS_SEARCH_RESULTS]: BANNER_SETTINGS.TERMS_SEARCH_RESULTS,
  [ROUTE_NAME.BECOME_AN_AUTHOR]: BANNER_SETTINGS.BECOME_AN_AUTHOR,
  [ROUTE_NAME.EVENTS]: BANNER_SETTINGS.EVENTS,
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS_ANNUAL]:
    BANNER_SETTINGS.INTELLIGENCE_ANNUAL_SUBSCRIPTION,
  [ROUTE_NAME.INTELLIGENCE]: BANNER_SETTINGS.INTELLIGENCE,
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS]: BANNER_SETTINGS.INTELLIGENCE_PRODUCTS,
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS_QIR_SLUG]:
    BANNER_SETTINGS.INTELLIGENCE_SINGLE_QIR,
  [ROUTE_NAME.CONTACT_US]: BANNER_SETTINGS.CONTACT_US,
  ...PERSONAL_AREA_BANNER_SETTINGS
}

export const BANNER_COUNT_BY_NEWS_LIST_PAGE_NAME = {
  [ROUTE_NAME.ALL_NEWS]: {
    mobile: 5,
    desktop: 5
  },
  [ROUTE_NAME.CATEGORY]: {
    mobile: 5,
    desktop: 5
  },
  [ROUTE_NAME.TAG_SLUG]: {
    mobile: 5,
    desktop: 5
  },
  [ROUTE_NAME.CATEGORY_SUBCATEGORY]: {
    mobile: 5,
    desktop: 5
  },
  [ROUTE_NAME.AUTHOR_SLUG]: {
    mobile: 5,
    desktop: 5
  },
  [ROUTE_NAME.TAG_SLUG]: {
    mobile: 5,
    desktop: 5
  }
}
