import { ROUTE_NAME } from 'enums/routes'

export const LINK_GENERATOR = {
  TERMS_OF_USE: () => ({
    name: ROUTE_NAME.TERMS_OF_USE
  }),
  COOKIES: () => ({
    name: ROUTE_NAME.COOKIES
  }),
  PRIVACY_POLICY: () => ({
    name: ROUTE_NAME.PRIVACY_POLICY
  })
}

export const DEFAULT_FALLBACK_LINK = ''

export const TARGET = {
  SELF: '_self',
  BLANK: '_blank'
}

export const TARGET_BEHAVIOR = {
  OPEN_IN_NEW_TAB: 'OPEN_IN_NEW_TAB',
  OPEN_IN_SAME_TAB: 'OPEN_IN_SAME_TAB'
}

export const TARGET_BY_TARGET_BEHAVIOR = {
  [TARGET_BEHAVIOR.OPEN_IN_NEW_TAB]: TARGET.BLANK,
  [TARGET_BEHAVIOR.OPEN_IN_SAME_TAB]: TARGET.SELF
}

export const TARGET_BEHAVIOR_BY_TARGET = {
  [TARGET.BLANK]: TARGET_BEHAVIOR.OPEN_IN_NEW_TAB,
  [TARGET.SELF]: TARGET_BEHAVIOR.OPEN_IN_SAME_TAB
}
