import { PAGE_SCHEME } from '@/utils/enums/pageSchemes'
import { replaceDoubleQuotesBySingle } from '~/plugins/helper'

export const generatePageScheme = schemes => {
  if (!schemes || !schemes.length) {
    /**
     * Removing schemas added by parent pages
     */
    return {
      script: [
        {
          hid: 'page-schemas',
          innerHTML: null
        }
      ]
    }
  }

  const schemeScript = `{
      "@context": "https://schema.org",
      ${drawSchemes(schemes)}
  }`

  return {
    script: [
      {
        hid: 'page-schemas',
        innerHTML: schemeScript,
        type: 'application/ld+json'
      }
    ]
  }
}

/**
 * The schema of these pages were not valid without this processing:
 * https://www.financemagnates.com/cryptocurrency/news/the-simpsons-features-jim-parsons-explaining-crypto-blockchain/
 * https://www.financemagnates.com/tag/142244/
 * @param schema
 * @returns {String}
 */
const escapeSchema = schema => {
  if (!schema) return ''

  return schema.replace(/\\/g, '')
}

const drawSchemes = schemes => {
  return schemes.length === 1
    ? `${escapeSchema(
        PAGE_SCHEME[schemes[0].type](
          replaceDoubleQuotesInObjectValues(schemes[0].data) || {}
        )
      )}`
    : `"@graph": [${schemes
        .map(
          ({ type, data }) =>
            `{${escapeSchema(
              PAGE_SCHEME[type](replaceDoubleQuotesInObjectValues(data) || {})
            )}}`
        )
        .join(',\n')}]`
}

function replaceDoubleQuotesInObjectValues(obj = {}) {
  return Object.entries(obj).reduce(
    (acc, [k, v]) => ({ ...acc, [k]: replaceDoubleQuotesBySingle(v) }),
    {}
  )
}
