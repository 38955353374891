export const ROUTE = {
  HOMEPAGE: '/',
  INTELLIGENCE: '/intelligence',
  INTELLIGENCE_PRODUCTS: '/intelligence/products',
  INTELLIGENCE_PRODUCTS_ANNUAL: '/intelligence/products/annual',
  INTELLIGENCE_PRODUCTS_QIR: '/intelligence/products/qir',
  AUTHOR: '/author',
  ABOUT_US: '/about',
  BECOME_AN_AUTHOR: '/become-an-author',
  ALL_NEWS: '/all-news',
  CONTACT_US: '/contact-us',
  EVENTS: '/events',
  TAG_SEARCH_RESULTS: '/tag/search-results/',
  TERMS: '/terms',
  TERMS_SEARCH_RESULTS: '/terms/search-results/',
  FORGOT_PASSWORD: '/forgot-password',
  SEARCH_RESULTS: '/search-results',
  PERSONAL_PROFILE: '/profile',
  TERMS_OF_USE: '/terms-of-use',
  PRIVACY_POLICY: '/privacy',
  COOKIES: '/cookies/',
  PERSONAL_PROFILE_ANNOUNCEMENTS: '/profile/announcements',
  PERSONAL_PROFILE_FOLLOWED_AUTHORS: '/profile/followed-authors',
  PERSONAL_PROFILE_FOLLOWED_COMPANIES: '/profile/followed-companies',
  PERSONAL_PROFILE_FOLLOWED_TOPICS: '/profile/followed-topics',
  PERSONAL_PROFILE_MY_COMPANIES: '/profile/my-companies',
  PERSONAL_PROFILE_ADD_COMPANY: '/profile/my-companies/add-company',
  PERSONAL_PROFILE_MY_CONTRIBUTIONS: '/profile/my-contributions',
  PERSONAL_PROFILE_NOTIFICATIONS: '/profile/notifications',
  PERSONAL_PROFILE_SAVED_FOR_LATER: '/profile/saved'
}

export const FM_DIR_ROUTE = {
  SEARCH_RESULTS: '/search-results',
  COMPANY_NEWS: '/news-and-articles'
}

export const ROUTE_NAME = {
  HOMEPAGE: 'index',
  ALL_NEWS: 'all-news',
  BECOME_AN_AUTHOR: 'become-an-author',
  CATEGORY: 'category',
  CATEGORY_SUBCATEGORY: 'category-subcategory',
  CATEGORY_SUBCATEGORY_AMP: 'category-subcategory-amp',
  CATEGORY_EDUCATION_CENTER: 'category-education-centre',
  CATEGORY_EDUCATION_CENTER_ARTICLE: 'category-education-centre-article',
  CATEGORY_EDUCATION_CENTER_ARTICLE_AMP:
    'category-education-centre-article-amp',
  CATEGORY_SUBCATEGORY_ARTICLE: 'category-subcategory-article',
  CATEGORY_SUBCATEGORY_ARTICLE_AMP: 'category-subcategory-article-amp',
  CATEGORY_SUBCATEGORY_ARTICLE_GIBBERISH:
    'category-subcategory-article-gibberish',
  EVENTS: 'events',
  TERMS_OF_USE: 'terms-of-use',
  PRIVACY_POLICY: 'privacy',
  COOKIES: 'cookies',
  TERMS: 'terms',
  TERMS_LETTER: 'terms-letter',
  TERMS_LETTER_SLUG: 'terms-letter-slug',
  TERMS_SEARCH_RESULTS: 'terms-search-results',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  CONTACT_US: 'contact-us',
  TAG: 'tag',
  TAG_SLUG: 'tag-slug',
  TAG_SEARCH_RESULTS: 'tag-search-results',
  INTELLIGENCE: 'intelligence',
  INTELLIGENCE_PRODUCTS: 'intelligence-products',
  INTELLIGENCE_PRODUCTS_QIR: 'intelligence-products-qir',
  INTELLIGENCE_PRODUCTS_QIR_SLUG: 'intelligence-products-qir-slug',
  INTELLIGENCE_PRODUCTS_ANNUAL: 'intelligence-products-annual',
  PERSONAL_PROFILE: 'profile',
  PERSONAL_PROFILE_ANNOUNCEMENTS: 'profile-announcements',
  PERSONAL_PROFILE_FOLLOWED_AUTHORS: 'profile-followed-authors',
  PERSONAL_PROFILE_FOLLOWED_COMPANIES: 'profile-followed-companies',
  PERSONAL_PROFILE_FOLLOWED_TOPICS: 'profile-followed-topics',
  PERSONAL_PROFILE_MY_CONTRIBUTIONS: 'profile-my-contributions',
  PERSONAL_PROFILE_ADD_POST: 'profile-my-contributions-edit',
  PERSONAL_PROFILE_EDIT_POST: 'profile-my-contributions-edit-articleSlug',
  PERSONAL_PROFILE_NOTIFICATIONS: 'profile-notifications',
  PERSONAL_PROFILE_SAVED_FOR_LATER: 'profile-saved',
  PERSONAL_PROFILE_MY_COMPANIES: 'profile-my-companies',
  PERSONAL_PROFILE_ADD_COMPANY: 'profile-my-companies-add-company',
  PERSONAL_PROFILE_EDIT_COMPANY: 'profile-my-companies-category-company-edit',
  PERSONAL_PROFILE_COMPANY_NEWS: 'profile-my-companies-category-company-news',
  PERSONAL_PROFILE_ADD_COMPANY_NEWS:
    'profile-my-companies-category-company-news-edit',
  PERSONAL_PROFILE_EDIT_COMPANY_NEWS:
    'profile-my-companies-category-company-news-article-edit',
  PERSONAL_PROFILE_COMPANY_VIDEOS:
    'profile-my-companies-category-company-videos',
  PERSONAL_PROFILE_ADD_COMPANY_VIDEO:
    'profile-my-companies-category-company-videos-edit',
  PERSONAL_PROFILE_EDIT_COMPANY_VIDEO:
    'profile-my-companies-category-company-videos-video-edit',
  AUTHOR: 'author',
  AUTHOR_SLUG: 'author-slug',
  SEARCH_RESULTS: 'search-results',
  CONTRIBUTOR_CONFIRMATION: 'contributor-confirmation',
  SPONSORED_CONTRIBUTOR_CONFIRMATION: 'sponsored-contributor-confirmation',
  ABOUT_US: 'about',
  SIGN_UP_CONFIRMATION: 'sign-up-confirmation',
  LEGACY_ARTICLE_ON_CATEGORY_PATH: 'legacy-article-on-category-path',
  REACTIVATION: 'reactivation'
}

export const AMP_PAGES = [
  ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE_AMP,
  ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE_AMP,
  ROUTE_NAME.CATEGORY_SUBCATEGORY_AMP
]

export const PERSONAL_AREA_ROUTES = [
  ROUTE_NAME.PERSONAL_PROFILE,
  ROUTE_NAME.PERSONAL_PROFILE_ANNOUNCEMENTS,
  ROUTE_NAME.PERSONAL_PROFILE_FOLLOWED_AUTHORS,
  ROUTE_NAME.PERSONAL_PROFILE_FOLLOWED_COMPANIES,
  ROUTE_NAME.PERSONAL_PROFILE_FOLLOWED_TOPICS,
  ROUTE_NAME.PERSONAL_PROFILE_MY_CONTRIBUTIONS,
  ROUTE_NAME.PERSONAL_PROFILE_ADD_POST,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_POST,
  ROUTE_NAME.PERSONAL_PROFILE_NOTIFICATIONS,
  ROUTE_NAME.PERSONAL_PROFILE_SAVED_FOR_LATER,
  ROUTE_NAME.PERSONAL_PROFILE_MY_COMPANIES,
  ROUTE_NAME.PERSONAL_PROFILE_ADD_COMPANY,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_COMPANY,
  ROUTE_NAME.PERSONAL_PROFILE_COMPANY_NEWS,
  ROUTE_NAME.PERSONAL_PROFILE_ADD_COMPANY_NEWS,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_COMPANY_NEWS,
  ROUTE_NAME.PERSONAL_PROFILE_COMPANY_VIDEOS,
  ROUTE_NAME.PERSONAL_PROFILE_ADD_COMPANY_VIDEO,
  ROUTE_NAME.PERSONAL_PROFILE_EDIT_COMPANY_VIDEO
]

export const GUARDED_ROUTES = [
  ...PERSONAL_AREA_ROUTES,
  ROUTE_NAME.BECOME_AN_AUTHOR
]

export const ROUTE_NAMES_WITH_PAGINATION = [
  ROUTE_NAME.ALL_NEWS,
  ROUTE_NAME.CATEGORY,
  ROUTE_NAME.CATEGORY_SUBCATEGORY,
  ROUTE_NAME.TERMS_LETTER_SLUG,
  ROUTE_NAME.TAG_SLUG,
  ROUTE_NAME.INTELLIGENCE_PRODUCTS,
  ROUTE_NAME.AUTHOR_SLUG
]
