<template>
  <div class="amp-layout__wrapper">
    <amp-header />
    <div class="amp-layout__content-container">
      <nuxt />
    </div>
    <a-footer />
  </div>
</template>

<script>
export default {
  name: 'AmpLayout',
  components: {
    AmpHeader: () => import('@/components/_sections/amp/Header'),
    AFooter: () => import('@/components/AFooter')
  }
}
</script>

<style lang="scss">
.amp-layout__wrapper {
  max-width: 800px;
  margin: 0 auto;
}

$gap-between-header-and-content: 20px;
$padding-top: $amp-header-height + $gap-between-header-and-content;
.amp-layout__content-container {
  padding-top: $padding-top;
  padding-bottom: 0;
  @include setStyleByBreakpoint('padding-left', $amp-page-padding-left);
  @include setStyleByBreakpoint('padding-right', $amp-page-padding-right);
}

amp-consent {
  background-color: $c--dark-semi-transparent;

  .amp-consent__popup {
    margin: 0 auto;
    position: relative;
    width: 100%;
    height: 100vh;

    .amp-active {
      display: none;
    }

    amp-iframe {
      background-color: transparent;
    }
  }
}
</style>
