/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'fm-mobile': {
    width: 41,
    height: 63,
    viewBox: '0 0 41 63',
    data:
      '<path pid="0" d="M20.483 14.3v1.194h-6.321v5.357h5.344v1.194h-5.344v6.612h-1.436V14.3h7.757zM28.506 34.448v14.55h-2.92v-9.459l-3.896 9.459h-2.208l-3.92-9.459v9.459h-2.92v-14.55h3.317l4.633 10.822 4.633-10.822h3.281z" _fill="#fff"/><path pid="1" d="M.517.643v62h40v-62h-40zm37 58.971H3.533V3.66h33.984v55.955z" _fill="#fff"/>'
  }
})
