import api from '~/utils/api'
import * as types from '~/store/mutation-types'
import apiV2 from '@/utils/apiV2'
import { generateArticleSlotData } from '@/utils/helpers/models/article'

export const state = () => ({
  skipSearchTrackOnSearchResultsPage: false
})

export const getters = {
  skipSearchTrackOnSearchResultsPage: state =>
    state.skipSearchTrackOnSearchResultsPage
}

export const actions = {
  async requestFmQuickSearchResults(_, params) {
    try {
      const { data } = await this.$axios.get(api.globalSearch.quickSearch(), {
        params
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestFmDirQuickSearchResults(_, params) {
    try {
      const { data } = await this.$axios.get(api.directory.quickSearch(), {
        params
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async getFullSearchResult(_, params) {
    try {
      const { data } = await this.$axios.get(api.globalSearch.getAllResults(), {
        params
      })

      data.Articles = (data.Articles || []).map(article =>
        generateArticleSlotData(article, this)
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestTermsQuicksearch(_, { queryParams }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.terms.termsQuickSearch(), {
        params: queryParams
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTermsFullSearch(_, { queryParams }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.terms.termsFullSearch(), {
        params: queryParams
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTagsQuicksearch(_, { queryParams }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.tags.tagsQuickSearch(), {
        params: queryParams
      })
      return data
    } catch (e) {
      throw e
    }
  },
  async requestTagsFullSearch(_, { queryParams }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.tags.tagsFullSearch(), {
        params: queryParams
      })
      return data
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {
  [types.SET_SKIP_SEARCH_TRACK_ON_SEARCH_RESULTS_PAGE_STATUS]: (
    state,
    value
  ) => {
    state.skipSearchTrackOnSearchResultsPage = value
  }
}
