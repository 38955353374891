import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'
import { generateArticleSlotData } from '@/utils/helpers/models/article'

const state = () => ({})

const getters = {}

const actions = {
  async requestTermBySlug(_, { queryParams, pathParams: { slug } }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.terms.getTerm(slug), {
        params: queryParams
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async requestTermsByLetter(_, { queryParams }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.terms.getTermsByLetter(), {
        params: queryParams
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async requestTermOfTheDay() {
    try {
      const { data } = await this.$axios.get(api.terms.getTermOfTheDay())
      return data
    } catch (err) {
      throw err
    }
  },
  async requestRelatedArticles(_, params) {
    try {
      const {
        data
      } = await this.$axios.get(api.articles.getArticleByTermSlug(), { params })

      data.Articles = (data.Articles || []).map(article =>
        generateArticleSlotData(article, this)
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestCreateUserSuggestion(_, { payload, captchaKey }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: captchaKey }
    }

    try {
      await this.$axios.post(
        api.terms.createUserSuggestion(),
        payload,
        settings
      )
    } catch (err) {
      throw err
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
