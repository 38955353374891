<template>
  <section class="container error__container">
    <a-visibility show :on="[$breakpoint.mobile]">
      <a-logo class="error__block_logo" :fixed-layout="FIXED_LAYOUT.MOBILE" />
    </a-visibility>

    <a-robot
      class="error__block_robot"
      :statusCode="error.statusCode"
      :title="ERROR_TITLE.PAGE_NOT_FOUND"
    />

    <div class="error__logo-details__wrap">
      <a-visibility hide :on="[$breakpoint.mobile]">
        <a-logo class="error__block_logo" />
      </a-visibility>

      <a-details
        class="error__block_details"
        :message="ERROR_MESSAGE.PAGE_NOT_FOUND"
      />
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import mixins from '@/utils/mixins'
import { FIXED_LAYOUT } from 'enums/fixed-layout'
import { ERROR_TITLE, ERROR_MESSAGE } from 'enums/errorCodes'

export default {
  props: ['error'],
  layout: 'error-layout',
  mixins: [mixins.setPageLoaded],
  components: {
    ARobot: () => import('@/components/_layout/Error/ARobot'),
    ALogo: () => import('@/components/_layout/Error/ALogo'),
    ADetails: () => import('@/components/_layout/Error/ADetails')
  },
  head() {
    /**
     * No SEO rules should be applied to 410 page according to specs
     */
    if (this.is410Page) return

    const pageName = `Page Not Found`

    return this.$generateMetaTags({
      titleParam: pageName,
      descriptionParam: pageName,
      source: 'seo/errorPageSeo'
    })
  },
  data() {
    return {
      FIXED_LAYOUT,
      ERROR_TITLE,
      ERROR_MESSAGE
    }
  },
  computed: {
    ...mapState({
      is410Page: 'errors/is410Page'
    })
  },
  methods: {
    ...mapActions({
      requestAuthStatus: 'auth/requestAuthStatus'
    })
  },
  mounted() {
    this.requestAuthStatus()
  }
}
</script>

<style lang="scss" scoped>
$gap: 100px;
$desktop-sm-width: 1024px;

.error {
  &__container {
    display: flex;
    justify-content: center;
    margin-bottom: 110px;

    @include mobile {
      flex-direction: column;
      align-items: center;
      margin-bottom: 0;
    }

    .error__logo-details__wrap {
      display: flex;
      flex-direction: column;
      max-width: 600px;
      width: 100%;

      @include desktop-sm {
        width: percentage(520px / $desktop-sm-width);
      }

      @include tablet {
        width: percentage(423px / 700px);
      }

      @include mobile {
        width: 100%;
        margin-top: 20px;
        margin-right: 0;
      }
    }
  }

  &__block {
    &_robot {
      display: flex;
      flex-direction: column;
      width: 400px;
      margin-right: $gap;

      @include desktop-sm {
        width: percentage(285px / $desktop-sm-width);
        margin-right: 30px;
      }

      @include tablet {
        min-width: 285px;
      }

      @include mobile {
        min-width: unset;
        width: percentage(160 / 290);
        margin: 0;
      }
    }

    &_logo {
      margin-bottom: 20px;
      max-width: 600px;
      width: 100%;

      @include mobile {
        max-width: 100%;
      }
    }

    &_details {
      @include mobile {
        width: 100%;
      }
    }
  }
}
</style>
