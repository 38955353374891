import Vue from 'vue'

import { click } from './directives/click'
import { focusable } from '@fmpedia/directives'
import {
  handleDragAndDrop,
  updateDragAndDropHandler
} from './directives/drag-and-drop'

Vue.directive('click', click)
Vue.directive('focusable', focusable)
Vue.directive('drag-and-drop', {
  bind: handleDragAndDrop,
  update: updateDragAndDropHandler
})
