import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from '@nuxt/ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d8a8f054 = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _1b13702e = () => interopDefault(import('../pages/all-news.vue' /* webpackChunkName: "pages/all-news" */))
const _74569d52 = () => interopDefault(import('../pages/become-an-author/index.vue' /* webpackChunkName: "pages/become-an-author/index" */))
const _0cce6818 = () => interopDefault(import('../pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _6b0aac51 = () => interopDefault(import('../pages/contributor-confirmation.vue' /* webpackChunkName: "pages/contributor-confirmation" */))
const _24c6c615 = () => interopDefault(import('../pages/cookies.vue' /* webpackChunkName: "pages/cookies" */))
const _21236146 = () => interopDefault(import('../pages/events/index.vue' /* webpackChunkName: "pages/events/index" */))
const _37296ece = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _1043c3ae = () => interopDefault(import('../pages/intelligence.vue' /* webpackChunkName: "pages/intelligence" */))
const _71f51e3d = () => interopDefault(import('../pages/intelligence/products/index.vue' /* webpackChunkName: "pages/intelligence/products/index" */))
const _3d4221f2 = () => interopDefault(import('../pages/intelligence/products/annual/index.vue' /* webpackChunkName: "pages/intelligence/products/annual/index" */))
const _786c6d00 = () => interopDefault(import('../pages/intelligence/products/qir/_slug.vue' /* webpackChunkName: "pages/intelligence/products/qir/_slug" */))
const _be08bc28 = () => interopDefault(import('../pages/intelligence/index.vue' /* webpackChunkName: "pages/intelligence/index" */))
const _37e8fae4 = () => interopDefault(import('../pages/privacy.vue' /* webpackChunkName: "pages/privacy" */))
const _d5f21fa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _2ea16b2f = () => interopDefault(import('../pages/profile/announcements/index.vue' /* webpackChunkName: "pages/profile/announcements/index" */))
const _0da6b4de = () => interopDefault(import('../pages/profile/followed-authors/index.vue' /* webpackChunkName: "pages/profile/followed-authors/index" */))
const _6e7af7f1 = () => interopDefault(import('../pages/profile/followed-companies/index.vue' /* webpackChunkName: "pages/profile/followed-companies/index" */))
const _83925f38 = () => interopDefault(import('../pages/profile/followed-topics/index.vue' /* webpackChunkName: "pages/profile/followed-topics/index" */))
const _14124ce6 = () => interopDefault(import('../pages/profile/my-companies/index.vue' /* webpackChunkName: "pages/profile/my-companies/index" */))
const _2ab8c895 = () => interopDefault(import('../pages/profile/my-contributions/index.vue' /* webpackChunkName: "pages/profile/my-contributions/index" */))
const _abab7dea = () => interopDefault(import('../pages/profile/notifications/index.vue' /* webpackChunkName: "pages/profile/notifications/index" */))
const _857d302c = () => interopDefault(import('../pages/profile/saved/index.vue' /* webpackChunkName: "pages/profile/saved/index" */))
const _bde2cb68 = () => interopDefault(import('../pages/profile/my-companies/add-company.vue' /* webpackChunkName: "pages/profile/my-companies/add-company" */))
const _437b654c = () => interopDefault(import('../pages/profile/my-contributions/edit/index.vue' /* webpackChunkName: "pages/profile/my-contributions/edit/index" */))
const _5c3cd4cc = () => interopDefault(import('../pages/profile/my-contributions/enums.js' /* webpackChunkName: "pages/profile/my-contributions/enums" */))
const _eec3b0ac = () => interopDefault(import('../pages/profile/my-contributions/edit/_articleSlug.vue' /* webpackChunkName: "pages/profile/my-contributions/edit/_articleSlug" */))
const _61ea1884 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/edit.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/edit" */))
const _709ca190 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/news/index.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/news/index" */))
const _6e91c156 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/videos/index.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/videos/index" */))
const _2e85e088 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/news/edit.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/news/edit" */))
const _3e294557 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/videos/edit.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/videos/edit" */))
const _9d239fc0 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/news/_article/edit.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/news/_article/edit" */))
const _b8a3fd00 = () => interopDefault(import('../pages/profile/my-companies/_category/_company/videos/_video/edit.vue' /* webpackChunkName: "pages/profile/my-companies/_category/_company/videos/_video/edit" */))
const _4bb7511c = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _54cc4b94 = () => interopDefault(import('../pages/reactivation/index.vue' /* webpackChunkName: "pages/reactivation/index" */))
const _0d6930f4 = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))
const _1657b604 = () => interopDefault(import('../pages/search-results/index.vue' /* webpackChunkName: "pages/search-results/index" */))
const _8941c578 = () => interopDefault(import('../pages/sign-up-confirmation/index.vue' /* webpackChunkName: "pages/sign-up-confirmation/index" */))
const _537eade5 = () => interopDefault(import('../pages/sponsored-contributor-confirmation.vue' /* webpackChunkName: "pages/sponsored-contributor-confirmation" */))
const _7ef12ca0 = () => interopDefault(import('../pages/tag.vue' /* webpackChunkName: "pages/tag" */))
const _1a316650 = () => interopDefault(import('../pages/tag/search-results.vue' /* webpackChunkName: "pages/tag/search-results" */))
const _7a5b19db = () => interopDefault(import('../pages/tag/_slug.vue' /* webpackChunkName: "pages/tag/_slug" */))
const _87b0b9fe = () => interopDefault(import('../pages/terms-of-use.vue' /* webpackChunkName: "pages/terms-of-use" */))
const _500e56e6 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _a3dd727a = () => interopDefault(import('../pages/terms/search-results.vue' /* webpackChunkName: "pages/terms/search-results" */))
const _44b60560 = () => interopDefault(import('../pages/terms/index.vue' /* webpackChunkName: "pages/terms/index" */))
const _89dae9f4 = () => interopDefault(import('../pages/terms/_letter/index.vue' /* webpackChunkName: "pages/terms/_letter/index" */))
const _8d482c84 = () => interopDefault(import('../pages/terms/_letter/_slug.vue' /* webpackChunkName: "pages/terms/_letter/_slug" */))
const _ba03e03e = () => interopDefault(import('../pages/auth/google/callback/index.vue' /* webpackChunkName: "pages/auth/google/callback/index" */))
const _b1b2271a = () => interopDefault(import('../pages/author/_slug/index.vue' /* webpackChunkName: "pages/author/_slug/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1e6ca7a6 = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _20785cff = () => interopDefault(import('../pages/_category/education-centre/index.vue' /* webpackChunkName: "pages/_category/education-centre/index" */))
const _460e3f1d = () => interopDefault(import('../pages/_category/education-centre/_article/index.vue' /* webpackChunkName: "pages/_category/education-centre/_article/index" */))
const _75cd8712 = () => interopDefault(import('../pages/_category/education-centre/_article/amp/index.vue' /* webpackChunkName: "pages/_category/education-centre/_article/amp/index" */))
const _01fcca1e = () => interopDefault(import('../pages/_category/_subcategory/index.vue' /* webpackChunkName: "pages/_category/_subcategory/index" */))
const _318ca993 = () => interopDefault(import('../pages/_category/_subcategory/amp/index.vue' /* webpackChunkName: "pages/_category/_subcategory/amp/index" */))
const _99cdb644 = () => interopDefault(import('../pages/_category/_subcategory/_article/index.vue' /* webpackChunkName: "pages/_category/_subcategory/_article/index" */))
const _1904b75a = () => interopDefault(import('../pages/_category/_subcategory/_article/amp/index.vue' /* webpackChunkName: "pages/_category/_subcategory/_article/amp/index" */))
const _7aeb3697 = () => interopDefault(import('../pages/_category/_subcategory/_article/index' /* webpackChunkName: "pages/_category/_subcategory/_article/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about/",
    component: _d8a8f054,
    pathToRegexpOptions: {"strict":true},
    name: "about"
  }, {
    path: "/all-news/",
    component: _1b13702e,
    alias: ["/all-news/page/:number(\\d+)"],
    pathToRegexpOptions: {"strict":true},
    name: "all-news"
  }, {
    path: "/become-an-author/",
    component: _74569d52,
    pathToRegexpOptions: {"strict":true},
    name: "become-an-author"
  }, {
    path: "/contact-us/",
    component: _0cce6818,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us"
  }, {
    path: "/contributor-confirmation/",
    component: _6b0aac51,
    pathToRegexpOptions: {"strict":true},
    name: "contributor-confirmation"
  }, {
    path: "/cookies/",
    component: _24c6c615,
    pathToRegexpOptions: {"strict":true},
    name: "cookies"
  }, {
    path: "/events/",
    component: _21236146,
    pathToRegexpOptions: {"strict":true},
    name: "events"
  }, {
    path: "/forgot-password/",
    component: _37296ece,
    pathToRegexpOptions: {"strict":true},
    name: "forgot-password"
  }, {
    path: "/intelligence/",
    component: _1043c3ae,
    pathToRegexpOptions: {"strict":true},
    name: "intelligence",
    children: [{
      path: "products/",
      component: _71f51e3d,
      alias: ["products/page/:number(\\d+)"],
      pathToRegexpOptions: {"strict":true},
      name: "intelligence-products"
    }, {
      path: "products/annual/",
      component: _3d4221f2,
      pathToRegexpOptions: {"strict":true},
      name: "intelligence-products-annual"
    }, {
      path: "products/qir/:slug?/",
      component: _786c6d00,
      pathToRegexpOptions: {"strict":true},
      name: "intelligence-products-qir-slug"
    }, {
      path: "",
      component: _be08bc28,
      pathToRegexpOptions: {"strict":true},
      name: "intelligence"
    }]
  }, {
    path: "/privacy/",
    component: _37e8fae4,
    pathToRegexpOptions: {"strict":true},
    name: "privacy"
  }, {
    path: "/profile/",
    component: _d5f21fa2,
    pathToRegexpOptions: {"strict":true},
    name: "profile",
    children: [{
      path: "announcements/",
      component: _2ea16b2f,
      pathToRegexpOptions: {"strict":true},
      name: "profile-announcements"
    }, {
      path: "followed-authors/",
      component: _0da6b4de,
      pathToRegexpOptions: {"strict":true},
      name: "profile-followed-authors"
    }, {
      path: "followed-companies/",
      component: _6e7af7f1,
      pathToRegexpOptions: {"strict":true},
      name: "profile-followed-companies"
    }, {
      path: "followed-topics/",
      component: _83925f38,
      pathToRegexpOptions: {"strict":true},
      name: "profile-followed-topics"
    }, {
      path: "my-companies/",
      component: _14124ce6,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies"
    }, {
      path: "my-contributions/",
      component: _2ab8c895,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-contributions"
    }, {
      path: "notifications/",
      component: _abab7dea,
      pathToRegexpOptions: {"strict":true},
      name: "profile-notifications"
    }, {
      path: "saved/",
      component: _857d302c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-saved"
    }, {
      path: "my-companies/add-company/",
      component: _bde2cb68,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-add-company"
    }, {
      path: "my-contributions/edit/",
      component: _437b654c,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-contributions-edit"
    }, {
      path: "my-contributions/enums/",
      component: _5c3cd4cc,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-contributions-enums"
    }, {
      path: "my-contributions/edit/:articleSlug/",
      component: _eec3b0ac,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-contributions-edit-articleSlug"
    }, {
      path: "my-companies/:category?/:company/edit/",
      component: _61ea1884,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-edit"
    }, {
      path: "my-companies/:category?/:company/news/",
      component: _709ca190,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-news"
    }, {
      path: "my-companies/:category?/:company/videos/",
      component: _6e91c156,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-videos"
    }, {
      path: "my-companies/:category?/:company/news/edit/",
      component: _2e85e088,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-news-edit"
    }, {
      path: "my-companies/:category?/:company/videos/edit/",
      component: _3e294557,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-videos-edit"
    }, {
      path: "my-companies/:category?/:company/news/:article?/edit/",
      component: _9d239fc0,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-news-article-edit"
    }, {
      path: "my-companies/:category?/:company/videos/:video?/edit/",
      component: _b8a3fd00,
      pathToRegexpOptions: {"strict":true},
      name: "profile-my-companies-category-company-videos-video-edit"
    }, {
      path: "",
      component: _4bb7511c,
      pathToRegexpOptions: {"strict":true},
      name: "profile"
    }]
  }, {
    path: "/reactivation/",
    component: _54cc4b94,
    pathToRegexpOptions: {"strict":true},
    name: "reactivation"
  }, {
    path: "/reset-password/",
    component: _0d6930f4,
    pathToRegexpOptions: {"strict":true},
    name: "reset-password"
  }, {
    path: "/search-results/",
    component: _1657b604,
    pathToRegexpOptions: {"strict":true},
    name: "search-results"
  }, {
    path: "/sign-up-confirmation/",
    component: _8941c578,
    pathToRegexpOptions: {"strict":true},
    name: "sign-up-confirmation"
  }, {
    path: "/sponsored-contributor-confirmation/",
    component: _537eade5,
    pathToRegexpOptions: {"strict":true},
    name: "sponsored-contributor-confirmation"
  }, {
    path: "/tag/",
    component: _7ef12ca0,
    pathToRegexpOptions: {"strict":true},
    name: "tag",
    children: [{
      path: "search-results/",
      component: _1a316650,
      pathToRegexpOptions: {"strict":true},
      name: "tag-search-results"
    }, {
      path: ":slug?/",
      component: _7a5b19db,
      alias: [":slug?/page/:number(\\d+)"],
      pathToRegexpOptions: {"strict":true},
      name: "tag-slug"
    }]
  }, {
    path: "/terms-of-use/",
    component: _87b0b9fe,
    pathToRegexpOptions: {"strict":true},
    name: "terms-of-use"
  }, {
    path: "/terms/",
    component: _500e56e6,
    pathToRegexpOptions: {"strict":true},
    name: "terms",
    children: [{
      path: "search-results/",
      component: _a3dd727a,
      pathToRegexpOptions: {"strict":true},
      name: "terms-search-results"
    }, {
      path: "",
      component: _44b60560,
      pathToRegexpOptions: {"strict":true},
      name: "terms"
    }, {
      path: ":letter/",
      component: _89dae9f4,
      pathToRegexpOptions: {"strict":true},
      name: "terms-letter"
    }, {
      path: ":letter/:slug/",
      component: _8d482c84,
      alias: [":letter/:slug/page/:number(\\d+)"],
      pathToRegexpOptions: {"strict":true},
      name: "terms-letter-slug"
    }]
  }, {
    path: "/auth/google/callback/",
    component: _ba03e03e,
    pathToRegexpOptions: {"strict":true},
    name: "auth-google-callback"
  }, {
    path: "/author/:slug?/",
    component: _b1b2271a,
    alias: ["/author/:slug?/page/:number(\\d+)"],
    pathToRegexpOptions: {"strict":true},
    name: "author-slug"
  }, {
    path: "/",
    component: _2dfb1658,
    pathToRegexpOptions: {"strict":true},
    name: "index"
  }, {
    path: "/:category/",
    component: _1e6ca7a6,
    alias: ["/:category/page/:number(\\d+)"],
    pathToRegexpOptions: {"strict":true},
    name: "category"
  }, {
    path: "/:category/education-centre/",
    component: _20785cff,
    pathToRegexpOptions: {"strict":true},
    name: "category-education-centre"
  }, {
    path: "/:category/education-centre/:article?/",
    component: _460e3f1d,
    alias: ["/:category/education-centre/:article?/(/*)"],
    pathToRegexpOptions: {"strict":true},
    name: "category-education-centre-article"
  }, {
    path: "/:category/education-centre/:article?/amp/",
    component: _75cd8712,
    pathToRegexpOptions: {"strict":true},
    name: "category-education-centre-article-amp"
  }, {
    path: "/:category/:subcategory/",
    component: _01fcca1e,
    alias: ["/:category/:subcategory/page/:number(\\d+)","/:category/:subcategory/(/*)"],
    pathToRegexpOptions: {"strict":true},
    name: "category-subcategory"
  }, {
    path: "/:category/:subcategory/amp/",
    component: _318ca993,
    pathToRegexpOptions: {"strict":true},
    name: "category-subcategory-amp"
  }, {
    path: "/:category/:subcategory/:article/",
    component: _99cdb644,
    alias: ["/:category/:subcategory/:article/(/*)"],
    pathToRegexpOptions: {"strict":true},
    name: "category-subcategory-article"
  }, {
    path: "/:category/:subcategory/:article/amp/",
    component: _1904b75a,
    pathToRegexpOptions: {"strict":true},
    name: "category-subcategory-article-amp"
  }, {
    path: "/:category/:subcategory/:article/:gibberish",
    component: _7aeb3697,
    name: "category-subcategory-article-gibberish"
  }],

  fallback: false
}

function decodeObj(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'string') {
      obj[key] = decode(obj[key])
    }
  }
}

export function createRouter () {
  const router = new Router(routerOptions)

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    const r = resolve(to, current, append)
    if (r && r.resolved && r.resolved.query) {
      decodeObj(r.resolved.query)
    }
    return r
  }

  return router
}
