import { PERSONAL_AREA_ROUTES } from 'enums/routes'
import { QUERY_PARAM_NAME, TAB_QUERY_PARAM_NAME } from 'enums/personal-area'

const ROUTE_SETTINGS_WITHOUT_REQUESTING_DATA = PERSONAL_AREA_ROUTES.map(
  paRouteName => ({
    routeName: paRouteName,
    queryParams: [QUERY_PARAM_NAME, TAB_QUERY_PARAM_NAME]
  })
)

function getArrayDifference(arr1, arr2) {
  return arr1
    .filter(x => !arr2.includes(x))
    .concat(arr2.filter(x => !arr1.includes(x)))
}

/**
 * We request header/footer/stayConnected data on every route change.
 * But on Personal Area we additionally redirect user to the same route but
 * with new query params for active tab (source).
 * In such cases we don't need to get general data as it causes double requests
 * to the Backend.
 */
export function checkIfMiddlewareShouldBeSkipped(from, to) {
  const fromName = from ? from.name : ''
  const toName = to.name
  if (fromName !== toName) return false

  const fromQueryParams = Object.keys(from.query)
  const toQueryParams = Object.keys(to.query)
  const queryDiff = getArrayDifference(fromQueryParams, toQueryParams)

  if (!queryDiff.length) return false

  return ROUTE_SETTINGS_WITHOUT_REQUESTING_DATA.some(
    ({ routeName, queryParams }) => {
      if (to.name === routeName) {
        queryDiff.every(changedQuery => queryParams.includes(changedQuery))
      }
      return (
        to.name === routeName &&
        queryDiff.every(changedQuery => queryParams.includes(changedQuery))
      )
    }
  )
}
