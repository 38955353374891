/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'megaphone-inactive': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data:
      '<path pid="0" _fill="none" class="cls-1" d="M4.86 0h22.28A4.93 4.93 0 0 1 32 5v22a4.93 4.93 0 0 1-4.85 5H4.86A4.92 4.92 0 0 1 0 27V5a4.92 4.92 0 0 1 4.86-5z"/><path pid="1" class="cls-2" _fill="#b8b8b8" d="M27.14 32H4.86A4.94 4.94 0 0 1 0 27V5a4.94 4.94 0 0 1 4.86-5h22.28A4.94 4.94 0 0 1 32 5v22a4.94 4.94 0 0 1-4.86 5zM4.86 1A3.93 3.93 0 0 0 1 5v22a3.93 3.93 0 0 0 3.86 4h22.28A3.93 3.93 0 0 0 31 27V5a3.93 3.93 0 0 0-3.86-4z"/><path pid="2" class="cls-2" _fill="#b8b8b8" d="M5.11 14.48a3.11 3.11 0 0 1 1.27-1.91A3.06 3.06 0 0 1 8 12.14c1.11 0 2.23 0 3.33-.1A9.92 9.92 0 0 0 17.47 9c.52-.54 1-1.11 1.53-1.67a1.73 1.73 0 0 1 2-.46 1.66 1.66 0 0 1 1 1.53v15.12a1.6 1.6 0 0 1-1.39 1.64l-.19.06h-.32a2.33 2.33 0 0 1-1.23-.79 28.38 28.38 0 0 0-2.23-2.29A9.79 9.79 0 0 0 11.93 20c-.62-.11-1.27-.15-1.93-.23v3.85a1.48 1.48 0 0 1-.61 1.26 2.7 2.7 0 0 1-.77.4.77.77 0 0 1-.54 0c-1.1-.43-1.34-.84-1.34-2.05v-3.34a.48.48 0 0 0-.15-.36 3.14 3.14 0 0 1-1.48-2 6.38 6.38 0 0 1 0-3.05zm5.46 4.2a11.24 11.24 0 0 1 6.69 2.61 32.39 32.39 0 0 1 2.47 2.5c.22.24.44.4.77.28s.38-.41.37-.73V8.54c0-.3 0-.57-.37-.69a.64.64 0 0 0-.75.25c-.38.45-.78.89-1.18 1.33a11.37 11.37 0 0 1-5.83 3.47c-.71.16-1.43.26-2.17.4zm-1.11-5.43H7.84a1.62 1.62 0 0 0-1.65 1.6v2.2A1.58 1.58 0 0 0 7 18.4c.8.52 1.66.2 2.51.3zm-1.6 6.53v3.75c0 .39.19.58.53.57a.52.52 0 0 0 .54-.58v-3.74zM25.35 16.5c-.41 0-.66-.21-.66-.54s.25-.56.66-.56h3c.45 0 .69.22.68.58s-.24.52-.68.52-2.5.01-3 0zM25.44 21.86l-1.06-1.06c-.29-.3-.32-.62-.08-.86a.58.58 0 0 1 .86.08c.71.7.68.66 1.38 1.38.32.32.33.65.07.89s-.54.2-.85-.11l-.32-.32zM26.28 10.83l-1.06 1.06a.56.56 0 1 1-.79-.78c.7-.72.67-.68 1.38-1.38.32-.32.65-.33.89-.07s.2.53-.1.85.03-.04-.32.32z"/>'
  }
})
