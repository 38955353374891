export const FORM_TYPE = {
  CONTACT_US: 'ContactUs',
  EVENTS_MAILING_LIST: 'EventsMailingList',
  EVENTS_STICKY_BUTTON: 'EventsStickyButton',
  NEWSLETTER: 'Newsletter',
  INTELLIGENCE_CUSTOM_REPORT: 'CustomMadeReport',
  INTELLIGENCE_BUY_HERE: 'BuyHere',
  INTELLIGENCE_ANNUAL_SUBSCRIPTION: 'AnnualSubscription',
  BECOME_AN_AUTHOR: 'BecomeAContributor',
  SEND_ME_AN_OFFER: 'SendMeAnOffer',
  HP_NEWSLETTER: 'HpNewsletter',
  ARTICLE_NEWSLETTER: 'ArticleNewsletter',
  SUGGEST_A_TERM: 'SuggestATerm'
}
