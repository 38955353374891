export default {
  data() {
    return {
      isFocusTrapActive: false
    }
  },
  methods: {
    $_focusTrapState_onFocusTrapActivate() {
      this.isFocusTrapActive = true
    },
    $_focusTrapState_onFocusTrapDeactivate() {
      this.isFocusTrapActive = false
    },
    /**
     * We need to wait for the focus trap to deactivate before trying to set
     * cursor anywhere else. For example, if we try to set cursor into the
     * Jodit while the trap is still active it will result in "maximum call
     * stack size exceeded" error.
     */
    $_focusTrapState_waitForFocusTrapToDeactivate() {
      return new Promise(resolve => {
        this.$helper.watchAndExecuteOnce({
          ctx: this,
          field: 'isFocusTrapActive',
          handlerFn: resolve,
          conditionFn: newVal => !newVal,
          immediate: true
        })
      })
    }
  }
}
