import Vue from 'vue'

const bus = new Vue()

const eventBus = {
  install(Vue) {
    Vue.prototype.$bus = bus
  }
}

Vue.use(eventBus)

export default function(ctx, inject) {
  inject('bus', bus)
}
