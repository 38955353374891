import { processResponse } from '@/plugins/helper'

export function getCategories(categoryData) {
  const { category, parentCategory } = processResponse(categoryData)

  if (!parentCategory) {
    return {
      categoryName: category.name,
      categorySlug: category.slug,
      subcategoryName: null,
      subcategorySlug: null
    }
  }

  return {
    categoryName: parentCategory.name,
    categorySlug: parentCategory.slug,
    subcategoryName: category.name,
    subcategorySlug: category.slug
  }
}
