import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'
import { generateArticleSlotData } from '@/utils/helpers/models/article'

const state = () => ({})

const getters = {}

const actions = {
  async requestTagBySlug(_, { queryParams, pathParams: { slug } }) {
    try {
      const { data } = await this.$axiosV2.get(apiV2.tags.getTag(slug), {
        params: queryParams
      })
      return data
    } catch (err) {
      throw err
    }
  },
  async requestArticleByTagSlug(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.articles.getArticleByTagSlug(),
        { params }
      )

      data.Articles = (data.Articles || []).map(article =>
        generateArticleSlotData(article, this)
      )
      return data
    } catch (err) {
      throw err
    }
  }
}

const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
