import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_nuxtenv_001ebd4f from 'nuxt_plugin_nuxtenv_001ebd4f' // Source: ./nuxt-env.js (mode: 'all')
import nuxt_plugin_workbox_6cf6843c from 'nuxt_plugin_workbox_6cf6843c' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_metaplugin_313b02f5 from 'nuxt_plugin_metaplugin_313b02f5' // Source: ./pwa/meta.plugin.js (mode: 'all')
import nuxt_plugin_axios_80c87b74 from 'nuxt_plugin_axios_80c87b74' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_axios_5659d192 from 'nuxt_plugin_axios_5659d192' // Source: ../plugins/axios.js (mode: 'all')
import nuxt_plugin_setispreviewmode_47bc740d from 'nuxt_plugin_setispreviewmode_47bc740d' // Source: ../plugins/set-is-preview-mode.js (mode: 'all')
import nuxt_plugin_index_24d762cc from 'nuxt_plugin_index_24d762cc' // Source: ../plugins/client-side-init/index.js (mode: 'client')
import nuxt_plugin_breakpoints_0eed390b from 'nuxt_plugin_breakpoints_0eed390b' // Source: ../plugins/breakpoints.js (mode: 'all')
import nuxt_plugin_helper_850e665a from 'nuxt_plugin_helper_850e665a' // Source: ../plugins/helper.js (mode: 'all')
import nuxt_plugin_error_464d9065 from 'nuxt_plugin_error_464d9065' // Source: ../plugins/error.js (mode: 'all')
import nuxt_plugin_vuelidate_4be431c8 from 'nuxt_plugin_vuelidate_4be431c8' // Source: ../plugins/vuelidate.js (mode: 'all')
import nuxt_plugin_globalfilters_7552a3d0 from 'nuxt_plugin_globalfilters_7552a3d0' // Source: ../plugins/global-filters.js (mode: 'all')
import nuxt_plugin_eventbus_2faefc80 from 'nuxt_plugin_eventbus_2faefc80' // Source: ../plugins/event-bus.js (mode: 'all')
import nuxt_plugin_customgeneratehead_d8ed4336 from 'nuxt_plugin_customgeneratehead_d8ed4336' // Source: ../plugins/custom-generate-head.js (mode: 'all')
import nuxt_plugin_vuescrollto_44ce9a1c from 'nuxt_plugin_vuescrollto_44ce9a1c' // Source: ../plugins/vue-scrollto.js (mode: 'all')
import nuxt_plugin_infiniteScroll_7cfa8fa0 from 'nuxt_plugin_infiniteScroll_7cfa8fa0' // Source: ../plugins/infiniteScroll.js (mode: 'client')
import nuxt_plugin_accessibilityfriendlyfocus_01857fe8 from 'nuxt_plugin_accessibilityfriendlyfocus_01857fe8' // Source: ../plugins/accessibility-friendly-focus.js (mode: 'client')
import nuxt_plugin_vuesvgicon_0b246f97 from 'nuxt_plugin_vuesvgicon_0b246f97' // Source: ../plugins/vue-svgicon.js (mode: 'all')
import nuxt_plugin_vue2transitions_4ee690c4 from 'nuxt_plugin_vue2transitions_4ee690c4' // Source: ../plugins/vue2-transitions.js (mode: 'all')
import nuxt_plugin_globalcomponents_52ce1226 from 'nuxt_plugin_globalcomponents_52ce1226' // Source: ../plugins/global-components.js (mode: 'all')
import nuxt_plugin_vuepaginate_81bafbba from 'nuxt_plugin_vuepaginate_81bafbba' // Source: ../plugins/vue-paginate.js (mode: 'all')
import nuxt_plugin_vueobservevisibility_66676af7 from 'nuxt_plugin_vueobservevisibility_66676af7' // Source: ../plugins/vue-observe-visibility.js (mode: 'client')
import nuxt_plugin_vueperfectscrollbar_4f61232e from 'nuxt_plugin_vueperfectscrollbar_4f61232e' // Source: ../plugins/vue-perfect-scrollbar.js (mode: 'client')
import nuxt_plugin_beforeEach_70c4fc21 from 'nuxt_plugin_beforeEach_70c4fc21' // Source: ../plugins/beforeEach.js (mode: 'all')
import nuxt_plugin_afterEach_00c2ad60 from 'nuxt_plugin_afterEach_00c2ad60' // Source: ../plugins/afterEach.js (mode: 'all')
import nuxt_plugin_lockscroll_696886de from 'nuxt_plugin_lockscroll_696886de' // Source: ../plugins/lock-scroll.js (mode: 'client')
import nuxt_plugin_directives_521c0486 from 'nuxt_plugin_directives_521c0486' // Source: ../plugins/directives.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    return this.$root.$options.$nuxt
  },
  configurable: true
})

Vue.use(Meta, {"refreshOnceOnNavigation":true,"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const originalRegisterModule = Vuex.Store.prototype.registerModule
const baseStoreOptions = { preserveState: process.client }

function registerModule (path, rawModule, options = {}) {
  return originalRegisterModule.call(this, path, rawModule, { ...baseStoreOptions, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"hid":"charset","charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, minimum-scale=1"},{"hid":"description","name":"description","content":"Finance Magnates"}],"htmlAttrs":{"lang":"en"},"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"},{"rel":"shortcut icon","type":"image\u002Fx-icon","href":"\u002Ffavicon.ico"}],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_nuxtenv_001ebd4f === 'function') {
    await nuxt_plugin_nuxtenv_001ebd4f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_6cf6843c === 'function') {
    await nuxt_plugin_workbox_6cf6843c(app.context, inject)
  }

  if (typeof nuxt_plugin_metaplugin_313b02f5 === 'function') {
    await nuxt_plugin_metaplugin_313b02f5(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_80c87b74 === 'function') {
    await nuxt_plugin_axios_80c87b74(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5659d192 === 'function') {
    await nuxt_plugin_axios_5659d192(app.context, inject)
  }

  if (typeof nuxt_plugin_setispreviewmode_47bc740d === 'function') {
    await nuxt_plugin_setispreviewmode_47bc740d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_index_24d762cc === 'function') {
    await nuxt_plugin_index_24d762cc(app.context, inject)
  }

  if (typeof nuxt_plugin_breakpoints_0eed390b === 'function') {
    await nuxt_plugin_breakpoints_0eed390b(app.context, inject)
  }

  if (typeof nuxt_plugin_helper_850e665a === 'function') {
    await nuxt_plugin_helper_850e665a(app.context, inject)
  }

  if (typeof nuxt_plugin_error_464d9065 === 'function') {
    await nuxt_plugin_error_464d9065(app.context, inject)
  }

  if (typeof nuxt_plugin_vuelidate_4be431c8 === 'function') {
    await nuxt_plugin_vuelidate_4be431c8(app.context, inject)
  }

  if (typeof nuxt_plugin_globalfilters_7552a3d0 === 'function') {
    await nuxt_plugin_globalfilters_7552a3d0(app.context, inject)
  }

  if (typeof nuxt_plugin_eventbus_2faefc80 === 'function') {
    await nuxt_plugin_eventbus_2faefc80(app.context, inject)
  }

  if (typeof nuxt_plugin_customgeneratehead_d8ed4336 === 'function') {
    await nuxt_plugin_customgeneratehead_d8ed4336(app.context, inject)
  }

  if (typeof nuxt_plugin_vuescrollto_44ce9a1c === 'function') {
    await nuxt_plugin_vuescrollto_44ce9a1c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_infiniteScroll_7cfa8fa0 === 'function') {
    await nuxt_plugin_infiniteScroll_7cfa8fa0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_accessibilityfriendlyfocus_01857fe8 === 'function') {
    await nuxt_plugin_accessibilityfriendlyfocus_01857fe8(app.context, inject)
  }

  if (typeof nuxt_plugin_vuesvgicon_0b246f97 === 'function') {
    await nuxt_plugin_vuesvgicon_0b246f97(app.context, inject)
  }

  if (typeof nuxt_plugin_vue2transitions_4ee690c4 === 'function') {
    await nuxt_plugin_vue2transitions_4ee690c4(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_52ce1226 === 'function') {
    await nuxt_plugin_globalcomponents_52ce1226(app.context, inject)
  }

  if (typeof nuxt_plugin_vuepaginate_81bafbba === 'function') {
    await nuxt_plugin_vuepaginate_81bafbba(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueobservevisibility_66676af7 === 'function') {
    await nuxt_plugin_vueobservevisibility_66676af7(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vueperfectscrollbar_4f61232e === 'function') {
    await nuxt_plugin_vueperfectscrollbar_4f61232e(app.context, inject)
  }

  if (typeof nuxt_plugin_beforeEach_70c4fc21 === 'function') {
    await nuxt_plugin_beforeEach_70c4fc21(app.context, inject)
  }

  if (typeof nuxt_plugin_afterEach_00c2ad60 === 'function') {
    await nuxt_plugin_afterEach_00c2ad60(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_lockscroll_696886de === 'function') {
    await nuxt_plugin_lockscroll_696886de(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_directives_521c0486 === 'function') {
    await nuxt_plugin_directives_521c0486(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // If server-side, wait for async component to be resolved first
  if (process.server && ssrContext && ssrContext.url) {
    await new Promise((resolve, reject) => {
      router.push(ssrContext.url, resolve, (err) => {
        // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
        if (!err._isRouter) return reject(err)
        if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

        // navigated to a different route in router guard
        const unregister = router.afterEach(async (to, from) => {
          ssrContext.url = to.fullPath
          app.context.route = await getRouteData(to)
          app.context.params = to.params || {}
          app.context.query = to.query || {}
          unregister()
          resolve()
        })
      })
    })
  }

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
