<template>
  <div class="google-captcha-policy">
    This site is protected by reCAPTCHA and the Google
    <a-link
      class="text-underline"
      to="https://policies.google.com/privacy"
      open-in-new-tab
      directive="nofollow"
      >Privacy Policy</a-link
    >
    and
    <a-link
      class="text-underline"
      to="https://policies.google.com/terms"
      open-in-new-tab
      directive="nofollow"
      >Terms of Service</a-link
    >
    apply.
  </div>
</template>

<script>
export default {
  name: 'AGoogleCaptchaTermsOfUse'
}
</script>

<style lang="scss" scoped>
.google-captcha-policy {
  margin-bottom: 21px;

  font-size: 11px;
  line-height: 12px;
}
</style>
