import { mapGetters, mapMutations } from 'vuex'

import { mapActionsLazy } from '@/plugins/helper'
import * as types from '@/store/mutation-types'
import { ROUTE_NAME } from 'enums/routes'
import { MODAL } from '@/components/_modals/AModalWrapper'
import { setSentryUserDetails } from '@/plugins/error'
import { SLIDE_NUMBER } from 'shared/AMakeYourAccountBetter/slide'
const OPEN_MAKE_YOUR_ACCOUT_BETTER_TIMEOUT = 15 * 1000 // 15 seconds

export const userDetails = {
  data() {
    return {
      exclusionRules: [
        () =>
          !!this.$route.query.becomeAContributorToken ||
          !!this.$route.query.becomeASponsoredContributorToken
      ]
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      userDetails: 'auth/userDetails',
      wasOnboardingFlowShownDuringSession:
        'auth/wasOnboardingFlowShownDuringSession',
      isContributorWithoutDetails: 'auth/isContributorWithoutDetails'
    })
  },
  watch: {
    isLoggedIn: {
      async handler(newVal) {
        if (newVal) {
          this.setMakeYourAccountBetterFlowPendingStatus(true)
          await this.$_userDetails_requestUserDetails()
          this.$nextTick(this.$_userDetails_makeYourAccountBetterFlow)
        } else {
          this.clearUserDetails()
          this.updateWasOnboardingFlowShownValue(false)
          setSentryUserDetails(null)
        }
      }
    }
  },
  methods: {
    ...mapActionsLazy({
      requestCurrentUserDetails: 'auth/requestCurrentUserDetails',
      requestRegisterUserSession: 'personal-area/requestRegisterUserSession'
    }),
    ...mapMutations({
      clearUserDetails: `auth/${types.CLEAR_USER_DETAILS}`,
      updateWasOnboardingFlowShownValue: `auth/${types.UPDATE_WAS_ONBOARDING_FLOW_SHOWN_VALUE}`,
      setMakeYourAccountBetterFlowPendingStatus:
        types.SET_MAKE_YOUR_ACCOUNT_BETTER_FLOW_PENDING_STATUS
    }),
    async $_userDetails_requestUserDetails() {
      try {
        if (this.isLoggedIn && !this.userDetails.Id) {
          await this.requestCurrentUserDetails()
          setSentryUserDetails(this.userDetails)
          if (this.isContributorWithoutDetails) {
            this.$router.replace({ name: ROUTE_NAME.PERSONAL_PROFILE })
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    async $_userDetails_makeYourAccountBetterFlow() {
      /**
       * MakeYourAccountBetter modal window should be opened during
       * first 3 user sessions only and if user haven't completed this flow before.
       * - First session (just after email confirmation or sign-up via social networks):
       * user is redirected to Personal Area and modal window opens with
       * MakeYourAccountBetter flow.
       * - Second and third session - user will not be redirected and modal
       * window will open after 15 seconds after login.
       */
      const isModalWindowShouldOpen =
        this.isLoggedIn &&
        this.userDetails.IsOnboardingFlowCompleted === false &&
        this.userDetails.ShowTellUsWhatYouLikeModal &&
        !this.isContributorWithoutDetails
      const isFirstSession = this.userDetails.SessionCount === 0

      /**
       * This condition is related to different layouts to avoid duplicate
       * modal appearance after changing default/intelligence layouts
       */
      if (this.wasOnboardingFlowShownDuringSession) return

      if (!isModalWindowShouldOpen) {
        this.setMakeYourAccountBetterFlowPendingStatus(false)
      } else {
        let previousLocation = null

        if (isFirstSession) {
          previousLocation = decodeURIComponent(
            this.$route.query.location || this.$route.fullPath
          )

          const openMakeYourAccountBetter = () =>
            this.$_userDetails_openMakeYourAccountBetter({ previousLocation })

          this.$router.push(
            {
              name: ROUTE_NAME.PERSONAL_PROFILE
            },
            openMakeYourAccountBetter
          )
        } else {
          const openMakeYourAccountBetter = () =>
            this.$_userDetails_openMakeYourAccountBetter({ previousLocation })

          setTimeout(
            openMakeYourAccountBetter,
            OPEN_MAKE_YOUR_ACCOUT_BETTER_TIMEOUT
          )
        }
      }
    },
    async $_userDetails_openMakeYourAccountBetter({ previousLocation }) {
      if (this.isLoggedIn) {
        this.$helper.closeAllModals()
        this.$helper.openModal(MODAL.MAKE_YOUR_ACCOUNT_BETTER, {
          currentSlideNumber: SLIDE_NUMBER.WELCOME,
          ...(previousLocation ? { previousLocation } : {})
        })
        this.updateWasOnboardingFlowShownValue(true)
        try {
          await this.requestRegisterUserSession()
        } catch (err) {
          this.$errorHandler(err, this, {
            showErrorPage: false,
            showMessage: true
          })
        }
      }
    }
  },
  async beforeMount() {
    if (this.exclusionRules.map(rule => rule()).every(v => !v)) {
      await this.$_userDetails_requestUserDetails()
      this.$nextTick(this.$_userDetails_makeYourAccountBetterFlow)
    }
  }
}
