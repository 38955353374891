<template>
  <div class="success-message">
    <div class="success-message__icon">
      <a-icon
        :icon="icon"
        :width="iconWidth"
        :height="iconHeight"
        :color="ICON_COLOR.BLUE"
        original
        no-hover
        class="share-icon"
      />
    </div>
    <p class="success-message__title">{{ title }}</p>

    <p class="success-message__text">
      <slot v-if="$slots.text" name="text" />
      <template v-else>{{ text }}</template>
    </p>

    <div v-if="$slots.default" class="success-message__slot">
      <slot />
    </div>
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import AIcon, { ICON_COLOR } from 'shared/AIcon'

export { ICON } from 'shared/AIcon'

export default {
  name: 'ASuccessMessage',
  components: { AIcon },
  props: {
    title: propValidator([PROP_TYPES.STRING], false),
    text: propValidator([PROP_TYPES.STRING], false),
    icon: propValidator([PROP_TYPES.STRING]),
    iconHeight: propValidator([PROP_TYPES.NUMBER], false, 100),
    iconWidth: propValidator([PROP_TYPES.NUMBER], false, 100)
  },
  data() {
    return { ICON_COLOR }
  }
}
</script>

<style lang="scss" scoped>
.success-message {
  width: 100%;
  height: 100%;
  color: $c--main;
  background: #fff;
  padding: 100px;

  @include mobile {
    padding: 50px;
  }

  &__icon {
    display: flex;
    justify-content: center;
    fill: #000; // hello
  }

  &__title {
    margin: 29px 0 11px;
    text-align: center;

    font-size: 24px;
    font-weight: bold;

    @include mobile {
      font-size: 16px;
    }
  }

  &__text {
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    white-space: pre-line;

    @include mobile {
      font-size: 16px;
    }
  }

  &__slot {
    width: 100%;
  }
}
</style>
