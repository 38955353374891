import { equals } from 'ramda'

import * as types from './mutation-types'
import { getElByDataRef } from '@fmpedia/helpers'
import {
  checkIfElementIsDataRef,
  runOnRefResize,
  getBoundingClientRectCustom
} from '@/plugins/helper'

const state = () => ({
  dataRefsToWatch: new Set(),
  mutationObserver: null,
  rectDataByDataRef: {},
  elByDataRef: new Map(),
  resizeListenerRemoverByDataRef: {}
})

const getters = {
  rectDataByDataRef: state => state.rectDataByDataRef
}

const actions = {
  watchDataRefs({ commit, dispatch, state }, dataRefs) {
    if (!dataRefs || !Array.isArray(dataRefs)) {
      console.error('dataRefs must be Array')
      return
    }

    commit(types.ADD_DATA_REFS_TO_WATCH, dataRefs)
  },
  unwatchDataRefs({ commit, dispatch, state }, dataRefs) {
    if (!dataRefs || !Array.isArray(dataRefs) || !dataRefs.length) {
      return
    }

    commit(types.REMOVE_DATA_REFS_FROM_WATCH, dataRefs)
    for (const dataRef of dataRefs) {
      dispatch('handleElResizeForDataRef', { dataRef, el: null })
      commit(types.REMOVE_RECT_DATA_BY_DATA_REF, { dataRef })
      if (state.elByDataRef.has(dataRef)) {
        state.elByDataRef.delete(dataRef)
      }
    }
  },
  unwatchAllDataRefs({ dispatch, state }) {
    dispatch('unwatchDataRefs', [...state.dataRefsToWatch])
  },
  handleMutationObserver({ dispatch, state }) {
    if (state.dataRefsToWatch.size === 0) {
      dispatch('stopMutationObserver')
    } else {
      dispatch('startMutationObserver')
    }
  },
  startMutationObserver({ dispatch, state }) {
    for (const dataRef of state.dataRefsToWatch) {
      const el = getElByDataRef(dataRef)
      dispatch('updateElRectDataForDataRef', { dataRef, el })
      dispatch('handleElResizeForDataRef', { dataRef, el })
      if (el) {
        state.elByDataRef.set(dataRef, el)
      } else if (state.elByDataRef.has(dataRef)) {
        state.elByDataRef.delete(dataRef)
      }
    }

    if (state.mutationObserver) return

    const observer = new MutationObserver(mutationsList => {
      for (const mutation of mutationsList) {
        if (mutation.type !== 'childList') return

        for (const addedNode of mutation.addedNodes) {
          for (const dataRef of state.dataRefsToWatch) {
            const targetSelector = `[data-ref="${dataRef}"]`
            const isDataRef = checkIfElementIsDataRef(addedNode, dataRef)
            const dataRefInChildren =
              addedNode.querySelector && addedNode.querySelector(targetSelector)
            const el = isDataRef ? addedNode : dataRefInChildren

            if (el) {
              state.elByDataRef.set(dataRef, el)
              dispatch('updateElRectDataForDataRef', { dataRef, el })
              dispatch('handleElResizeForDataRef', { dataRef, el })
            }
          }
        }

        for (const removedNode of mutation.removedNodes) {
          for (const dataRef of state.dataRefsToWatch) {
            const targetSelector = `[data-ref="${dataRef}"]`
            const isDataRef = checkIfElementIsDataRef(removedNode, dataRef)
            const dataRefInChildren =
              removedNode.querySelector &&
              removedNode.querySelector(targetSelector)

            if (isDataRef || dataRefInChildren) {
              const removedDataRef = isDataRef ? removedNode : dataRefInChildren

              const isRemovedNodeTheLatestEl =
                removedDataRef &&
                state.elByDataRef.has(dataRef) &&
                removedDataRef === state.elByDataRef.get(dataRef)

              if (!isRemovedNodeTheLatestEl) return

              dispatch('updateElRectDataForDataRef', { dataRef, el: null })
              dispatch('handleElResizeForDataRef', { dataRef, el: null })
              if (state.elByDataRef.has(dataRef)) {
                state.elByDataRef.delete(dataRef)
              }
            }
          }
        }
      }
    })
    observer.observe(document.body, {
      childList: true,
      subtree: true
    })
    dispatch('saveMutationObserver', observer)
  },
  stopMutationObserver({ dispatch, state }) {
    if (!state.mutationObserver) return

    state.mutationObserver.disconnect()
    dispatch('removeMutationObserver')
  },
  saveMutationObserver({ commit }, observer) {
    commit(types.SAVE_MUTATION_OBSERVER, observer)
  },
  removeMutationObserver({ commit }) {
    commit(types.REMOVE_MUTATION_OBSERVER)
  },
  updateElRectDataForDataRef({ commit }, { dataRef, el }) {
    const rectData = el ? getBoundingClientRectCustom(el) : null

    commit(types.UPDATE_RECT_DATA_BY_DATA_REF, { dataRef, rectData })
  },
  handleElResizeForDataRef({ commit, dispatch, state }, { dataRef, el }) {
    if (el && !state.resizeListenerRemoverByDataRef[dataRef]) {
      const resizeObserverRemover = runOnRefResize({
        dataRef,
        fn: ({ rectData }) => {
          commit(types.UPDATE_RECT_DATA_BY_DATA_REF, {
            dataRef,
            rectData
          })
        }
      })
      commit(types.SAVE_RESIZE_OBSERVER_REMOVER, {
        dataRef,
        remover: resizeObserverRemover
      })
    }

    if (!el && state.resizeListenerRemoverByDataRef[dataRef]) {
      state.resizeListenerRemoverByDataRef[dataRef]()
      commit(types.REMOVE_RESIZE_OBSERVER_REMOVER, { dataRef })
    }
  }
}

const mutations = {
  [types.ADD_DATA_REFS_TO_WATCH](state, dataRefs) {
    dataRefs.forEach(dataRef => {
      state.dataRefsToWatch.add(dataRef)
    })
  },
  [types.REMOVE_DATA_REFS_FROM_WATCH](state, dataRefs) {
    dataRefs.forEach(dataRef => {
      state.dataRefsToWatch.delete(dataRef)
    })
  },
  [types.SAVE_MUTATION_OBSERVER](state, observer) {
    state.mutationObserver = observer
  },
  [types.REMOVE_MUTATION_OBSERVER](state) {
    state.mutationObserver = null
  },
  [types.UPDATE_RECT_DATA_BY_DATA_REF](state, { dataRef, rectData }) {
    const existingData = state.rectDataByDataRef[dataRef]
    if (equals(existingData, rectData)) {
      console.log('Data is not changed. Return.')
      return
    }
    state.rectDataByDataRef = {
      ...state.rectDataByDataRef,
      [dataRef]: rectData
        ? {
            top: rectData.top,
            bottom: rectData.bottom,
            left: rectData.left,
            right: rectData.right,
            width: rectData.width,
            height: rectData.height
          }
        : null
    }
  },
  [types.REMOVE_RECT_DATA_BY_DATA_REF](state, { dataRef }) {
    state.rectDataByDataRef = Object.entries(state.rectDataByDataRef).reduce(
      (acc, [key, value]) => {
        if (key === dataRef) return acc

        return {
          ...acc,
          [key]: value
        }
      },
      {}
    )
  },
  [types.SAVE_RESIZE_OBSERVER_REMOVER](state, { dataRef, remover }) {
    state.resizeListenerRemoverByDataRef = {
      ...state.resizeListenerRemoverByDataRef,
      [dataRef]: remover
    }
  },
  [types.REMOVE_RESIZE_OBSERVER_REMOVER](state, { dataRef }) {
    state.resizeListenerRemoverByDataRef = Object.entries(
      state.resizeListenerRemoverByDataRef
    ).reduce((acc, [key, value]) => {
      if (key === dataRef) return acc

      return {
        ...acc,
        [key]: value
      }
    }, {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
