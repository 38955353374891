<template>
  <make-your-account-better
    v-if="isLazyLoaded"
    @hook:mounted="$_lazyLoadModal_onComponentMounted"
  />
</template>

<script>
import { MODAL } from '@/components/_modals/AModalWrapper'
import mixins from '@/utils/mixins'

export default {
  name: 'AMakeYourAccountLazyLoad',
  mixins: [mixins.lazyLoadModal(MODAL.MAKE_YOUR_ACCOUNT_BETTER)],
  components: {
    MakeYourAccountBetter: () => import('./index')
  }
}
</script>

<style lang="scss" scoped></style>
