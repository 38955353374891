export const HEADER_STATE = {
  COLLAPSED: 'collapsed',
  EXPANDED: 'expanded'
}

export const HEADER_ID = 'header-menu'

export const TOP_STICKY_CONTAINER_ID = 'top-sticky-container'

export const HEADER_ICON_SIZE = {
  DESKTOP: 32,
  MOBILE: 20
}

export const HEADER_HEIGHT = {
  DESKTOP_EXPANDED: 102,
  DESKTOP_COLLAPSED: 50,
  TABLET: 75,
  MOBILE: 56
}

export const TOP_HEADER_STRIP_HEIGHT = 40
