import { REFS } from 'enums/external-refs'
import { ROUTE_NAME } from 'enums/routes'
import {
  getFirstArticleBannerContainerHeight,
  getSecondArticleBannerContainerHeight
} from '@/utils/helpers/sideBannersMovement/articlePage'
import {
  getFirstHomepageBannerContainerHeight,
  getSecondHomepageBannerContainerHeight
} from '@/utils/helpers/sideBannersMovement/homepage'

const HOMEPAGE_SETTINGS = {
  prepareRectData: rectDataByDataRef => {
    return {
      pageWrap: rectDataByDataRef[REFS.LAYOUT_PAGE_WRAP],
      combinedSection: rectDataByDataRef[REFS.HOMEPAGE_COMBINED_SECTION],
      videosSection: rectDataByDataRef[REFS.HOMEPAGE_VIDEOS_SECTION]
    }
  },
  handlers: [
    getFirstHomepageBannerContainerHeight,
    getSecondHomepageBannerContainerHeight
  ]
}

const ARTICLE_PAGE_SETTINGS = {
  prepareRectData: rectDataByDataRef => {
    return {
      content: rectDataByDataRef[REFS.ARTICLE_PAGE_CONTENT],
      moreFromTheAuthor:
        rectDataByDataRef[REFS.ARTICLE_PAGE_MORE_FROM_THE_AUTHOR],
      categoryNews: rectDataByDataRef[REFS.ARTICLE_PAGE_CATEGORY_NEWS]
    }
  },
  handlers: [
    getFirstArticleBannerContainerHeight,
    getSecondArticleBannerContainerHeight
  ]
}

export const SIDE_BANNERS_SETTINGS_BY_PAGE = {
  [ROUTE_NAME.HOMEPAGE]: HOMEPAGE_SETTINGS,
  [ROUTE_NAME.CATEGORY_SUBCATEGORY]: ARTICLE_PAGE_SETTINGS,
  [ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE]: ARTICLE_PAGE_SETTINGS,
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE]: ARTICLE_PAGE_SETTINGS
}

export const GAP_BETWEEN_SIDE_BANNERS = 50 // SCSS in ALayoutBannersSection component
export const GAP_BETWEEN_SECTIONS = 40
export const LAYOUT_TOP_PADDING = 40
