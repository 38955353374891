<template>
  <component
    :is="wrapperComponent"
    :to="to"
    :open-in-new-tab="openInNewTab"
    :directive="directive"
    :title="hint"
    type="button"
    class="a-icon"
    :class="iconClasses"
    :style="wrapperDynamicStyle"
    v-bind="wrapperAttrs"
    @click="onClick"
    @focus="onFocus"
    @blur="onBlur"
  >
    <svgicon
      v-if="isIconLoaded"
      :icon="icon"
      class="a-icon__icon"
      :style="iconStyle"
      :original="original"
    />
    <div v-else :style="iconStyle" />
    <template v-if="$helper.isNotAmpPage($route.name)">
      <span
        v-if="title || titleOnHover"
        class="a-icon__description text-super-tiny"
        :style="titleDynamicStyle"
        :title="title"
        :title-on-hover="titleOnHover || title"
        :width="titleWidth"
      />
    </template>
    <span v-else class="a-icon__description amp text-super-tiny">{{
      title
    }}</span>
    <slot name="additional-info" />
  </component>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'

import '@/assets/svg/icons-compiled/fl'
import '@/assets/svg/icons-compiled/megaphone-active'
import '@/assets/svg/icons-compiled/megaphone-inactive'
import '@/assets/svg/icons-compiled/bell-active'
import '@/assets/svg/icons-compiled/bell-inactive'
import '@/assets/svg/icons-compiled/newsletter-rect-blue'
import '@/assets/svg/icons-compiled/envelope-rect-blue'
import '@/assets/svg/icons-compiled/magnifier'
import '@/assets/svg/icons-compiled/envelope-2'
import '@/assets/svg/icons-compiled/fm-mobile'

import { ICON } from './enums'
export { ICON }

/**
 * When modifying this list make sure you remove/add a relevant import above!
 */
const ICONS_TO_EXCLUDE_FROM_LAZY_LOAD = [
  ICON.FL,
  ICON.MEGAPHONE_ACTIVE,
  ICON.MEGAPHONE_INACTIVE,
  ICON.BELL_ACTIVE,
  ICON.BELL_INACTIVE,
  ICON.NEWSLETTER_RECT_BLUE,
  ICON.ENVELOPE_RECT_BLUE,
  ICON.MAGNIFIER,
  ICON.ENVELOPE_2,
  ICON.FM_MOBILE
]

const ICON_VALUES = Object.values(ICON)

export const ICON_COLOR = {
  BLUE: 'Blue',
  WHITE: 'White',
  BLACK: 'Black',
  CUSTOM: 'Custom'
}

export const ICON_COLOR_VALUES = Object.values(ICON_COLOR)

const ICON_CLASS = {
  [ICON_COLOR.BLACK]: 'a-icon_black',
  [ICON_COLOR.WHITE]: 'a-icon_white',
  [ICON_COLOR.BLUE]: 'a-icon_blue',
  [ICON_COLOR.CUSTOM]: 'a-icon_custom'
}

const ICON_DEFAULT_SIZE = 35

const WRAPPER_COMPONENT = {
  BUTTON: 'button',
  LINK: 'a-link',
  DIV: 'div'
}

const DEFAULT_TITLE_WIDTH = 115

export default {
  name: 'AIcon',
  inheritAttrs: false,
  props: {
    icon: propValidator([PROP_TYPES.STRING], true, null, icon =>
      ICON_VALUES.includes(icon)
    ),
    title: propValidator([PROP_TYPES.STRING], false, ''),
    titleOnHover: propValidator([PROP_TYPES.STRING], false, ''),
    titleWidth: propValidator([PROP_TYPES.NUMBER], false, DEFAULT_TITLE_WIDTH),
    hint: propValidator([PROP_TYPES.STRING], false, ''),
    to: propValidator([PROP_TYPES.STRING, PROP_TYPES.OBJECT], false),
    width: propValidator(
      [PROP_TYPES.NUMBER, PROP_TYPES.STRING],
      false,
      ICON_DEFAULT_SIZE
    ),
    height: propValidator([PROP_TYPES.NUMBER], false, ICON_DEFAULT_SIZE),
    color: propValidator([PROP_TYPES.STRING], false, ICON_COLOR.BLACK, prop =>
      ICON_COLOR_VALUES.includes(prop)
    ),
    /**
     * This prop turns off iconColor props because original colors are used
     * Can be used for colored icons but there is no possibility to change
     * icon colors on hover
     */
    original: propValidator([PROP_TYPES.BOOLEAN], false, false),
    disabled: propValidator([PROP_TYPES.BOOLEAN], false, false),
    isButton: propValidator([PROP_TYPES.BOOLEAN], false, false),
    noHover: propValidator([PROP_TYPES.BOOLEAN], false, false),
    openInNewTab: propValidator([PROP_TYPES.ANY], false, undefined),
    directive: propValidator([PROP_TYPES.STRING], false, undefined),
    fill: propValidator([PROP_TYPES.STRING], false),
    id: propValidator([PROP_TYPES.STRING], false, ''),
    ariaLabel: propValidator([PROP_TYPES.STRING], false, '')
  },
  data() {
    return {
      isIconLoaded: false
    }
  },
  computed: {
    wrapperComponent() {
      if (this.isButton) {
        return WRAPPER_COMPONENT.BUTTON
      }

      if (this.to && !this.disabled) {
        return WRAPPER_COMPONENT.LINK
      }

      return WRAPPER_COMPONENT.DIV
    },
    iconWrapperWidthStyleValue() {
      return this.$helper.isString(this.width) ? this.width : `${this.width}px`
    },
    wrapperDynamicStyle() {
      return {
        width: this.iconWrapperWidthStyleValue
      }
    },
    iconStyle() {
      return {
        width: this.iconWrapperWidthStyleValue,
        height: `${this.height}px`,
        ...(this.fill ? { fill: this.fill } : {})
      }
    },
    iconClasses() {
      return [
        ICON_CLASS[this.color],
        {
          'a-icon_original': this.original,
          'a-icon_disabled': this.disabled,
          'a-icon_div': this.wrapperComponent === WRAPPER_COMPONENT.DIV,
          'a-icon_no-hover': this.noHover,
          'a-icon_is-button': this.isButton
        }
      ]
    },
    titleDynamicStyle() {
      return {
        width: `${this.titleWidth}px`
        // maxWidth: `${this.titleWidth}px`
      }
    },
    wrapperAttrs() {
      return {
        ...(this.id ? { id: this.id } : {}),
        ...(this.ariaLabel ? { 'aria-label': this.ariaLabel } : {})
      }
    }
  },
  watch: {
    icon: {
      async handler() {
        this.isIconLoaded = false

        if (ICONS_TO_EXCLUDE_FROM_LAZY_LOAD.includes(this.icon)) {
          this.isIconLoaded = true
          return
        }

        await import(`@/assets/svg/icons-compiled/${this.icon}`)
        this.isIconLoaded = true
      },
      immediate: true
    }
  },
  methods: {
    onClick() {
      if (this.disabled || this.wrapperComponent === WRAPPER_COMPONENT.DIV) {
        return
      }

      this.$emit('click')
    },
    onBlur(event) {
      this.$emit('blur', event)
    },
    onFocus(event) {
      this.$emit('focus', event)
    }
  },
  created() {
    if (process.server && this.$helper.isAmpPage(this.$route.name)) {
      this.isIconLoaded = true

      Object.values(ICON).forEach(icon => {
        require(`@/assets/svg/icons-compiled/${icon}`)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.a-icon {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .a-icon__description {
    width: 100%;
    margin-top: 2px;
    transition: opacity 0.1s;
    content: '';
    text-align: center;

    &::before {
      display: inline-block;
      width: 100%;
      max-width: 200px; /* maximum title width */
      content: attr(title);
      text-align: center;
    }

    &.amp {
      width: 50px;
    }

    &.amp::before {
      display: none;
    }
  }

  &.a-icon_disabled {
    opacity: 0.4;
    cursor: not-allowed;

    &.a-icon_is-button {
      cursor: not-allowed;
    }
  }

  &.a-icon_is-button {
    cursor: pointer;
  }

  .a-icon__icon {
    transition: width 0.5s, height 0.5s;
  }

  .a-icon_black .a-icon__icon {
    fill: $c--black;
  }

  &.a-icon_white {
    .a-icon__icon {
      fill: $c--white;
    }

    .a-icon__description {
      color: $c--white;
    }
  }

  &.a-icon_blue .a-icon__icon {
    fill: $c--main;
  }

  &.a-icon_no-hover {
    color: $c--black;
  }
}

.a-icon.a-icon_no-hover:hover {
  color: $c--black;
}

.a-icon:not(.a-icon_no-hover):not(.a-icon_disabled):hover {
  .a-icon__description::before {
    content: attr(title-on-hover);
  }

  &.a-icon_original {
    .a-icon__icon {
      opacity: 0.7;
    }
  }

  &.a-icon_blue,
  &.a-icon_white {
    .a-icon__icon {
      fill: $c--black;
    }

    .a-icon__description {
      color: $c--gray-main;
    }
  }

  &.a-icon_black {
    .a-icon__icon {
      fill: $c--main;
    }

    .a-icon__description {
      color: $c--main;
    }
  }
}
</style>
