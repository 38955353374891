import {
  CUSTOM_HTML_TAG,
  CUSTOM_HTML_TAG_VALUES,
  EMPTY_TEXT_CHARS,
  HTML_TAG,
  IMAGE_SETTINGS,
  INLINE_EXCLUSION_ELEMENTS_TO_UNWRAP_FROM_PARAGRAPH,
  INLINE_HTML_TAGS,
  SPECIAL_CHAR,
  IMAGE_ALIGNMENT
} from '@fmpedia/enums'

export {
  CUSTOM_HTML_TAG,
  CUSTOM_HTML_TAG_VALUES,
  HTML_TAG,
  INLINE_HTML_TAGS,
  SPECIAL_CHAR,
  EMPTY_TEXT_CHARS,
  INLINE_EXCLUSION_ELEMENTS_TO_UNWRAP_FROM_PARAGRAPH,
  IMAGE_SETTINGS,
  IMAGE_ALIGNMENT
}

export const MULTIPLE_COLUMNS_DATA_ATTRIBUTE = 'data-multiple-columns'
