import { QUERY_PARAM_VALUE, TAB_NAME } from 'enums/personal-area'

export default {
  computed: {
    $_sourceTabs_sourceQueryParam() {
      return this.$route.query.source || ''
    },
    $_sourceTabs_isSourceFm() {
      return (
        this.$_sourceTabs_sourceQueryParam.toLowerCase() ===
        QUERY_PARAM_VALUE[TAB_NAME.FM]
      )
    },
    $_sourceTabs_isSourceFl() {
      return (
        this.$_sourceTabs_sourceQueryParam.toLowerCase() ===
        QUERY_PARAM_VALUE[TAB_NAME.FL]
      )
    }
  }
}
