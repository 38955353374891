import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

export default {
  mixins: [hydrationHelpers],
  data() {
    return {
      refDimensions: {}
    }
  },
  watch: {
    $_hydrationHelpers_windowWidth: {
      immediate: true,
      handler() {
        if (process.client) {
          this.$nextTick(this.$_refDimensions_setDimensions)
        }
      }
    }
  },
  methods: {
    $_refDimensions_setDimensions() {
      if (this.$helper.isObject(this.$refs)) {
        Object.entries(this.$refs).forEach(([key, value]) => {
          if (value) {
            const el = this.$_refDimensions_getRefElement(value)
            this.$set(this.refDimensions, key, {
              width: el ? el.clientWidth : 0,
              height: el ? el.clientHeight : 0
            })
          }
        })
      }

      if (
        this.$_refDimensions_runOnChange &&
        this.$helper.checkIfFunction(this.$_refDimensions_runOnChange)
      ) {
        this.$_refDimensions_runOnChange()
      }
    },
    $_refDimensions_getRefElement(ref) {
      if (!ref) return null

      if (Array.isArray(ref)) {
        return ref[0] || null
      } else {
        return ref.$el || ref
      }
    }
  }
}
