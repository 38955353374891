/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope-2': {
    width: 512,
    height: 512,
    viewBox: '0 0 382.117 382.117',
    data:
      '<path pid="0" d="M336.764 45.945H45.354C20.346 45.945 0 65.484 0 89.5v203.117c0 24.016 20.346 43.555 45.354 43.555h291.41c25.008 0 45.353-19.539 45.353-43.555V89.5c0-24.016-20.345-43.555-45.353-43.555zm0 251.775H45.354c-3.676 0-6.9-2.384-6.9-5.103V116.359l131.797 111.27a15.014 15.014 0 0 0 9.676 3.538l22.259.001c3.536 0 6.974-1.257 9.677-3.539l131.803-111.274v176.264c-.002 2.717-3.227 5.101-6.902 5.101zM191.059 192.987L62.87 84.397h256.378l-128.189 108.59z" _fill="#FFF"/>'
  }
})
