export const LAYOUT_STICKY_TOP_PADDING = 0
export const INTELLIGENCE_QIR_SEARCH_TAKE_PER_PAGE = 9

export const PAGINATION_MAX_VISIBLE_PAGES = {
  DESKTOP: 5,
  MOBILE: 2
}

/**
 * Define the timeout after what successful modal message should be closed
 */
export const SUCCESS_MESSAGE_TIMEOUT = 5000
export const HEADER_TRANSITION_DELAY = 500
export const THROTTLE_DEFAULT_DURATION = 200

export const FOOTER_MARGIN_TOP = 15
