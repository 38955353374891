import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestEvents() {
    try {
      const { data } = await this.$axios.get(api.events.getConfiguration())
      return data
    } catch (e) {
      throw e
    }
  },
  async requestEventsSocialMediaPosts() {
    try {
      const { data: Posts } = await this.$axios.get(
        api.events.getEventsSocialPosts()
      )
      return Posts.Posts
    } catch (e) {
      throw e
    }
  },
  async submitMailingListLead(_, { payload, captchaKey }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: captchaKey }
    }

    try {
      await this.$axios.post(
        api.events.submitMailingListLead(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  },
  async submitJoinEventsLead(_, { payload, headerParams }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }

      await this.$axiosV2.post(
        apiV2.marketing.submitJoinEventsLead(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {}
