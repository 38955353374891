const intelligence = {
  slug: 'f/intelligence',
  purchaseQirBundle: () =>
    `${intelligence.slug}/reports/quarterly/bundle/purchase`,
  purchaseQirSingle: id =>
    `${intelligence.slug}/reports/quarterly/${id}/purchase`,
  getQirList: () => `${intelligence.slug}/reports/quarterly`,
  getQir: slug => `${intelligence.slug}/reports/quarterly/${slug}`,
  getLatestQir: () => `${intelligence.slug}/reports/quarterly/latest`,
  getQirPackages: () => `${intelligence.slug}/reports/quarterly/packages`,
  getIntelligenceHpQirGallery: () =>
    `${intelligence.slug}/reports/quarterly/gallery`,
  getAnnualPageLinks: () => `${intelligence.slug}/layout/annual/links`
}

export default intelligence
