// Was implemented for PayPal payment flow
// TODO - DO NOT DELETE can be used for another flows
/** Allow to exclude needed route from navigation history
 *  (so, user can not go back to this route) **/

import { ROUTE_NAME } from 'enums/routes'

const ROUTES_TO_REPLACE = [ROUTE_NAME.PAYPAL_PAYMENT]

function checkIfRouteToReplace(routeName) {
  return ROUTES_TO_REPLACE.includes(routeName)
}

let wasRouteReplaced = false

export default function({ app: { router }, from, route }) {
  const routeFromName = from ? from.name : null
  const routeToPath = route.path

  if (!process.browser || !routeFromName || wasRouteReplaced) {
    wasRouteReplaced = false
    return
  }

  if (checkIfRouteToReplace(routeFromName)) {
    wasRouteReplaced = true
    return router.replace(routeToPath)
  }
}
