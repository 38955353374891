export const DEVICE_ID_COOKIE_NAME = 'deviceId'
/** 30 years in days **/
export const DEVICE_ID_COOKIE_EXPIRES_IN_DAYS = 365 * 30
/** 30 years in seconds **/
export const DEVICE_ID_COOKIE_MAX_AGE =
  DEVICE_ID_COOKIE_EXPIRES_IN_DAYS * 60 * 60 * 24
export const GA_COOKIE_NAME = '_ga'

export const HTTP_STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  GONE: 410,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503
}
