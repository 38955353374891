import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestCategoriesQuicksearch(_, { pathParams }) {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.categories.categoriesQuicksearch(),
        {
          params: pathParams
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestFiltersByFmDirCategoryId(_, params) {
    try {
      const { data } = await this.$axios.get(
        api.directory.getFiltersByCategoryId(),
        {
          params
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestFiltersByFlDirCategoryId(_, params) {
    try {
      const { data } = await this.$flAxios.get(
        api.directory.getFiltersByCategoryId(),
        {
          params
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestCreateFmDirCompany(_, payload) {
    try {
      const { data } = await this.$axios.post(
        api.directory.createCompany(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestCreateFlDirCompany(_, payload) {
    try {
      const { data } = await this.$flAxios.post(
        api.directory.createCompany(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestUpdateFmDirCompany(_, payload) {
    try {
      const { data } = await this.$axios.post(
        api.directory.updateCompany(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestUpdateFlDirCompany(_, payload) {
    try {
      const { data } = await this.$flAxios.post(
        api.directory.updateCompany(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestSaveFmDirCompanyPreview(_, payload) {
    try {
      const { data } = await this.$axios.post(
        api.directory.saveCompanyPreview(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestSaveFlDirCompanyPreview(_, payload) {
    try {
      const { data } = await this.$flAxios.post(
        api.directory.saveCompanyPreview(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestPinCompanyVideo(_, payload) {
    try {
      await this.$axios.post(api.directory.pinCompanyVideo(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestDirectoryCategoriesRotated() {
    try {
      const { data } = await this.$axios.get(
        api.directory.getDirectoryCategoryRotated()
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestCreateCompanyVideo(_, payload) {
    try {
      await this.$axios.post(api.directory.createCompanyVideo(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestUpdateCompanyVideo(_, payload) {
    try {
      await this.$axios.post(api.directory.updateCompanyVideo(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestCreateCompanyNews(_, payload) {
    try {
      await this.$axios.post(api.directory.createArticle(), payload)
    } catch (err) {
      throw err
    }
  },
  async requestUpdateCompanyNews(_, payload) {
    try {
      await this.$axios.post(api.directory.updateArticle(), payload)
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
