import { mapActions } from 'vuex'
import { MEDIA_CENTER_SCOPE } from 'enums/media-center'

export default {
  methods: {
    ...mapActions({
      requestUploadFmFile: 'media/requestUploadFmFile',
      requestUploadFmDirFile: 'media/requestUploadFmDirFile',
      requestUploadFlFile: 'media/requestUploadFlFile',
      requestUploadFlDirFile: 'media/requestUploadFlDirFile'
    }),
    $_mediaCenter_getUploadHandler(mediaCenterScope) {
      const handlerMap = {
        [MEDIA_CENTER_SCOPE.FM]: this.requestUploadFmFile,
        [MEDIA_CENTER_SCOPE.FM_DIR]: this.requestUploadFmDirFile,
        [MEDIA_CENTER_SCOPE.FL]: this.requestUploadFlFile,
        [MEDIA_CENTER_SCOPE.FL_DIR]: this.requestUploadFlDirFile
      }

      return handlerMap[mediaCenterScope]
    },
    $_mediaCenter_createImageFormData({
      file,
      name,
      aspectRatio,
      title,
      caption
    }) {
      const formData = new FormData()

      formData.append('File', file, name)
      formData.append('Title', title)
      formData.append('Caption', caption)
      formData.append('AspectRatio', aspectRatio)

      return formData
    },
    async $_mediaCenter_uploadImageAndGetDetails({
      file,
      name,
      aspectRatio,
      title = name,
      caption,
      mediaCenterScope = MEDIA_CENTER_SCOPE.FM
    }) {
      try {
        const formData = this.$_mediaCenter_createImageFormData({
          file,
          name,
          aspectRatio,
          title,
          caption
        })
        return await this.$_mediaCenter_getUploadHandler(mediaCenterScope)({
          payload: formData
        })
      } catch (err) {
        throw err
      }
    }
  }
}
