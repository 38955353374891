import { mapActionsLazy } from '@/plugins/helper'

const SAVED_ENTITY = {
  ARTICLE: 'Article',
  DIRECTORY_NEWS: 'DirectoryNews',
  COMPANY: 'Company',
  TAG: 'Tag',
  CATEGORY: 'Category',
  AUTHOR: 'Author'
}

export default {
  computed: {
    $_refreshDataHandler_savedEntity() {
      return SAVED_ENTITY
    }
  },
  methods: {
    ...mapActionsLazy({
      requestSavedEntities: 'personal-area/requestSavedEntities',
      requestArticleContentBySlug: 'articles/requestArticleContentBySlug',
      requestAuthor: 'author/requestAuthor'
    }),
    $_refreshDataHandler_updateIsSavedValue(
      { id, value },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(article.id === id ? { isSaved: value } : {})
      }))
    },
    $_refreshDataHandler_updateIsFollowedTagValue(
      { id, value },
      articles = this.articles
    ) {
      return articles.map(article => {
        if (!article.mainTag || article.mainTag.id !== id) return article

        return {
          ...article,
          mainTag: {
            ...article.mainTag,
            isFollowed: value
          },
          tags: article.tags.map(tag => ({
            ...tag,
            isFollowed: tag.id === id ? value : tag.isFollowed
          }))
        }
      })
    },
    $_refreshDataHandler_resetActionIconValues(articles = this.articles) {
      return articles.map(article => ({
        ...article,
        isSaved: false,
        mainTag: { ...article.mainTag, isFollowed: false }
      }))
    },
    async $_refreshDataHandler_getSavedIdsOfEntities(arrayOfEntities) {
      const payload = { entityTypes: arrayOfEntities }
      try {
        const { SavedEntityIds } = await this.requestSavedEntities(payload)
        return SavedEntityIds
      } catch (err) {
        this.$errorHandler(err, this)
        return {}
      }
    },
    $_refreshDataHandler_updateArticleSavedEntities(
      { articleIds, tagIds },
      articles = this.articles
    ) {
      return articles.map(article => ({
        ...article,
        ...(articleIds && articleIds.includes(article.id)
          ? { isSaved: true }
          : {}),
        ...(tagIds && tagIds.includes(article.mainTag && article.mainTag.id)
          ? { mainTag: { ...article.mainTag, isFollowed: true } }
          : {})
      }))
    },
    async $_refreshDataHandler_getSingleArticleEntities(payload) {
      try {
        const {
          isSaved,
          author: { isFollowed },
          tags,
          mainTag
        } = await this.requestArticleContentBySlug(payload)

        return {
          isSaved,
          isFollowed,
          tags,
          mainTag
        }
      } catch (err) {
        this.$errorHandler(err, this)
        return {}
      }
    },
    async $_refreshDataHandler_getIsFollowedAuthorValue(payload) {
      try {
        const { IsFollowed } = await this.requestAuthor(payload)
        return IsFollowed
      } catch (err) {
        this.$errorHandler(err, this)
        return false
      }
    }
  }
}
