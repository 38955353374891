import { removeStartingSlash } from '@fmpedia/helpers'
import { getLastPathParam, isStringStartsWith } from '@/plugins/helper'
import { getGroupTypesByPageName, handleEndingSlash } from 'enums/seo'
import { MIDDLEWARE } from 'enums/middleware'
import { ROUTE_NAME } from 'enums/routes'
import { SPECIAL_SUBCATEGORY } from 'enums/special-categories'
import { prop } from 'ramda'

const isPersonalProfileRoute = route =>
  isStringStartsWith(ROUTE_NAME.PERSONAL_PROFILE, prop('name', route))

const isMiddlewareSkipped = (app, route) =>
  app.$helper.isMiddlewareSkipped(MIDDLEWARE.PAGE_SEO, route) ||
  isPersonalProfileRoute(route)

export default async function({ app, store, route, redirect }) {
  if (isMiddlewareSkipped(app, route)) return

  try {
    const possibleTypes = getGroupTypesByPageName(route.name)
    const value = getPageSlug(route, possibleTypes)

    const requestPageSeoFn = () => {
      const queryParams = {
        possibleTypes,
        value
      }
      return store.dispatch('seo/requestPageSeo', { queryParams })
    }

    const requestHomepageSeoFn = () => store.dispatch('seo/requestHomepageSeo')

    /**
     * We always fetch Homepage SEO since it is used for
     * general purposes, such as og:site_name value.
     * To avoid multiple requests for HP SEO, we check
     * current route name.
     */
    const promises = [requestHomepageSeoFn()]

    if (route.name !== ROUTE_NAME.HOMEPAGE) {
      promises.push(requestPageSeoFn())
    }

    await Promise.all(promises)
  } catch (err) {
    redirect()
  }
}

function getPageSlug(route, possibleTypes) {
  const { article, subcategory, category, slug } = route.params

  if (ROUTE_NAME.CATEGORY_EDUCATION_CENTER === route.name) {
    return `${category}/${SPECIAL_SUBCATEGORY.EDUCATION_CENTER}`
  }

  if (ROUTE_NAME.CATEGORY_SUBCATEGORY === route.name) {
    return `${category}/${subcategory}`
  }

  if (
    [ROUTE_NAME.TAG_SLUG, ROUTE_NAME.TERMS_LETTER_SLUG].includes(route.name)
  ) {
    return handleEndingSlash(getLastPathParam(route.path), possibleTypes)
  }

  const articlePageSlugWithEncodedSymbols = article
    ? getLastPathParam(route.path)
    : ''

  const subcategorySlugWithEncodedSymbols = subcategory
    ? getLastPathParam(route.path)
    : ''

  const pageSlug =
    articlePageSlugWithEncodedSymbols ||
    subcategorySlugWithEncodedSymbols ||
    category ||
    slug ||
    route.path.replace(/page\/([0-9]*)\/*$/, '')

  return `${removeStartingSlash(
    handleEndingSlash(pageSlug, possibleTypes)
  )}`.toLowerCase()
}
