const users = {
  slug: 'f/users',
  registrationWithEmail: () => `${users.slug}/registration/email`,
  confirmationOfRegistration: () => `${users.slug}/registration/email/confirm`,
  loginWithEmail: () => `${users.slug}/authenticate`,
  refreshAccessToken: () => `${users.slug}/reauthenticate`,
  registrationWithFacebook: () => `${users.slug}/registration/facebook`,
  loginWithFacebook: () => `${users.slug}/authenticate/facebook`,
  registrationWithGoogle: () => `${users.slug}/registration/google`,
  getAuthStatus: () => `${users.slug}/get-auth-status`,
  loginWithGoogle: () => `${users.slug}/authenticate/google`,
  registrationWithTwitter: () => `${users.slug}/registration/twitter`,
  loginWithTwitter: () => `${users.slug}/authenticate/twitter`
}

export default users
