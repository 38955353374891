/**
 * The following slugs should keep their casing as it contradicts general rules:
 * - Slugs should be in lowercase.
 * - Encoded symbols should be in uppercase.
 * (see convertToLowercaseExceptEncodedToUppercase handler)
 */
export const LEGACY_SLUGS = [
  'Eddie-Tofpik-beware-sanke-oil-house-get-your-regulation-in-order',
  'OTC-Markets-Petitions-SEC-to-Expand-Online-Capital-Raising-to-Reporting-Firms',
  'Shanghai-Futures-Exchange-Launches-Nasdaq-SMARTS-for-Market-Surveillance',
  'Bank-Leumi-to-Pay-1.6-Million-for-Illegal-US-Cross-Border-Business',
  'Germany%E2%80%99s-Push-for-Financial-Transaction-Tax-Is-Bad-News-for-Traders',
  'Finotec-Trading-UK-All-Set-Growth-Spurt-2017',
  'Dominance+of+Banks+in+FX+Faces+Severe+Challenge',
  'Yorkshire-Group-Charged-%241.5m-for-Illegal-Transactions',
  'FlexTrade-to-Stream-Institutional-Research-in-its-EMS-from-RSRCHXchange',
  'Brexit-Process-Faces-Legal-Action-From-Law-Firm-Mishcon-de-Reya-in-UK',
  'The+Parallels+between+Building+a+Football+and+a+Trading+Team',
  'IronFX-UK-Renamed-Entity-Sees-Declining-Revenues-in-2015',
  'Why-I-Trust-The-Trading-Technology-Unlocking-the-Insight-of-the-Masses',
  'japanese-investigators-suspects-%c2%a511-7b-investment-scam',
  'world-first-opens-amsterdam-office-better-serve-benelux-clients-%e2%80%8e'
]
