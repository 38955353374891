<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import scss from '@/utils/scss'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

const { breakpoints } = scss

const WARN_MESSAGES = {
  BREAKPOINT_NOT_VALID: () => 'Such breakpoint does not exist',
  SHOW_HIDE_NOT_PRESENT: () =>
    `You should provide either 'show' or 'hide' option`,
  SHOW_HIDE_BOTH_PRESENT: () =>
    `You should provide either 'show' or 'hide' option, not both at the same time`,
  WRONG_RANGE: (from, to) => `${from} - ${to} is not a valid range`,
  NO_BREAKPOINT_PROVIDED: () => 'You have to provide at least one breakpoint',
  NO_SLOT: () => `No slot was provided`
}

const CLASS_BY_BREAKPOINT = {
  [breakpoints.mobile]: 'hide-on-mobile',
  [breakpoints.tablet]: 'hide-on-tablet',
  [breakpoints.desktopSm]: 'hide-on-desktop-sm',
  [breakpoints.desktopMd]: 'hide-on-desktop-md',
  [breakpoints.desktopLg]: 'hide-on-desktop-lg',
  [breakpoints.desktopXl]: 'hide-on-desktop-xl',
  [breakpoints.aboveDesktopXl]: 'hide-on-above-desktop-xl'
}

const BREAKPOINT_VALUES = Object.values(breakpoints)

function validateBreakpoint(breakpoint) {
  const isValid = BREAKPOINT_VALUES.some(v => v === breakpoint)

  if (!isValid) {
    console.warn(WARN_MESSAGES.BREAKPOINT_NOT_VALID())
  }

  return isValid
}

function validateBreakpoints(breakpoints) {
  return (
    !breakpoints ||
    breakpoints.length === 0 ||
    breakpoints.every(b => b !== null && validateBreakpoint(b))
  )
}

export default {
  name: 'AVisibility',
  mixins: [hydrationHelpers],
  props: {
    show: propValidator([PROP_TYPES.BOOLEAN], false, false),
    hide: propValidator([PROP_TYPES.BOOLEAN], false, false),
    /**
     * Starting point including (!) breakpoint passed
     **/
    from: propValidator(
      [PROP_TYPES.NUMBER],
      false,
      breakpoints.mobile,
      validateBreakpoint
    ),
    /**
     * Ending point including (!) breakpoint passed
     **/
    to: propValidator(
      [PROP_TYPES.NUMBER],
      false,
      breakpoints.aboveDesktopXl,
      validateBreakpoint
    ),
    /**
     * Array of specific breakpoint values
     **/
    on: propValidator([PROP_TYPES.ARRAY], false, null, validateBreakpoints),
    keepOnClient: propValidator([PROP_TYPES.BOOLEAN], false, true),
    /**
     * We use this prop to disable the AVisibility component and pass through the
     * original slot.
     * It is very convenient for AMP pages. If something is implemented to be
     * shown on mobile only, it should be shown on all the breakpoints on AMP page.
     * To avoid unnecessary v-if/v-else in the templates, this prop can be used.
     */
    alwaysShow: propValidator([PROP_TYPES.BOOLEAN], false, false)
  },
  data() {
    return {
      isValid: true,
      isRendered: false
    }
  },
  computed: {
    targetBreakpoints() {
      /**
       * If "on" is provided, it takes precedence over "from" - "to"
       */
      if (this.on) return this.on

      /**
       * "from" - "to" is a special case of an Array provided with "on", so we can treat them similarly
       */
      return BREAKPOINT_VALUES.slice(
        BREAKPOINT_VALUES.indexOf(this.from),
        BREAKPOINT_VALUES.indexOf(this.to) + 1
      )
    },

    breakpointsToHideElementOn() {
      if (this.show) {
        return BREAKPOINT_VALUES.filter(
          b => !this.targetBreakpoints.includes(b)
        )
      } else if (this.hide) {
        return this.targetBreakpoints
      }

      return []
    },
    breakpointsToShowElementOn() {
      return BREAKPOINT_VALUES.filter(
        b => !this.breakpointsToHideElementOn.includes(b)
      )
    },

    generatedClasses() {
      return this.breakpointsToHideElementOn.map(
        breakpoint => CLASS_BY_BREAKPOINT[breakpoint]
      )
    },

    resultingClass() {
      return this.generatedClasses.join(' ')
    },

    /**
     * If a slot should be hidden given current css breakpoint, remove it from the DOM
     **/
    isHiddenOnClient() {
      if (this.keepOnClient) return false

      return (
        (this.isRendered &&
          this.$_hydrationHelpers_isVisibleOnRange(
            this.breakpointsToHideElementOn
          )) ||
        (this.isRendered && !this.isValid)
      )
    },
    isHiddenByParentVisibility() {
      const parentVisibility = this.findParentAVisibility()
      if (!parentVisibility) return false

      const parentHideOn = parentVisibility.breakpointsToHideElementOn

      return this.breakpointsToShowElementOn.every(breakpoint =>
        parentHideOn.includes(breakpoint)
      )
    }
  },
  created() {
    this.setValidity()
  },
  mounted() {
    this.isRendered = true
  },
  updated() {
    this.setValidity()
  },
  methods: {
    setValidity() {
      this.isValid = this.isInputValid()
    },

    isInputValid() {
      if (!this.$slots.default) {
        console.warn(WARN_MESSAGES.NO_SLOT())
        return false
      }

      if (!this.show && !this.hide) {
        console.warn(WARN_MESSAGES.SHOW_HIDE_NOT_PRESENT())
        return false
      } else if (this.show && this.hide) {
        console.warn(WARN_MESSAGES.SHOW_HIDE_BOTH_PRESENT())
        return false
      }

      if (this.from && this.to && this.from >= this.to) {
        console.warn(WARN_MESSAGES.WRONG_RANGE(this.from, this.to))
        return false
      }

      if (!this.from && !this.to && !this.on) {
        console.warn(WARN_MESSAGES.NO_BREAKPOINT_PROVIDED())
        return false
      }

      return true
    },

    /**
     * When text node is passed in a slot, we create a span wrapper with text as its child
     **/
    renderSlotWithNoTag(h) {
      return h(
        'span',
        {
          class: this.resultingClass
        },
        [this.$slots.default[0]]
      )
    },

    addCustomClassesToSlot() {
      const slotData = this.$slots.default[0].data
        ? this.$slots.default[0].data
        : {}

      /**
       * Excluding class duplicates on each rendering iteration
       **/
      if (slotData.staticClass) {
        const setOfClasses = new Set(
          slotData.staticClass.split(' ').concat(this.generatedClasses)
        )

        slotData.staticClass = Array.from(setOfClasses).join(' ')
      }

      const slotStaticClass = slotData.staticClass || this.resultingClass

      this.$slots.default[0].data = slotData
      this.$slots.default[0].data.staticClass = slotStaticClass
    },
    findParentAVisibility(vueComponent = this) {
      let parent = vueComponent.$parent
      let visibilityComponent
      while (parent && !visibilityComponent) {
        const componentName = parent.$options.name
        if (componentName === this.$options.name) {
          visibilityComponent = parent
        } else {
          parent = parent.$parent
        }
      }

      return visibilityComponent
    }
  },
  render(h) {
    if (this.alwaysShow) {
      return this.$slots.default[0]
    }

    if (
      this.isHiddenOnClient ||
      !this.$slots.default ||
      this.isHiddenByParentVisibility
    ) {
      return null
    }

    try {
      if (!this.$slots.default[0].tag) {
        return this.renderSlotWithNoTag(h)
      }

      this.addCustomClassesToSlot()

      return this.$slots.default[0]
    } catch (err) {
      this.$errorHandler(err, this, { showMessage: false })
      return null
    }
  }
}
</script>
