<template>
  <pedia-assistant
    v-if="isLazyLoaded"
    :api-base-url="$env.API_URL"
    ref="pediaAssistant"
    class="pedia-assistant"
    @hook:mounted="loadedPromiseResolve"
    @show-results="onShowResults"
  />
</template>

<script>
import { mapGetters } from 'vuex'

export const ASSISTANT_BUS_NAME = 'open-pedia-assistant'

export default {
  name: 'APediaAssistantLazyLoad',
  components: {
    PediaAssistant: () => import('@fmpedia/assistant')
  },
  data() {
    return {
      isLazyLoaded: false,
      loadedPromiseResolve: () => {}
    }
  },
  computed: {
    ...mapGetters({
      isPageNavigationInProgress: 'isPageNavigationInProgress'
    })
  },
  watch: {
    isPageNavigationInProgress(newVal) {
      if (!newVal || !this.$refs.pediaAssistant) return

      this.$refs.pediaAssistant.onExitAssistant()
    }
  },
  methods: {
    initAssistant() {
      this.$bus.$on(ASSISTANT_BUS_NAME, async () => {
        if (!this.isLazyLoaded) {
          this.isLazyLoaded = true
          await new Promise(resolve => {
            this.loadedPromiseResolve = resolve
          })
          this.$refs.pediaAssistant.activateAssistant()
        } else {
          this.$refs.pediaAssistant.activateAssistant()
        }
      })
    },
    onShowResults({ categorySlug, Filters }) {
      const filterListObject = this.createFilterQueryParams(Filters)

      const queryString = this.$helper.serializeQueryParams(filterListObject)
      const resultPageUrl = `${this.$env.FM_DIR_DOMAIN_URL}/${categorySlug}?${queryString}`
      window.open(resultPageUrl, '_blank')
    },
    createFilterQueryParams(filters) {
      return filters.reduce((acc, { FilterIds, Values }) => {
        const isBooleanFilter = FilterIds.length > 1
        const filterObject = FilterIds.reduce(
          (acc, id, index) => ({
            ...acc,
            [id]: isBooleanFilter ? Values[index] : Values
          }),
          {}
        )

        return {
          ...acc,
          ...filterObject
        }
      }, {})
    }
  },
  mounted() {
    this.initAssistant()
  },
  beforeDestroy() {
    this.$bus.$off(ASSISTANT_BUS_NAME)
  }
}
</script>

<style lang="scss" scoped>
div.pedia-assistant.pedia-assistant__wrapper {
  /deep/ .pedia-assistant__overlay {
    z-index: 99999;
  }
}
</style>
