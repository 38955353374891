<template>
  <client-only>
    <collapse-transition>
      <div
        v-if="isValidationErrorContainerVisible"
        class="input-group__error"
        :class="inputGroupDynamicClass"
      >
        {{ $_validation_errorMessages(error)[0] }}
      </div>
    </collapse-transition>
  </client-only>
</template>

<script>
import mixins from '@/utils/mixins'
import { propValidator, PROP_TYPES } from '@/utils/validators'

export default {
  name: 'AValidationMessage',
  mixins: [mixins.validation],
  props: {
    error: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    absolute: propValidator([PROP_TYPES.BOOLEAN], false, true)
  },
  computed: {
    inputGroupDynamicClass() {
      return {
        'input-group__error-static': !this.absolute
      }
    },
    isValidationErrorContainerVisible() {
      return !this.absolute || this.$_validation_hasValidationError(this.error)
    }
  }
}
</script>
