import {
  GAP_BETWEEN_SECTIONS,
  GAP_BETWEEN_SIDE_BANNERS,
  LAYOUT_TOP_PADDING
} from './enums'

export function getFirstHomepageBannerContainerHeight(rectData) {
  const { pageWrap, combinedSection } = rectData

  if (!combinedSection || !pageWrap) return null

  const { top: combinedSectionTop } = combinedSection
  const { top: pageWrapTop } = pageWrap

  const resultHeight =
    combinedSectionTop -
    pageWrapTop -
    LAYOUT_TOP_PADDING -
    GAP_BETWEEN_SIDE_BANNERS

  return Math.floor(resultHeight)
}
export function getSecondHomepageBannerContainerHeight(rectData) {
  const { combinedSection, videosSection } = rectData

  if (!combinedSection || !videosSection) return null

  const { top: combinedSectionTop } = combinedSection
  const { top: videosSectionTop } = videosSection

  const resultHeight =
    videosSectionTop - combinedSectionTop - GAP_BETWEEN_SECTIONS

  return Math.floor(resultHeight)
}
