import * as types from '@/store/mutation-types'

export default async function({ from, store, redirect, route }) {
  if (!process.client || store.getters['router/isRouteChangeAllowed']) {
    return
  }

  if (route.params.force) return

  store.commit(types.SET_PAGE_NAVIGATION_IN_PROGRESS_STATE, false)

  const isRedirectConfirmed = await new Promise(resolve => {
    store.dispatch('router/requestRouteChange')
    store.dispatch('router/saveRouteChangeRequestResolver', resolve)
  })
  if (!isRedirectConfirmed) {
    redirect(from)
  } else {
    store.commit(types.SET_PAGE_NAVIGATION_IN_PROGRESS_STATE, true)
  }
}
