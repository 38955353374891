import { mapGetters } from 'vuex'
import { mapActionsLazy } from '@/plugins/helper'
import { PENDING_ACTION } from 'enums/pending-actions'
import { EXPAND_NEWSLETTER_WIDGET_EVENT } from '@/components/_sections/article/AArticleTemplate/BlackNewsletterFormWidget/enums'
import { REFS } from 'enums/external-refs'
import { waitForElAppearsInDom } from '@fmpedia/helpers'
import { FM_DIR_ROUTE } from 'enums/routes'
import { SEARCH_HISTORY_SOURCE } from 'enums/directory'

export default {
  data() {
    return {
      getUpdateHandlerByActionType: {
        [PENDING_ACTION.SAVE_ARTICLE]: ({ newValue }) =>
          newValue
            ? this.requestSaveForLater
            : this.requestDeleteFmSavedArticle,
        [PENDING_ACTION.FOLLOW_TAG]: ({ newValue }) =>
          newValue ? this.requestFollowFmTags : this.requestUnfollowFmTags,
        [PENDING_ACTION.FOLLOW_AUTHOR]: ({ newValue }) =>
          newValue
            ? this.requestFollowFmAuthors
            : this.requestUnfollowFmAuthors,
        [PENDING_ACTION.OPEN_ARTICLE_NEWSLETTER_WIDGET]: () => async () => {
          const isNewsletterWidgetLoaded = await waitForElAppearsInDom({
            dataRef: REFS.ARTICLE_PAGE_NEWSLETTER_WIDGET
          })

          if (!isNewsletterWidgetLoaded) return

          this.$bus.$emit(EXPAND_NEWSLETTER_WIDGET_EVENT)
        },
        [PENDING_ACTION.SAVE_SEARCH_HISTORY]: () =>
          this.requestSaveSearchHistory,
        [PENDING_ACTION.FOLLOW_COMPANY]: () => this.requestFollowFmCompanies
      },
      generateUpdatePayloadByActionType: {
        [PENDING_ACTION.SAVE_ARTICLE]: ({ id }) => ({
          pathParams: { articleId: id }
        }),
        [PENDING_ACTION.FOLLOW_TAG]: ({ id }) => ({ payload: { Ids: [id] } }),
        [PENDING_ACTION.FOLLOW_AUTHOR]: ({ id }) => ({
          payload: { Ids: [id] }
        }),
        [PENDING_ACTION.SAVE_SEARCH_HISTORY]: ({
          source,
          searchPhrase,
          categorySlug,
          entityRecordId
        }) => {
          const urlBySource = {
            [SEARCH_HISTORY_SOURCE.FMDIR_SEARCH_RESULT_PAGE]: `${FM_DIR_ROUTE.SEARCH_RESULTS}/?searchPhrase=${searchPhrase}`,
            [SEARCH_HISTORY_SOURCE.FMDIR_CATEGORY_PAGE]: `${categorySlug}/?searchPhrase=${searchPhrase}`
          }

          return {
            payload: {
              Source: source,
              SearchPhrase: searchPhrase,
              URL: urlBySource[source],
              EntityRecordId: entityRecordId
            }
          }
        },
        [PENDING_ACTION.FOLLOW_COMPANY]: ({ id }) => ({
          payload: { Ids: [id] }
        })
      },
      /**
       * Note that refresh payload should be an object as we extend it with
       * the field "isSuccess" that represents if the handler was called without
       * errors
       */
      generateRefreshPayloadByActionType: {
        [PENDING_ACTION.SAVE_ARTICLE]: ({ id, newValue }) => ({
          id,
          value: newValue
        }),
        [PENDING_ACTION.FOLLOW_TAG]: ({ id, newValue }) => ({
          id,
          value: newValue
        }),
        [PENDING_ACTION.FOLLOW_AUTHOR]: ({ id, newValue }) => ({
          id,
          value: newValue
        })
      }
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
      isAuthStatusRequested: 'auth/isAuthStatusRequested',
      isAuthStatusRequestInProgress: 'auth/isAuthStatusRequestInProgress'
    })
  },
  methods: {
    ...mapActionsLazy({
      requestSaveForLater: 'personal-area/requestSaveForLater',
      requestDeleteFmSavedArticle: 'personal-area/requestDeleteFmSavedArticle',
      requestFollowFmTags: 'personal-area/requestFollowFmTags',
      requestUnfollowFmTags: 'personal-area/requestUnfollowFmTags',
      requestFollowFmAuthors: 'personal-area/requestFollowFmAuthors',
      requestUnfollowFmAuthors: 'personal-area/requestUnfollowFmAuthors',
      requestFollowFmCompanies: 'personal-area/requestFollowFmCompanies',
      requestSaveSearchHistory: 'personal-area/requestSaveSearchHistory',
      concurrentRouterPush: 'router/concurrentRouterPush'
    })
  }
}
