import { getTopStickyElement } from '@/plugins/helper'

function getHeaderData() {
  const headerRectData = getTopStickyElement().getBoundingClientRect()

  return {
    rect: headerRectData
  }
}

export function setHeaderDataToState({ store }) {
  const { rect } = getHeaderData()

  store.dispatch('setHeaderBottomRectValue', rect.bottom)
}
