export const FORM_ID = {
  NEWSLETTER_EVENTS: 'newsletter-events-form',
  JOIN_EVENTS_WIDGET: 'join-events-widget-form',
  JOIN_EVENTS: 'join-events-form',
  BUY_HERE: 'buy-here-form',
  CUSTOM_MADE_REPORT: 'custom-made-report-form',
  BECOME_AN_AUTHOR: 'become-an-author-form',
  CONTACT_US: 'contact-us-form',
  CONTACT_US_WIDGET: 'contact-us-widget-form',
  NEWSLETTER_MODAL: 'newsletter-modal-form',
  NEWSLETTER_WIDGET: 'newsletter-widget-form',
  NEWSLETTER_HOMEPAGE: 'newsletter-homepage-form',
  NEWSLETTER_ARTICLE: 'newsletter-article-form',
  FORGOT_PASSWORD: 'forgot-password-form',
  RESET_PASSWORD: 'reset-password-form',
  SEND_ME_AN_OFFER: 'send-me-an-offer-form',
  SUGGEST_A_TERM: 'suggest-a-term-form',
  SIGN_IN_EMAIL: 'sign-in-email-form',
  SIGN_UP_EMAIL: 'sign-up-email-form',
  EBOOK: 'ebook-form',
  NEVER_MISS_INSIDERS_REPORT: 'never-miss-insiders-form'
}
