<template>
  <div class="layout-banners__wrapper" :style="wrapperInlineStyle">
    <div
      class="layout-banners__first-banner-wrapper"
      :style="firstBannerWrapperDynamicStyles"
    >
      <a-sticky class="layout-banners__banner__wrapper first">
        <a-banner :banner-settings="bannerSettings.RIGHT_SIDE_1" />
      </a-sticky>
    </div>

    <div
      class="layout-banners__second-banner-wrapper"
      :style="secondBannerWrapperDynamicStyles"
    >
      <a-sticky class="layout-banners__banner__wrapper second">
        <a-banner :banner-settings="bannerSettings.RIGHT_SIDE_2" />
      </a-sticky>
    </div>
  </div>
</template>

<script>
import ABanner from 'shared/ABanner/index.vue'
import { BANNER_SETTINGS_BY_ROUTE_NAME } from 'enums/banners/banner-settings-by-route-name'
import { PROP_TYPES, propValidator } from '@/utils/validators'
import ASticky from 'shared/ASticky/index.vue'

const DEFAULT_FIRST_BANNER_CONTAINER_HEIGHT = 700
const GAP_BETWEEN_BANNERS = 50

export default {
  name: 'ALayoutBannersSection',
  components: { ASticky, ABanner },
  props: {
    layoutTopPadding: propValidator(
      [PROP_TYPES.STRING, PROP_TYPES.NUMBER],
      false
    ),
    firstBannerContainerHeight: propValidator(
      [PROP_TYPES.NUMBER],
      false,
      DEFAULT_FIRST_BANNER_CONTAINER_HEIGHT
    ),
    secondBannerContainerHeight: propValidator([PROP_TYPES.NUMBER], false)
  },
  computed: {
    wrapperInlineStyle() {
      if (this.layoutTopPadding == null) return {}

      return {
        paddingTop: `${this.layoutTopPadding}px`
      }
    },
    bannerSettings() {
      return BANNER_SETTINGS_BY_ROUTE_NAME[this.$route.name] || {}
    },
    firstBannerWrapperDynamicStyles() {
      if (!this.firstBannerContainerHeight) return null

      return {
        height: `${this.firstBannerContainerHeight}px`
      }
    },
    secondBannerWrapperDynamicStyles() {
      if (this.secondBannerContainerHeight) {
        return { height: `${this.secondBannerContainerHeight}px` }
      }

      const firstBannerWithGap =
        this.firstBannerContainerHeight + GAP_BETWEEN_BANNERS

      return { height: `calc(100% - ${firstBannerWithGap}px)` }
    }
  }
}
</script>

<style lang="scss" scoped>
$banners-gap: 50px;

.layout-banners__wrapper {
  height: 100%;
  padding-top: 180px;

  @include tablet {
    display: none;
  }

  .layout-banners__first-banner-wrapper {
    position: relative;
  }

  .layout-banners__second-banner-wrapper {
    position: relative;
  }

  .layout-banners__banner__wrapper {
    width: $banner-width-xl;
    height: $side-banner-height;

    @include desktop-sm {
      width: $banner-width-large;
    }

    &.first {
      top: 120px;
    }

    &.second {
      margin-top: $banners-gap;
      top: 120px;
    }
  }
}
</style>
