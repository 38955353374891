<template>
  <fade-transition :duration="150">
    <div
      v-if="!inProgress"
      :class="$options.consts.WIDGETS_WRAPPER_CLASS"
      :key="currentWidget"
      @blur.capture="onWidgetsBlur"
    >
      <component
        :display-widget-buttons="displayWidgetButtons"
        :is="currentWidget"
      />
      <a-scroll-to-top />
    </div>
  </fade-transition>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import * as types from '@/store/mutation-types'
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { hydrateWhenVisible } from '@/utils/helpers/vue-lazy-hydration/LazyHydrate'
import { WIDGETS_WRAPPER_CLASS } from '@/components/_layout/ALayoutWidgets/enums'
import { hasParentWith } from '@fmpedia/helpers'
import { CLOSE_LAYOUT_WIDGETS_BUS_NAME } from 'shared/AContactWidget/enums'

const WIDGETS = {
  CONTACT_WIDGET: 'AContactWidget',
  EVENTS_WIDGET: 'AJoinEventsWidget'
}

export default {
  name: 'ALayoutWidgets',
  props: {
    displayWidgetButtons: propValidator([PROP_TYPES.BOOLEAN], false, true)
  },
  consts: {
    WIDGETS_WRAPPER_CLASS
  },
  components: {
    AContactWidget: () => import('shared/AContactWidget'),
    AJoinEventsWidget: () =>
      import('@/components/_sections/events/AJoinEventsWidget'),
    AScrollToTop: hydrateWhenVisible(() => import('shared/AScrollToTop'))
  },
  data() {
    return {
      inProgress: true
    }
  },
  computed: {
    isEventPage() {
      return this.$route.name === 'events'
    },
    currentWidget() {
      return this.isEventPage ? WIDGETS.EVENTS_WIDGET : WIDGETS.CONTACT_WIDGET
    }
  },
  methods: {
    ...mapActions({
      requestFormsSettings: 'leads/requestFormsSettings'
    }),
    ...mapMutations({
      setStickyWidgetsAsNotPending: types.SET_STICKY_WIDGETS_AS_NOT_PENDING
    }),
    onWidgetsBlur(event) {
      const { relatedTarget } = event

      if (this.$helper.isRecaptchaChallenge(relatedTarget)) return

      if (
        relatedTarget &&
        !hasParentWith(relatedTarget, {
          className: WIDGETS_WRAPPER_CLASS
        })
      ) {
        this.$bus.$emit(CLOSE_LAYOUT_WIDGETS_BUS_NAME)
      }
    },
    async getWidgetSettings() {
      try {
        await this.requestFormsSettings()
      } catch (err) {
        this.$errorHandler(err, this)
      } finally {
        this.inProgress = false
        this.setStickyWidgetsAsNotPending()
      }
    }
  },
  mounted() {
    this.getWidgetSettings()
  }
}
</script>

<style lang="scss" scoped>
.widgets-wrapper {
  position: absolute;
  top: 0;
  left: calc(100% - #{$desktop-full-hd-container-padding-right});
  width: 54px;
  height: 100%;
  z-index: $z-index-widget-wrapper;
  pointer-events: none;
  padding-left: 5px;

  @include desktop-md {
    /**
     * for the breakpoint of 1200-1399px it was approved by the client that the distance from right sky banner to the
     * sticky widgets can be less than 50px, but not less than 30px:
     * https://adraba.atlassian.net/browse/FMP-6308?focusedCommentId=36855&oldIssueView=true&page=com.atlassian.jira.plugin.system.issuetabpanels%3Acomment-tabpanel#comment-36855
     * In our case, for this range, the distance is going to be 35px.
    */
    padding-left: 20px;
    left: calc(100% - #{$desktop-full-hd-container-padding-right});
  }

  @include desktop-sm {
    position: fixed;
    right: -100%;
  }
}
</style>
