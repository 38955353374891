<template>
  <div class="cross-domain-auth">
    <a-cross-domain-iframe
      v-for="site in sitesToAuthenticate"
      :key="site"
      :site="site"
      :settings="cookieSettings"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { SET_AUTH_COOKIE_PATH } from '~/config/serverMiddleware'
import { QUERY_PARAM } from '@/server/api/cross-domain-settings'
import ACrossDomainIframe from './ACrossDomainIframe'
import * as types from '@/store/mutation-types'

export default {
  name: 'ACrossDomainCookies',
  components: { ACrossDomainIframe },
  data() {
    return {
      // TODO comment this when approved on QA and before production (client requirement)
      sitesToAuthenticate: [
        this.$env.DOMAIN_URL,
        this.$env.FM_DIR_DOMAIN_URL,
        this.$env.FL_DOMAIN_URL
      ],
      authQueryString: null
    }
  },
  computed: {
    ...mapGetters({
      accessToken: 'auth/accessToken',
      refreshToken: 'auth/refreshToken',
      rtlm: 'auth/rtlm'
    }),
    /**
     * Every settings is used to process single task for sync queries between sites
     * api - server middleware api
     * queryString - will be watched by ACrossDomainIFrame component
     * ToDo: add "sites" prop to run cookies sync only for requested sites
     */
    cookieSettings() {
      return [
        {
          api: SET_AUTH_COOKIE_PATH,
          queryString: this.authQueryString
        }
      ]
    },
    tokens() {
      return {
        accessToken: this.accessToken,
        refreshToken: this.refreshToken
      }
    }
  },
  watch: {
    tokens: {
      deep: true,
      handler(newVal, oldVal) {
        // /** Case when access token is expired and refreshed refreshed **/
        console.log('----------------------------------------')
        console.log('oldVal.accessToken', oldVal.accessToken)
        console.log('newVal.accessToken', newVal.accessToken)
        console.log('oldVal.refreshToken', oldVal.refreshToken)
        console.log('newVal.refreshToken', newVal.refreshToken)
        console.log('----------------------------------------')
        if (
          (oldVal.accessToken && newVal.accessToken) ||
          (!oldVal.accessToken &&
            newVal.accessToken &&
            !oldVal.refreshToken &&
            !newVal.refreshToken)
        )
          return

        console.log('COOKIE IFRAME')

        const accessTokenQuery = `${QUERY_PARAM.ACCESS_TOKEN}=${
          this.accessToken ? this.accessToken.getValue() : ''
        }`
        const refreshTokenQuery = `${QUERY_PARAM.REFRESH_TOKEN}=${this
          .refreshToken || ''}`
        const refreshTokenLastModifiedQuery = `${
          QUERY_PARAM.REFRESH_TOKEN_LAST_MODIFIED
        }=${this.rtlm || ''}`

        /**
         * This string will be watched by ACrossDomainIFrame component.
         * On change - iframe will open with src to our server middleware api
         * @returns {string}
         */
        this.authQueryString = `?${accessTokenQuery}&${refreshTokenQuery}&${refreshTokenLastModifiedQuery}`
        this.removeRefreshToken()
      }
    }
  },
  methods: {
    ...mapMutations({
      removeRefreshToken: `auth/${types.REMOVE_REFRESH_TOKEN}`
    })
  }
}
</script>
