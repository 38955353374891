import { mapGetters, mapActions } from 'vuex'
import { equals } from 'ramda'

import { SOURCE } from 'enums/source'
import {
  SignalR,
  REQUIRED_ACTION,
  REQUIRED_ACTION_BY_DYNAMIC_STATE
} from 'enums/signalr'

export default {
  data() {
    return {
      hubConnection: null,
      hubConnectionImportPromise: null,
      signalR_signalRSettings: [
        {
          hostPath: this.$env.API_URL,
          hubKey: 'FmBellIconNotifications',
          hubPath: '/hubs/bell-icon-notifications',
          source: SOURCE.FM,
          listeners: [
            {
              name: 'CountInfo',
              handler: this.handleNewFmMessage
            }
          ],
          startupInvokes: [
            {
              name: 'Init',
              payloadFn: () => this.$helper.getTokenValue(this.accessToken)
            }
          ],
          shouldHubBeConnected: () => this.isLoggedIn,
          onDisconnect: this.clearNewFmMessages,
          onError: this.handleError
        },
        {
          hostPath: this.$env.FL_API_URL,
          hubKey: 'FlBellIconNotifications',
          hubPath: '/hubs/bell-icon-notifications',
          source: SOURCE.FL,
          listeners: [
            {
              name: 'CountInfo',
              handler: this.handleNewFlMessage
            }
          ],
          startupInvokes: [
            {
              name: 'Init',
              payloadFn: () => this.$helper.getTokenValue(this.accessToken)
            }
          ],
          shouldHubBeConnected: () => this.isLoggedIn,
          onDisconnect: this.clearNewFlMessages,
          onError: this.handleError
        }
      ],
      signalR_messageCount: {
        [SOURCE.FM]: 0,
        [SOURCE.FL]: 0
      },
      signalR_hubConnections: {}
    }
  },
  computed: {
    ...mapGetters({
      accessToken: 'auth/accessToken',
      isLoggedIn: 'auth/isLoggedIn',
      isOnline: 'isOnline',
      isBrowserTabActiveAndFocused: 'isBrowserTabActiveAndFocused'
    }),
    $_signalr_dynamicStateToWatch() {
      if (!process.client) return

      return this.signalR_signalRSettings.reduce((acc, hubSettings) => {
        const isSignalrInitialized = !!window.signalR
        const { hostPath, hubKey } = hubSettings

        const isHubConnected =
          !!this.signalR_hubConnections[hubKey] &&
          this.signalR_hubConnections[hubKey].isConnected
        const isConnectionRequired = hubSettings.shouldHubBeConnected()

        const hubData = {
          hostPath,
          hubKey,
          hubSettings,
          isSignalrInitialized,
          isHubConnected,
          isConnectionRequired,
          isOnline: this.isOnline,
          isBrowserTabActiveAndFocused: this.isBrowserTabActiveAndFocused
        }

        return [...acc, hubData]
      }, [])
    },
    totalNewMessageCount() {
      return (
        this.signalR_messageCount[SOURCE.FM] +
        this.signalR_messageCount[SOURCE.FL]
      )
    }
  },
  watch: {
    $_signalr_dynamicStateToWatch: {
      immediate: true,
      deep: true,
      handler(newVal, oldVal) {
        /**
         * We check if newVal and oldVal are different because we found the cases
         * when they are equal. Such cases might cause double connect issue and
         * errors during the second connection.
         */
        if (!process.client || equals(newVal, oldVal)) return

        newVal.forEach(newHubSettings => {
          const { hubSettings, hubKey } = newHubSettings

          const requiredAction = this.getRequiredActionBySettings(
            newHubSettings
          )
          const isConnectRequired = [
            REQUIRED_ACTION.CONNECT,
            REQUIRED_ACTION.RECONNECT
          ].includes(requiredAction)

          const isHubAlreadyExist = !!this.signalR_hubConnections[hubKey]

          /** Create new SignalR instance **/
          if (!isHubAlreadyExist && isConnectRequired) {
            this.signalR_hubConnections[hubKey] = new SignalR({
              settings: hubSettings
            })
            this.startInitialNotificationCountRequest({
              args: { source: hubSettings.source }
            })
            return
          }

          /** Use existing Signalr instance **/
          switch (requiredAction) {
            case REQUIRED_ACTION.CONNECT:
              this.signalR_hubConnections[hubKey].connect()
              this.startInitialNotificationCountRequest({
                args: { source: hubSettings.source }
              })
              break
            case REQUIRED_ACTION.DISCONNECT:
              this.signalR_hubConnections[hubKey].disconnect()
              break
            case REQUIRED_ACTION.PAUSE_ON_TIMEOUT:
              this.signalR_hubConnections[hubKey].startPauseTimeout()
              break
            case REQUIRED_ACTION.CANCEL_PAUSE_TIMEOUT:
              this.signalR_hubConnections[hubKey].cancelPauseTimeout()
              break
            case REQUIRED_ACTION.RECONNECT:
              this.signalR_hubConnections[hubKey].reconnect()
          }
        })
      }
    }
  },
  methods: {
    ...mapActions({
      requestSetNewNotificationCount:
        'notifications/requestSetNewNotificationCount',
      startInitialNotificationCountRequest:
        'notifications/startInitialNotificationCountRequest',
      completeInitialNotificationCountRequest:
        'notifications/completeInitialNotificationCountRequest'
    }),
    getRequiredActionBySettings(settings) {
      const {
        isSignalrInitialized,
        isConnectionRequired,
        isHubConnected,
        isOnline,
        isBrowserTabActiveAndFocused
      } = settings

      const boolResult = this.$helper.getBinaryStringFromBooleanArray([
        isSignalrInitialized,
        isOnline && isBrowserTabActiveAndFocused,
        isHubConnected,
        isConnectionRequired
      ])

      return (
        REQUIRED_ACTION_BY_DYNAMIC_STATE[boolResult] || REQUIRED_ACTION.NOTHING
      )
    },
    async handleNewFmMessage(message) {
      try {
        this.signalR_messageCount[SOURCE.FM] = this.getNewMessageCount(message)
        await this.requestSetNewNotificationCount(this.totalNewMessageCount)
        await this.completeInitialNotificationCountRequest({
          args: { source: SOURCE.FM }
        })
      } catch (err) {
        this.$errorHandler(err, this)
      }
    },
    async handleNewFlMessage(message) {
      try {
        this.signalR_messageCount[SOURCE.FL] = this.getNewMessageCount(message)
        await this.requestSetNewNotificationCount(this.totalNewMessageCount)
        await this.completeInitialNotificationCountRequest({
          args: { source: SOURCE.FL }
        })
      } catch (err) {
        this.$errorHandler(err, this)
      }
    },
    getNewMessageCount(message) {
      return message
        ? Object.values(message).reduce((acc, count) => acc + count, 0)
        : 0
    },
    clearNewFmMessages() {
      this.signalR_messageCount[SOURCE.FM] = 0
      this.requestSetNewNotificationCount(this.totalNewMessageCount)
    },
    clearNewFlMessages() {
      this.signalR_messageCount[SOURCE.FL] = 0
      this.requestSetNewNotificationCount(this.totalNewMessageCount)
    },
    handleError({ err, errorMessage }) {
      const error = err || new Error(errorMessage)
      this.$errorHandler(error, this, {
        showMessage: false,
        message: errorMessage
      })
    }
  },
  beforeDestroy() {
    Object.values(this.signalR_hubConnections).forEach(host => {
      host.disconnect()
    })
  }
}
