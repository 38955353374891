/**
 * Google Banners
 * @link https://developers.google.com/doubleclick-gpt/reference
 */
import { mapActions, mapGetters } from 'vuex'
import { sraBannerHandler } from 'enums/banners/sra-display-handler'
import { isConsentGivenForEntity } from '@/utils/mixins/one-trust'
import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { CURRENCY_MODULE_CONFIG } from 'enums/banners/header-bidding'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

const BANNERS_REFRESHING_INTERVAL = 180000
const REFRESH_BANNERS_QUOTA = 3

let refreshCountPerformed = 0
let refreshIntervalId = null

export const googleBanners = {
  mixins: [hydrationHelpers],
  data() {
    return {
      unwatchTabActiveAndFocused: () => {},
      unwatchWindowWidth: () => {},
      isGptReady: false,
      isPrebidReady: false
    }
  },
  computed: {
    ...mapGetters({
      activeBannerSlots: 'activeBannerSlots',
      isGptLoadStarted: 'isGptLoadStarted',
      isBrowserTabActiveAndFocused: 'isBrowserTabActiveAndFocused',
      isCurrentUserBot: 'isCurrentUserBot'
    })
  },
  methods: {
    ...mapActions({
      setIsEmptyStatusForBannerCampaign: 'setIsEmptyStatusForBannerCampaign',
      deleteIsEmptyStatusForBannerCampaign:
        'deleteIsEmptyStatusForBannerCampaign',
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    $_googleBanners_isRefreshCountQuotaReached() {
      return refreshCountPerformed >= REFRESH_BANNERS_QUOTA
    },
    $_googleBanners_refreshActiveBanners() {
      Object.keys(this.activeBannerSlots).forEach(slotId => {
        sraBannerHandler.registerRefreshBannerSlot(
          this.activeBannerSlots[slotId]
        )
      })
    },
    $_googleBanners_clearRefreshBannersInterval() {
      clearInterval(refreshIntervalId)
    },
    $_googleBanners_initializeBannerRefreshLoop() {
      if (
        this.$_hydrationHelpers_isLayoutMobile ||
        this.$_googleBanners_isRefreshCountQuotaReached()
      ) {
        return
      }

      refreshIntervalId = setInterval(() => {
        try {
          /**
           * In case if there is nothing to refresh on the current page,
           * we skip the iteration (examples: error page, terms of use etc)
           */
          if (!Object.keys(this.activeBannerSlots).length) return

          this.$_googleBanners_refreshActiveBanners()
          refreshCountPerformed++

          if (this.$_googleBanners_isRefreshCountQuotaReached()) {
            this.$_googleBanners_removeWatchers()
            this.$_googleBanners_clearRefreshBannersInterval()
          }
        } catch (err) {
          this.$_googleBanners_clearRefreshBannersInterval()
          this.$errorHandler(err, this)
        }
      }, BANNERS_REFRESHING_INTERVAL)
    },
    $_googleBanners_enqueueHandlers() {
      const googletag = window.googletag || {}
      googletag.cmd = googletag.cmd || []
      const self = this

      googletag.cmd.push(function() {
        googletag.pubads().disableInitialLoad()
        googletag.pubads().enableSingleRequest()
        googletag.enableServices()
        self.$_googleBanners_addEventListeners(googletag)
        self.isGptReady = true
        self.$_googleBanners_setBannersApiAsReady()
      })

      const pbjs = window.pbjs || {}
      pbjs.que = pbjs.que || []

      pbjs.que.push(function() {
        pbjs.setConfig(CURRENCY_MODULE_CONFIG)
        self.isPrebidReady = true
        self.$_googleBanners_setBannersApiAsReady()
      })
    },
    $_googleBanners_init() {
      if (!isConsentGivenForEntity(COOKIE_ENTITY_TYPE.DFP_BANNER)) return

      this.$helper.pollUntil({
        fn: this.$_googleBanners_enqueueHandlers,
        condition: () =>
          !!window.googletag?.apiReady && !!window.pbjs?.libLoaded,
        interval: 50
      })
    },
    $_googleBanners_setBannersApiAsReady() {
      if (!this.isGptReady || !this.isPrebidReady) return

      this.$store.dispatch('setBannersInitStatus')
    },
    $_googleBanners_addEventListeners(googletag) {
      const self = this

      googletag.pubads().addEventListener('slotRenderEnded', function(event) {
        const slotId = event.slot.getSlotElementId()

        self.setIsEmptyStatusForBannerCampaign({
          id: slotId,
          status: event.isEmpty
        })
      })
    },
    $_googleBanners_removeWatchers() {
      this.unwatchTabActiveAndFocused()
      this.unwatchWindowWidth()
    },
    $_googleBanners_registerWatchers() {
      /**
       * Prevent banners' refresh when current tab is inactive
       */
      this.unwatchTabActiveAndFocused = this.$watch(
        'isBrowserTabActiveAndFocused',
        function(isTabActive) {
          if (this.isCurrentUserBot) return

          if (isTabActive) {
            this.$_googleBanners_initializeBannerRefreshLoop()
          } else {
            this.$_googleBanners_clearRefreshBannersInterval()
          }
        }
      )

      /**
       * Whenever window width changes, we need to reset the refresh banner loop
       */
      this.unwatchWindowWidth = this.$watch(
        '$_hydrationHelpers_windowWidth',
        function(newVal, oldVal) {
          if (
            !oldVal ||
            this.isCurrentUserBot ||
            !this.isBrowserTabActiveAndFocused
          ) {
            return
          }

          this.$_googleBanners_clearRefreshBannersInterval()

          /**
           * Preventing banners' refresh for mobile devices. When resolution is
           * switched from mobile to desktop, refresh should resume
           */
          if (this.$_hydrationHelpers_isLayoutMobile) return

          this.$_googleBanners_initializeBannerRefreshLoop()
        }
      )
    }
  },
  beforeMount() {
    this.$_googleBanners_registerWatchers()
  },
  mounted() {
    if (this.isCurrentUserBot || this.isGptLoadStarted) return

    this.$store.dispatch('setGptLoadStartedStatus')

    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: () => {
          this.$_googleBanners_init()
          this.$_googleBanners_initializeBannerRefreshLoop()
        },
        entityType: COOKIE_ENTITY_TYPE.DFP_BANNER
      })
    )
  },
  beforeDestroy() {
    this.$_googleBanners_removeWatchers()
    this.$_googleBanners_clearRefreshBannersInterval()
  }
}
