import api from '~/utils/api'

export const state = () => ({
  formsSettings: {}
})

export const getters = {}

export const actions = {
  async requestBecomeAContributor(_, { payload, headerParams }) {
    try {
      const settings = {
        headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
      }
      await this.$flAxios.post(
        api.leads.becomeAContributor(),
        payload,
        settings
      )
    } catch (e) {
      throw e
    }
  },
  async requestBecomeAContributorFileUpload(_, payload) {
    try {
      const { data } = await this.$flAxios.post(
        api.leads.becomeAContributorFileUpload(),
        payload
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestExpertise() {
    try {
      const {
        data: { Expertises }
      } = await this.$flAxios.get(api.layout.getBecomeAContributerExpertises())
      return Expertises
    } catch (e) {
      throw e
    }
  }
}

export const mutations = {}
