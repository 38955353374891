export const ARTICLE_SLOT_MODEL = {
  GENERAL: {
    Id: '',
    Topic: '',
    Slug: '',
    Tldr: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsPinned: false,
    IsSaved: false,
    IsMigrated: false,
    IsDisclaimerVisible: false,
    Category: {
      Id: '',
      Name: '',
      Slug: '',
      ParentId: '',
      ParentName: '',
      ParentSlug: ''
    },
    Sticker: null,
    Author: {
      Id: '',
      Name: '',
      Slug: '',
      Status: '',
      Role: ''
    },
    SponsorshipCompany: null,
    MainTerm: null,
    MainTag: {
      Id: '',
      Name: '',
      Slug: '',
      IsFollowed: false
    }
  },
  RELATED: {
    Id: '',
    Topic: '',
    Slug: '',
    PublishedOn: '',
    Category: {
      Name: '',
      Slug: ''
    },
    ParentCategory: {
      Name: '',
      Slug: ''
    },
    FeaturedImage: null,
    FeaturedVideo: null
  },
  HOMEPAGE: {
    Tldr: '',
    Sticker: null,
    Tags: [
      {
        Slug: '',
        Name: '',
        IsMain: true
      }
    ],
    Slug: '',
    Topic: '',
    PublishedOn: '',
    Category: {
      Name: '',
      Slug: ''
    },
    ParentCategory: null,
    FeaturedImage: null,
    FeaturedVideo: null
  },
  SAVED_FOR_LATER_FM_ARTICLE: {
    Id: '',
    Topic: '',
    Slug: '',
    Tldr: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsPinned: false,
    IsSaved: false,
    IsMigrated: false,
    IsDisclaimerVisible: false,
    Category: {
      Id: '',
      Name: '',
      Slug: '',
      ParentId: null,
      ParentName: null,
      ParentSlug: null
    },
    Sticker: null,
    Author: {
      Id: '',
      Name: '',
      Slug: '',
      Status: '',
      Role: ''
    },
    SponsorshipCompany: null,
    MainTerm: null,
    MainTag: {
      Id: '',
      Name: '',
      Slug: '',
      IsFollowed: false
    }
  },
  SAVED_FOR_LATER_FM_DIR_ARTICLE: {
    Id: '',
    Slug: '',
    Topic: '',
    FmArticleUrl: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsSaved: false,
    Company: {
      Id: '',
      Name: '',
      Slug: '',
      LogoUrl: '',
      IsFollowed: false,
      Category: {
        Id: '',
        Name: '',
        Slug: ''
      }
    }
  },
  SAVED_FOR_LATER_FL_ARTICLE: {
    Id: '',
    Title: '',
    ExpandedContent: '',
    Slug: '',
    Tldr: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsPinned: false,
    IsSessionWrap: false,
    IsMigrated: false,
    Category: {
      Name: '',
      Slug: ''
    },
    Author: {
      Id: '',
      Name: '',
      Slug: '',
      PhotoUrl: ''
    },
    SponsorshipCompany: null,
    ThumbnailImage: null,
    FeaturedImage: null,
    FeaturedVideo: null,
    Terms: []
  },
  NOTIFICATION: {
    Id: '',
    Article: null,
    DirectoryArticle: null,
    ForexLiveArticle: null,
    Authors: [],
    Tags: [],
    Categories: [],
    Companies: []
  },
  NOTIFICATION_FM_ARTICLE: {
    Id: '',
    Topic: '',
    Slug: '',
    Tldr: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsPinned: false,
    IsSaved: false,
    IsMigrated: false,
    IsDisclaimerVisible: false,
    Category: {
      Id: '',
      Name: '',
      Slug: '',
      ParentId: null,
      ParentName: null,
      ParentSlug: null
    },
    Sticker: null,
    Author: {
      Id: '',
      Name: '',
      Slug: '',
      Status: '',
      Role: ''
    },
    SponsorshipCompany: null,
    MainTerm: null,
    MainTag: {
      Id: '',
      Name: '',
      Slug: '',
      IsFollowed: false
    }
  },
  NOTIFICATION_FM_DIR_ARTICLE: {
    Id: '',
    Slug: '',
    Topic: '',
    FmArticleUrl: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsSaved: false,
    Company: {
      Id: '',
      Name: '',
      Slug: '',
      LogoUrl: '',
      IsFollowed: false,
      Category: {
        Id: '',
        Name: '',
        Slug: ''
      }
    }
  },
  NOTIFICATION_FL_ARTICLE: {
    Id: '',
    Title: '',
    ExpandedContent: '',
    Slug: '',
    Tldr: '',
    PublishedOn: '',
    ModifiedOn: '',
    IsPinned: false,
    IsSessionWrap: false,
    IsMigrated: false,
    Category: {
      Name: '',
      Slug: ''
    },
    Author: {
      Id: '',
      Name: '',
      Slug: '',
      Status: '',
      Role: ''
    },
    SponsorshipCompany: null,
    ThumbnailImage: null,
    FeaturedImage: null,
    FeaturedVideo: null
  }
}
