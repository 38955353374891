import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'
import { urlFormatters } from '@/utils/mixins/general'
import { ERROR_CODE, ERROR_CODE_V2 } from 'enums/errorCodes'
import { COMPONENT_TYPE } from 'enums/component-type'

export const state = () => ({})

const TARGET_AXIOS_INSTANCE_BY_COMPONENT_TYPE = {
  [COMPONENT_TYPE.FM]: ctx => ctx.$axiosV2,
  [COMPONENT_TYPE.FM_DIR]: ctx => ctx.$axiosV2,
  [COMPONENT_TYPE.FL]: ctx => ctx.$flAxios,
  [COMPONENT_TYPE.FL_DIR]: ctx => ctx.$flAxios
}

const TARGET_REQUEST_VIDEO_API_ROUTE_BY_COMPONENT_TYPE = {
  [COMPONENT_TYPE.FM]: apiV2.videos.getVideo,
  [COMPONENT_TYPE.FM_DIR]: apiV2.videos.getVideo,
  [COMPONENT_TYPE.FL]: api.videos.getVideo,
  [COMPONENT_TYPE.FL_DIR]: api.videos.getVideo
}

const TARGET_UPLOAD_VIDEO_API_ROUTE_BY_COMPONENT_TYPE = {
  [COMPONENT_TYPE.FM]: apiV2.videos.uploadVideo,
  [COMPONENT_TYPE.FM_DIR]: apiV2.videos.uploadVideo,
  [COMPONENT_TYPE.FL]: api.videos.uploadVideo,
  [COMPONENT_TYPE.FL_DIR]: api.videos.uploadVideo
}

export const actions = {
  async requestUploadVideoByUrl(
    { dispatch },
    { args: { Url, ComponentType } }
  ) {
    const ExternalId = urlFormatters.methods.getYoutubeVideoId(Url)
    const EmbedUrl = urlFormatters.methods.formatIdToEmbedUrl(ExternalId)
    const targetAxiosInstance = TARGET_AXIOS_INSTANCE_BY_COMPONENT_TYPE[
      ComponentType
    ](this)

    if (!ExternalId) {
      throw new Error('Invalid Youtube Video URL')
    }

    try {
      const queryParams = {
        ExternalId,
        ComponentType
      }
      const foundVideo = await dispatch('requestVideo', { queryParams })

      if (foundVideo) {
        return {
          ...foundVideo,
          EmbedUrl
        }
      }
    } catch (err) {
      const errorCode = this.$helper.getBackendErrorCode(err)

      /**
       * Create video if it was not found
       */
      if (
        [ERROR_CODE_V2.VIDEO_NOT_FOUND, ERROR_CODE.VIDEO_NOT_FOUND].includes(
          errorCode
        )
      ) {
        try {
          const uploadVideoApiRouteFn =
            TARGET_UPLOAD_VIDEO_API_ROUTE_BY_COMPONENT_TYPE[ComponentType]
          const payload = {
            ExternalId,
            ComponentType
          }
          const { data } = await targetAxiosInstance.post(
            uploadVideoApiRouteFn(),
            payload
          )

          return {
            ...data,
            EmbedUrl
          }
        } catch (err) {
          throw err
        }
      }

      throw err
    }
  },
  async requestVideo(_, { queryParams }) {
    try {
      const targetAxiosInstance = TARGET_AXIOS_INSTANCE_BY_COMPONENT_TYPE[
        queryParams.ComponentType
      ](this)

      const getVideoApiRouteFn =
        TARGET_REQUEST_VIDEO_API_ROUTE_BY_COMPONENT_TYPE[
          queryParams.ComponentType
        ]

      const { data } = await targetAxiosInstance.get(getVideoApiRouteFn(), {
        params: queryParams
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestYoutubeVideoTitleByUrl(_, { Url }) {
    try {
      const ExternalId = urlFormatters.methods.getYoutubeVideoId(Url)

      const { data } = await this.$axios.get(
        api.videoHosting.getYoutubeVideoData(ExternalId)
      )

      return data.Title
    } catch (err) {
      throw err
    }
  }
}
