import { REFS } from 'enums/external-refs'
import { mapActions, mapGetters } from 'vuex'
import { SHOW_ONE_SIGNAL_ICON_BUS_NAME } from 'enums/oneSignal'

import { COOKIE_ENTITY_TYPE } from 'enums/oneTrust'
import { createOneTrustHandlerInstance } from 'enums/oneTrust/one-trust-handler'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { KEY_CODE } from '@fmpedia/enums'

const ONE_SIGNAL_BELL_CONTAINER_ID = 'onesignal-bell-container'
const ONE_SIGNAL_BELL_LAUNCHER_ID = 'onesignal-bell-launcher'
const ONE_SIGNAL_BELL_LAUNCHER_BUTTON_CLASS = 'onesignal-bell-launcher-button'

/**
 * At first, this variable was located in "data", but when navigating between
 * layouts (default -> error or vice versa), the mixin got destroyed and so was
 * the saved container. To fix it we moved this variable to the outer scope
 */
let oneSignalContainerSaved = null

function executeClickHandlerOnEnter(event, el) {
  // Check if the Enter key was pressed
  if (event.key === 'Enter' || event.keyCode === KEY_CODE.ENTER) {
    // Prevent the default action to avoid any unwanted behavior
    event.preventDefault()

    // Programmatically trigger a click event on the element
    el.click()
  }
}

function makeElementFocusable(el) {
  if (!el) return

  el.setAttribute('tabIndex', '0')

  el.addEventListener('keydown', event => executeClickHandlerOnEnter(event, el))
}

/**
 * OneSignal
 * @link https://app.onesignal.com/apps/dd12f78c-b146-46c4-ad30-bf7b3eb12193/settings
 */
export const oneSignal = {
  mixins: [hydrationHelpers],
  computed: {
    ...mapGetters({
      isPreviewMode: 'isPreviewMode'
    })
  },
  watch: {
    $_hydrationHelpers_windowWidth() {
      /**
       * Since the header has different layouts depending on current CSS breakpoint,
       * the DOM with the injected OneSignal markup gets destroyed. So, we need to
       * restore it in such cases
       */
      requestAnimationFrame(this.$_oneSignal_relocateOneSignalToHeader)
    }
  },
  methods: {
    ...mapActions({
      registerHandlerThatRequiresConsent:
        'one-trust/registerHandlerThatRequiresConsent'
    }),
    $_oneSignal_getNewOneSignalContainer() {
      return this.$_hydrationHelpers_isLayoutMobile
        ? document.querySelector(`[data-ref="${REFS.ONE_SIGNAL_MOBILE}"]`)
        : document.querySelector(`[data-ref="${REFS.ONE_SIGNAL_DESKTOP}"]`)
    },
    async $_oneSignal_substituteOneSignalIcon(newContainer, oldContainer) {
      const iconToSubstituteOneSignal = newContainer.firstChild

      const isSubscribed = await window.OneSignal.getSubscription()
      this.$bus.$emit(SHOW_ONE_SIGNAL_ICON_BUS_NAME, isSubscribed)

      oldContainer
        .querySelector(`#${ONE_SIGNAL_BELL_LAUNCHER_ID}`)
        .appendChild(iconToSubstituteOneSignal)
    },
    async $_oneSignal_relocateOneSignalToHeader() {
      const oneSignalContainer =
        oneSignalContainerSaved ||
        document.getElementById(ONE_SIGNAL_BELL_CONTAINER_ID)

      const newOneSignalContainer = this.$_oneSignal_getNewOneSignalContainer()

      if (!oneSignalContainer || !newOneSignalContainer) return

      const isOneSignalAlreadyInContainer = !!newOneSignalContainer.querySelector(
        `#${ONE_SIGNAL_BELL_CONTAINER_ID}`
      )

      if (isOneSignalAlreadyInContainer) return

      if (!oneSignalContainerSaved) {
        oneSignalContainerSaved = oneSignalContainer

        makeElementFocusable(
          oneSignalContainer.querySelector(
            `.${ONE_SIGNAL_BELL_LAUNCHER_BUTTON_CLASS}`
          )
        )
      }

      await this.$_oneSignal_substituteOneSignalIcon(
        newOneSignalContainer,
        oneSignalContainer
      )

      newOneSignalContainer.appendChild(oneSignalContainer)
    },
    $_oneSignal_addOneSignalToHead() {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.async = true
      script.onload = this.$_oneSignal_openOneSignal
      script.src = 'https://cdn.onesignal.com/sdks/OneSignalSDK.js'
      this.$helper.insertScriptToHead(script)
    },
    $_oneSignal_openOneSignal() {
      const oneSignal = window.OneSignal || {}
      const appId = this.$env.ONE_SIGNAL_ID
      const ctx = this

      /**
       * For dev environment you should use ngrok for forwarding your localhost
       * to external https address (add this address to OneSignal configuration)
       * @type {string}
       */
      oneSignal.push(function() {
        oneSignal.init({
          appId
        })

        oneSignal.on('subscriptionChange', isSubscribed => {
          ctx.$bus.$emit(SHOW_ONE_SIGNAL_ICON_BUS_NAME, isSubscribed)
        })

        /**
         * Since there are no explicit API methods/events for defining the completion
         * of the OneSignal initialization, this method is used for such a purpose.
         * We also need to make sure the OneSignal's container is rendered before trying
         * to relocate it. If cache is disabled in the browser, the time when this container
         * is ready is arbitrary, that's why we use polling.
         */
        oneSignal.getTags().then(() =>
          ctx.$helper.pollUntil({
            fn: ctx.$_oneSignal_relocateOneSignalToHeader,
            condition: () =>
              !!document.getElementById(ONE_SIGNAL_BELL_CONTAINER_ID)
          })
        )
      })
    },
    $_oneSignal_init() {
      if (!window.OneSignal) {
        this.$_oneSignal_addOneSignalToHead()
      } else {
        requestAnimationFrame(this.$_oneSignal_relocateOneSignalToHeader)
      }
    }
  },
  mounted() {
    if (this.isPreviewMode) return

    this.registerHandlerThatRequiresConsent(
      createOneTrustHandlerInstance({
        handler: this.$_oneSignal_init,
        entityType: COOKIE_ENTITY_TYPE.ONE_SIGNAL
      })
    )
  }
}
