export const MIDDLEWARE = {
  CONTRIBUTOR_WITHOUT_DETAILS: 'contributor-without-details',
  PAGE_SEO: 'page-seo',
  MIGRATED_REDIRECTS: 'migration-redirects',
  PATH_REPLACE: 'path-replace',
  PREVENT_ROUTING: 'prevent-routing',
  ROUTER_NAVIGATION_DETAILS: 'router-navigation-details',
  SEO_REDIRECT: 'seo-redirect',
  REDIRECT_410: '410-redirect',
  PREVIEW_NAVIGATION: 'preview-navigation',
  CONTENT_REFRESH_IN_BACKGROUND: 'content-refresh-in-background',
  ASYNC_MIDDLEWARES: 'async-middlewares',
  REMOVE_LOCAL_ONE_TRUST_HANDLERS: 'remove-local-one-trust-handlers',
  CONFIRM_ROUTE_CHANGE: 'confirm-route-change'
}
