// import * as types from './mutation-types'
// import api from '~/utils/api'
// import { config } from '~/config/general-config'
import Cookies from 'js-cookie'
import { pathOr } from 'ramda'

import * as types from './mutation-types'
import api from '~/utils/api'
import apiV2 from '~/utils/apiV2'
import {
  encodeToBase64,
  decodeFromBase64,
  forceUrlFetch,
  saveValueInClosure
} from '@/plugins/helper'
import { USER_TYPE, CONTRIBUTOR_TYPES } from 'enums/users'
import { HTTP_STATUS_CODE } from 'enums/errorCodes'
import { AUTH_PROVIDER, CLEAN_USER_DETAILS } from 'enums/auth'

export const generateAuthApiUrl = (ctx, path) => {
  return `${ctx.$env.DOMAIN_URL}/v2/${path}`
}

// const accessToken =
//   'eyJJZCI6IjBiZGM5NzJmLTZhODAtNDZkMy1hMDVkLTAzMTQyNDEyYjcyZiIsIkZpcnN0TmFtZSI6IlBhdCIsIkxhc3ROYW1lIjoiQmFycmV0dCIsIkVtYWlsIjoiQmFyckBtYWlsLmNvbSIsIlR5cGUiOjEsIkV4cGlyZVRpbWUiOiIyMDE5LTA2LTA4VDE2OjM2OjEyLjE1NDI4NzQrMDM6MDAiLCJIYXNoIjoibmlnbjRNMjFQWDVabHJKMUlSdDFLdlhsL3VmeEZKeVdUY3dOM3VoSHVGYz0ifQ=='

export const state = () => ({
  accessToken: null,
  refreshToken: null,
  rtlm: null, // RefreshTokenLastModified
  isAuthStatusRequestInProgress: false,
  isAuthStatusRequested: false,
  socials: {
    facebook: AUTH_PROVIDER.FACEBOOK,
    google: AUTH_PROVIDER.GOOGLE,
    twitter: AUTH_PROVIDER.TWITTER
  },
  isLoginBanned: false,
  banExpirationTime: null,
  userDetails: {
    ...CLEAN_USER_DETAILS
  },
  wasOnboardingFlowShownDuringSession: false,
  isAuthFormLoadingInProgress: false,
  isAuthFormLoaded: false
})

export const getters = {
  accessToken: state => state.accessToken,
  refreshToken: state => state.refreshToken,
  rtlm: state => state.rtlm,
  isAuthStatusRequestInProgress: state => state.isAuthStatusRequestInProgress,
  isAuthStatusRequested: state => state.isAuthStatusRequested,
  isLoggedIn: (state, _, __, rootGetters) =>
    !!state.accessToken && !rootGetters.isPreviewMode,
  socials: state => state.socials,
  isUserLoginBanned: state => state.isLoginBanned,
  banExpirationTime: state => new Date(state.banExpirationTime),
  userDetails: state => state.userDetails,
  cleanUserDetails: state => CLEAN_USER_DETAILS,
  userType: state => state.userDetails.Type,
  userFullName: state =>
    `${state.userDetails.FirstName || ''}${
      state.userDetails.FirstName ? ' ' : ''
    }${state.userDetails.LastName || ''}`,
  userInitials: (state, getters) => {
    const { userFullName } = getters
    if (!userFullName) return

    const userNameParts = getters.userFullName.split(' ')
    if (userNameParts.length < 2) return null

    return userNameParts
      .slice(0, 2)
      .map(p => p[0])
      .join('')
  },
  wasOnboardingFlowShownDuringSession: state =>
    state.wasOnboardingFlowShownDuringSession,
  isAuthFormLoadingInProgress: state => state.isAuthFormLoadingInProgress,
  isAuthFormLoaded: state => state.isAuthFormLoaded,
  isSponsoredContributor: (state, getters) =>
    [
      USER_TYPE.FM_SPONSORED_CONTRIBUTOR,
      USER_TYPE.FL_SPONSORED_CONTRIBUTOR
    ].includes(getters.userType),
  isRegularContributor: (state, getters) =>
    [USER_TYPE.FM_CONTRIBUTOR, USER_TYPE.FL_CONTRIBUTOR].includes(
      getters.userType
    ),
  isContributor: (state, getters) =>
    CONTRIBUTOR_TYPES.includes(getters.userType),
  isSubscriber: (state, getters) => getters.userType === USER_TYPE.SUBSCRIBER,
  isContributorWithoutDetails: state => {
    const { Type, Company, FirstName, LastName, Job } = state.userDetails

    if ([USER_TYPE.FM_CONTRIBUTOR, USER_TYPE.FL_CONTRIBUTOR].includes(Type)) {
      return state.accessToken && (!FirstName || !LastName || !Job)
    } else if (
      [
        USER_TYPE.FM_SPONSORED_CONTRIBUTOR,
        USER_TYPE.FL_SPONSORED_CONTRIBUTOR
      ].includes(Type)
    ) {
      return state.accessToken && (!FirstName || !LastName || !Company)
    }

    return false
  }
}

export const actions = {
  checkAndUpdateLockoutExpiration({ state, commit }) {
    if (!process.client || !localStorage) return

    const timestamp = decodeFromBase64(localStorage.getItem('bet')) // bet = ban expiration time

    if (!timestamp) {
      commit(types.SET_LOGIN_BAN_DETAILS, {})
      return
    }

    const now = new Date()
    const isLoginBanned = new Date(timestamp) > now
    commit(types.SET_LOGIN_BAN_DETAILS, {
      isLoginBanned,
      timestamp
    })
  },
  setUserLockoutStatus({ commit, dispatch }, error = null) {
    if (!error) {
      commit(types.SET_LOGIN_BAN_DETAILS, {})
      return
    }

    const status = +pathOr('', ['response', 'status'], error)
    // ToDo: Keep only 401 code when backend is fixed
    if (
      ![HTTP_STATUS_CODE.BAD_REQUEST, HTTP_STATUS_CODE.UNAUTHORIZED].includes(
        status
      )
    ) {
      throw error
    }

    let errorMessage = ''
    let responseObject = {}
    try {
      responseObject = JSON.parse(
        pathOr('{}', ['response', 'data', 'Message'], error)
      )
    } catch (err) {
      throw error
    }

    errorMessage = pathOr('', ['ErrorMessage'], responseObject)
    const expirationTime = pathOr('', ['ExpirationTime'], responseObject)
    const serverTime = pathOr('', ['ServerTime'], responseObject)

    const syncTime = new Date() - new Date(serverTime)
    const timestamp = new Date(
      +new Date(`${expirationTime}+0000`) + syncTime
    ).toISOString()

    const payload = {
      isLoginBanned: true,
      timestamp
    }
    commit(types.SET_LOGIN_BAN_DETAILS, payload)
    throw Error(errorMessage)
  },
  async requestSignInWithEmail(
    { commit, dispatch, state },
    { headerParams, payload }
  ) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
    }

    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.loginWithEmail()),
        payload,
        settings
      )
      commit(types.SET_AUTH_TOKENS, data)
      dispatch('setUserLockoutStatus')
    } catch (error) {
      dispatch('setUserLockoutStatus', error)
    }
  },
  async requestAuthStatus({ commit }) {
    try {
      commit(types.SET_IS_AUTH_STATUS_REQUEST_IN_PROGRESS, true)
      const { data } = await this.$axios.get(
        generateAuthApiUrl(this, apiV2.users.getAuthStatus())
      )
      console.log('requestAuthStatus data', data)

      commit(types.SET_AUTH_TOKENS, data)
      commit(types.SET_IS_AUTH_STATUS_REQUESTED)
    } catch (err) {
      throw err
    } finally {
      commit(types.SET_IS_AUTH_STATUS_REQUEST_IN_PROGRESS, false)
    }
  },
  async deauthenticateUser({ commit }) {
    commit(types.LOG_OUT_USER)
  },
  async requestConfirmEmailSignUp({ dispatch, commit, getters }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.confirmationOfRegistration()),
        payload
      )

      if (getters.isLoggedIn) {
        await dispatch('deauthenticateUser')
      }

      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestCurrentUserDetails({ commit }) {
    try {
      const { data } = await this.$axios.get(
        forceUrlFetch(api.users.getCurrentUserDetails())
      )
      commit(types.SET_USER_DETAILS, data)
    } catch (err) {
      throw err
    }
  },
  async requestResetPassword(_, { headerParams, payload }) {
    const headers = { GoogleCaptchaResponseKey: headerParams.captchaKey }

    try {
      await this.$axiosV2.post(apiV2.me.resetPassword(), payload, { headers })
    } catch (err) {
      throw err
    }
  },
  async requestConfirmPassword(
    { commit, getters, dispatch },
    { headerParams, payload }
  ) {
    const headers = { GoogleCaptchaResponseKey: headerParams.captchaKey }

    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.me.confirmPassword()),
        payload,
        {
          headers
        }
      )

      if (getters.isLoggedIn) {
        await dispatch('deauthenticateUser')
      }

      commit(types.SET_AUTH_TOKENS, data)
      return data
    } catch (err) {
      throw err
    }
  },
  async requestChangePassword(_, { payload }) {
    try {
      await this.$axiosV2.patch(apiV2.me.changePassword(), payload)
    } catch (err) {
      throw err
    }
  },
  async refreshAccessToken({ commit }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.refreshAccessToken())
      )

      commit(types.SET_AUTH_TOKENS, { at: data })
    } catch (err) {
      throw err
    }
  },
  async becomeAContributor(_, params) {
    try {
      await this.$axios.post(api.users.becomeAContributor())
    } catch (err) {
      throw err
    }
  },
  async requestSignUpWithEmail(_, { payload, headerParams }) {
    const settings = {
      headers: { GoogleCaptchaResponseKey: headerParams.captchaKey }
    }

    try {
      const { data } = await this.$axiosV2.post(
        apiV2.users.registrationWithEmail(),
        payload,
        settings
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestSignUpWithFacebook({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.registrationWithFacebook()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestSignInWithFacebook({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.loginWithFacebook()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestSignUpWithGoogle({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.registrationWithGoogle()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestSignInWithGoogle({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.loginWithGoogle()),

        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestTwitterAuthUrl(_, { payload }) {
    try {
      const {
        data: { OAuthUrl }
      } = await this.$axiosV2.post(apiV2.socials.twitterAuthUrl(), payload)

      return OAuthUrl
    } catch (err) {
      throw err
    }
  },
  async requestTwitterAccessToken(_, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        apiV2.socials.twitterRequestAccessToken(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestTwitterUserDetails(_, { pathParams }) {
    try {
      const { data } = await this.$axiosV2.get(
        apiV2.socials.twitterGetUserDetails(),
        {
          params: pathParams
        }
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestSignInWithTwitter({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.loginWithTwitter()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestSignUpWithTwitter({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.users.registrationWithTwitter()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestDeactivateUserProfile() {
    try {
      await this.$axiosV2.patch(apiV2.me.deactivateUserProfile())
    } catch (err) {
      throw err
    }
  },
  async requestReactivateUserProfile(
    { commit, dispatch, getters },
    { payload }
  ) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.me.reactivateUserProfile()),
        payload
      )

      if (getters.isLoggedIn) {
        await dispatch('deauthenticateUser')
      }

      commit(types.SET_AUTH_TOKENS, data)
      return data
    } catch (err) {
      throw err
    }
  },
  async requestReactivateFacebookUserProfile({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.me.reactivateFacebookUserProfile()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestReactivateGoogleUserProfile({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.me.reactivateGoogleUserProfile()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestReactivateTwitterUserProfile({ commit }, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        generateAuthApiUrl(this, apiV2.me.reactivateTwitterUserProfile()),
        payload
      )
      commit(types.SET_AUTH_TOKENS, data)
    } catch (err) {
      throw err
    }
  },
  async requestUserProfileReactivation(_, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        apiV2.me.requestUserProfileReactivation(),
        payload
      )
      return data
    } catch (err) {
      throw err
    }
  },
  async requestFmDirCompanyState(_, params) {
    try {
      const { data } = await this.$axios.get(api.users.getCompanyState(), {
        params
      })

      return data
    } catch (err) {
      throw err
    }
  },
  async requestFlDirCompanyState(_, params) {
    try {
      const { data } = await this.$flAxios.get(api.users.getCompanyState(), {
        params
      })

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_AUTH_TOKENS](state, { at = null, rt = null, rtlm = null }) {
    console.log('AccessToken', at)
    // if (process.client) {
    //   setTimeout(() => {
    //     state.accessToken = saveValueInClosure(at + '323')
    //   }, 10000)
    // }
    const oldAccessToken = state.accessToken
    state.accessToken = at ? saveValueInClosure(at) : null

    /** Case when access token is expired and refreshed refreshed **/
    if (oldAccessToken && at) return

    state.refreshToken = rt
    state.rtlm = rtlm
    if (rtlm) {
      console.log('Cookie SET', state.rtlm)
      Cookies.set('rtlm', state.rtlm)
    }

    // }
  },
  [types.LOG_OUT_USER](state) {
    state.accessToken = null
    state.refreshToken = null
    state.rtlm = null
    Cookies.remove('rtlm', state.rtlm)
  },
  [types.REMOVE_REFRESH_TOKEN](state) {
    console.log('REMOVE_REFRESH_TOKEN')
    state.refreshToken = null
  },
  [types.SET_IS_AUTH_STATUS_REQUEST_IN_PROGRESS](state, status) {
    state.isAuthStatusRequestInProgress = status
  },
  [types.SET_IS_AUTH_STATUS_REQUESTED](state) {
    state.isAuthStatusRequested = true
  },
  [types.SET_LOGIN_BAN_DETAILS](
    state,
    { isLoginBanned = false, timestamp = null }
  ) {
    state.isLoginBanned = isLoginBanned
    state.banExpirationTime = timestamp
    localStorage.setItem('bet', encodeToBase64(timestamp)) // bet = ban expiration time
  },
  [types.SET_USER_DETAILS](state, userDetails) {
    state.userDetails = {
      ...state.userDetails,
      ...userDetails
    }
  },
  [types.CLEAR_USER_DETAILS](state) {
    state.userDetails = { ...CLEAN_USER_DETAILS }
  },
  [types.UPDATE_WAS_ONBOARDING_FLOW_SHOWN_VALUE](state, value) {
    state.wasOnboardingFlowShownDuringSession = value
  },
  [types.SET_AUTH_FORM_LOADING_IN_PROGRESS](state) {
    state.isAuthFormLoadingInProgress = true
  },
  [types.SET_AUTH_FORM_LOADED](state) {
    state.isAuthFormLoadingInProgress = false
    state.isAuthFormLoaded = true
  }
}
