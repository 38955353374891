import { ROUTE_NAME } from 'enums/routes'
import { SPECIAL_CATEGORY } from 'enums/special-categories'
import { SEARCH_FILTER } from 'enums/search-resluts'

export const TARGETING_KEY = {
  DEFAULT: 'catName',
  TERM: 'term'
}

export const TESTING_TARGETING_VALUE = 'test-banners'

export const SEARCH_RESULTS_GENERAL_TARGETING_VALUE = 'search'
export const SEARCH_RESULTS_SINGLE_DATA_TARGETING_VALUE =
  'Search_Results_Single_Data'

export const PARENT_TARGETING_VALUE_BY_CATEGORY = {
  [SPECIAL_CATEGORY.THOUGHT_LEADERSHIP]: 'Thought_Leadership'
}

export const SUBCATEGORY_ARTICLE_TARGETING_VALUE_BY_CATEGORY = {
  [SPECIAL_CATEGORY.THOUGHT_LEADERSHIP]: 'TL_archive'
}

const PERSONAL_AREA_TARGETING_VALUE = Object.entries(ROUTE_NAME)
  .filter(([key]) => key.match(/^PERSONAL_PROFILE/))
  .reduce(
    (acc, [key, value]) => ({
      ...acc,
      [value]: [
        { key: TARGETING_KEY.DEFAULT, valueHandler: () => 'Personal_area' }
      ]
    }),
    {}
  )

const termSlugTargetingValueHandler = ctx => {
  const termSlug = ctx.$route.params.slug
  return ctx.$helper.capitalize(termSlug.toLowerCase())
}

const categoryTargetingValueHandler = ctx => {
  const categorySlug = ctx.$route.params.category.toLowerCase()
  return PARENT_TARGETING_VALUE_BY_CATEGORY[categorySlug] || categorySlug
}

const subcategoryArticleTargetingValueHandler = ctx => {
  const categorySlug = ctx.$route.params.category.toLowerCase()

  return (
    SUBCATEGORY_ARTICLE_TARGETING_VALUE_BY_CATEGORY[categorySlug] ||
    `${categorySlug}_archive`
  )
}

export const TARGETING_SETTINGS_BY_PAGE_NAME = {
  [ROUTE_NAME.HOMEPAGE]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: ctx =>
        ctx.$_hydrationHelpers_isLayoutMobile ? 'HP_mobile' : 'HP'
    }
  ],
  [ROUTE_NAME.ALL_NEWS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'all-news'
    }
  ],
  [ROUTE_NAME.ABOUT_US]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'About'
    }
  ],
  [ROUTE_NAME.TERMS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Terms_HP'
    }
  ],
  [ROUTE_NAME.TERMS_LETTER]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Terms_Single_Letter'
    }
  ],
  [ROUTE_NAME.TERMS_SEARCH_RESULTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Terms_Search'
    }
  ],
  [ROUTE_NAME.AUTHOR_SLUG]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Author_Page'
    }
  ],
  [ROUTE_NAME.TAG_SLUG]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Tag_Page'
    }
  ],
  [ROUTE_NAME.TAG_SEARCH_RESULTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Tags_Search'
    }
  ],
  [ROUTE_NAME.EVENTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Events'
    }
  ],
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS_ANNUAL]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'intelligence'
    }
  ],
  [ROUTE_NAME.INTELLIGENCE]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'intelligence'
    }
  ],
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'intelligence'
    }
  ],
  [ROUTE_NAME.INTELLIGENCE_PRODUCTS_QIR_SLUG]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'intelligence'
    }
  ],
  [ROUTE_NAME.CONTACT_US]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Contact_Us'
    }
  ],
  [ROUTE_NAME.BECOME_AN_AUTHOR]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: () => 'Become_an_author'
    }
  ],
  [ROUTE_NAME.SEARCH_RESULTS]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: ctx => {
        const searchFilterValues = Object.values(SEARCH_FILTER)

        if (searchFilterValues.includes(ctx.$route.query.filter)) {
          return SEARCH_RESULTS_SINGLE_DATA_TARGETING_VALUE
        }

        return SEARCH_RESULTS_GENERAL_TARGETING_VALUE
      }
    }
  ],
  [ROUTE_NAME.TERMS_LETTER_SLUG]: [
    {
      key: TARGETING_KEY.TERM,
      valueHandler: termSlugTargetingValueHandler
    }
  ],
  [ROUTE_NAME.CATEGORY]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: categoryTargetingValueHandler
    }
  ],
  [ROUTE_NAME.CATEGORY_SUBCATEGORY]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: subcategoryArticleTargetingValueHandler
    }
  ],
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: subcategoryArticleTargetingValueHandler
    }
  ],
  [ROUTE_NAME.CATEGORY_SUBCATEGORY_ARTICLE]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: subcategoryArticleTargetingValueHandler
    }
  ],
  [ROUTE_NAME.CATEGORY_EDUCATION_CENTER_ARTICLE]: [
    {
      key: TARGETING_KEY.DEFAULT,
      valueHandler: subcategoryArticleTargetingValueHandler
    }
  ],
  ...PERSONAL_AREA_TARGETING_VALUE
}
