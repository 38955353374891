<template>
  <div class="gdpr_container">
    <!--
      Please, read this issue to understand why <template> tag is required here:
      https://github.com/vuejs/vue/issues/11299
     -->
    <template v-if="hideCheckbox">
      <span v-html="sanitizedGdprHtml" class="gdpr__text" />
    </template>

    <a-checkbox
      v-else
      class="gdpr__checkbox"
      :checkbox-style="checkboxStyle"
      :value="value"
      :label="gdprWithDefault"
      :disabled="disabled"
      :error="error"
      label-as-html
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { propValidator, PROP_TYPES } from '@/utils/validators'
import { parseAndSanitizeHtml } from '@/utils/helpers/processHtml/parseAndSanitizeHtml'

export { CHECKBOX_STYLE } from 'shared/ACheckbox'

export default {
  name: 'AGdpr',
  props: {
    gdpr: propValidator([PROP_TYPES.STRING], false),
    hideCheckbox: propValidator([PROP_TYPES.BOOLEAN], false, false),
    error: propValidator([PROP_TYPES.OBJECT], false, () => ({})),
    value: propValidator([PROP_TYPES.BOOLEAN], false, false),
    disabled: propValidator([PROP_TYPES.BOOLEAN], false, false),
    checkboxStyle: propValidator([PROP_TYPES.STRING], false)
  },
  computed: {
    sanitizedGdprHtml() {
      return parseAndSanitizeHtml(this.gdprWithDefault)
    },
    gdprWithDefault() {
      return (
        this.gdpr ||
        `By submitting I agree to Finance Magnates’ <a href="${this.$env.DOMAIN_URL}/terms-of-use/" target="_blank">Terms</a>, <a href="${this.$env.DOMAIN_URL}/privacy/#cookie-section" target="_blank">Cookies and Privacy Notice.</a>`
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.gdpr_container {
  .gdpr__checkbox {
    width: 100%;
    max-height: inherit;
    color: inherit;
  }

  .gdpr__text {
    font-size: 14px;
    line-height: 1.4;

    /deep/ a {
      text-decoration: underline;
    }

    @include mobile {
      font-size: 12px;
    }
  }
}
</style>
