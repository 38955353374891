import * as types from './mutation-types'

const state = () => ({
  confirmRequiredItems: [],
  isRouteChangeRequested: false,
  routerChangeRequestPromise: null,
  routerChangeRequestResolver: null
})

const getters = {
  isRouteChangeAllowed: state => state.confirmRequiredItems.length === 0,
  isRouteChangeRequested: state => state.isRouteChangeRequested,
  confirmRequiredItems: state => state.confirmRequiredItems
}

const actions = {
  disableRouteChange({ commit }, item) {
    commit(types.ADD_ITEM_THAT_REQUIRES_CONFIRM, item)
  },
  enableRouteChangeById({ commit }, id) {
    commit(types.REMOVE_ITEM_THAT_REQUIRES_CONFIRM, id)
  },
  async getIdsWithRequiredConfirm({ getters }) {
    const items = getters.confirmRequiredItems

    const results = await Promise.all(
      items.map(async item => {
        const isConfirmRequired = item.isConfirmRequiredFn
          ? await item.isConfirmRequiredFn()
          : true

        return { ...item, isConfirmRequired }
      })
    )

    return results
      .filter(({ isConfirmRequired }) => isConfirmRequired)
      .map(item => item.id)
  },
  requestRouteChange({ commit }) {
    commit(types.SET_IS_ROUTE_CHANGE_REQUESTED, true)
  },
  cancelRouteChange({ commit }) {
    commit(types.SET_IS_ROUTE_CHANGE_REQUESTED, false)
  },
  saveRouteChangeRequestPromise({ commit }, promise) {
    commit(types.SET_CHANGE_ROUTE_REQUEST_PROMISE, promise)
  },
  saveRouteChangeRequestResolver({ commit }, resolver) {
    commit(types.SET_CHANGE_ROUTE_REQUEST_RESOLVER, resolver)
  },
  submitRouteChange({ state, dispatch }) {
    if (!state.routerChangeRequestResolver) return

    state.routerChangeRequestResolver(true)
    dispatch('cancelRouteChange')
    dispatch('saveRouteChangeRequestResolver', null)
  },
  denyRouteChange({ dispatch, state }) {
    if (!state.routerChangeRequestResolver) return

    state.routerChangeRequestResolver(false)
    dispatch('cancelRouteChange')
    dispatch('saveRouteChangeRequestResolver', null)
  },
  async concurrentRouterPush(
    { dispatch, state },
    { args: { router, to, force = false, replace = false } }
  ) {
    if (force) {
      dispatch('saveRouteChangeRequestPromise', null)
      dispatch('saveRouteChangeRequestResolver', null)
    }

    if (state.routerChangeRequestPromise) {
      await state.routerChangeRequestPromise
    }

    const promise = new Promise(resolve => {
      dispatch('saveRouteChangeRequestResolver', resolve)
    })
    dispatch('saveRouteChangeRequestPromise', promise)
    dispatch('requestRouteChange')
    const callback = () => {
      dispatch('cancelRouteChange')
      if (
        state.routerChangeRequestPromise &&
        state.routerChangeRequestResolver
      ) {
        state.routerChangeRequestResolver()
        dispatch('saveRouteChangeRequestPromise', null)
        dispatch('saveRouteChangeRequestResolver', null)
      }
    }

    if (replace) {
      router.replace(to, callback, callback)
    } else {
      router.push(to, callback, callback)
    }
  }
}

const mutations = {
  [types.ADD_ITEM_THAT_REQUIRES_CONFIRM](state, item) {
    if (!state.confirmRequiredItems.map(({ id }) => id).includes(item.id)) {
      state.confirmRequiredItems = [...state.confirmRequiredItems, item]
    }
  },
  [types.REMOVE_ITEM_THAT_REQUIRES_CONFIRM](state, id) {
    if (state.confirmRequiredItems.map(({ id }) => id).includes(id)) {
      state.confirmRequiredItems = state.confirmRequiredItems.filter(
        ({ id: existingId }) => existingId !== id
      )
    }
  },
  [types.SET_IS_ROUTE_CHANGE_REQUESTED](state, data) {
    state.isRouteChangeRequested = data
  },
  [types.SET_CHANGE_ROUTE_REQUEST_PROMISE](state, data) {
    state.routerChangeRequestPromise = data
  },
  [types.SET_CHANGE_ROUTE_REQUEST_RESOLVER](state, data) {
    state.routerChangeRequestResolver = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
