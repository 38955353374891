import Vue from 'vue'

function maxLength(text, textLength) {
  const length = textLength > 3 ? textLength : 75
  if (!text) return ''

  if (text.length > length) {
    const visibleText = text.slice(0, textLength)

    const endOfSentencePositions = getEndOfSentencePositions(visibleText)
    const spacePositions = getSpacePositions(visibleText)

    const lastDotIndex = endOfSentencePositions
      ? Math.max(...endOfSentencePositions)
      : 0
    const lastSpaceIndex = spacePositions ? Math.max(...spacePositions) : 0

    const lastChar = lastDotIndex || lastSpaceIndex
    return lastChar ? text.slice(0, lastChar + 1) : `${visibleText}...`
  }

  return text
}

Vue.filter('maxLength', maxLength)

const END_OF_SENTENCE_REGEXP = new RegExp(/(\.([^\d]|$))|[?!]/, 'gmi')
const SPACE_REGEXP = new RegExp(/\s/, 'gmi')

function getEndOfSentencePositions(string) {
  if (!string) return null
  const positions = []
  const match = string.replace(
    END_OF_SENTENCE_REGEXP,
    (str, group1, group2, offset) => {
      positions.push(offset)
      return str
    }
  )

  if (!match || !positions.length) return null

  return positions
}

function getSpacePositions(string) {
  if (!string) return null

  const positions = []
  const match = string.replace(SPACE_REGEXP, (str, offset) => {
    positions.push(offset)
    return str
  })

  if (!match || !positions.length) return null

  return positions
}
