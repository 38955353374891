export const ICON = {
  ENLARGE: 'enlarge',
  BELL: 'bell',
  ARROW_LEFT_BLUE: 'arrow-left-blue',
  ARROW_RIGHT_BLUE: 'arrow-right-blue',
  UPLOADED_ROUND_GREEN: 'uploaded-round-green',
  UPLOAD_ROUND_BLACK: 'upload-round-black',
  CAMERA: 'camera',
  CATEGORY: 'category',
  FOLDER: 'folder',
  LABEL: 'label',
  LABEL_ROUND_BLACK: 'label-round-black',
  CHECK_MARK_ROUND_BLACK: 'check-mark-round-black',
  SENT_LETTER: 'sent-letter',
  SENT_LETTER_2: 'sent-letter-2',
  ENVELOPE: 'envelope',
  ENVELOPE_2: 'envelope-2',
  ENVELOPE_3: 'envelope-3',
  ENVELOPE_ROUND_BLACK: 'envelope-round-black',
  ENVELOPE_ROUND_GRAY: 'envelope-round-gray',
  ENVELOPE_RECT_BLUE: 'envelope-rect-blue',
  NEWSLETTER_RECT_BLUE: 'newsletter-rect-blue',
  TWITTER: 'twitter',
  TWITTER_ROUND_BLACK: 'twitter-round-black',
  TWITTER_ROUND_BLUE: 'twitter-icon-color',
  LINKEDIN: 'linkedin',
  LINKEDIN_ROUND_BLACK: 'linkedin-round-black',
  LINKEDIN_ROUND_BLUE: 'linkedin-round-blue',
  TELEGRAM: 'telegram',
  TELEGRAM_ROUND_BLACK: 'telegram-round-black',
  TELEGRAM_ROUND_BLUE: 'telegram-round-blue',
  TELEGRAM_ROUND_WHITE: 'telegram-round-white',
  FACEBOOK: 'facebook',
  FACEBOOK_ROUND_BLACK: 'facebook-round-black',
  FACEBOOK_ROUND_BLUE: 'facebook-round-blue',
  YOUTUBE: 'youtube',
  YOUTUBE_ROUND_BLACK: 'youtube-round-black',
  WHATSUP_ROUND_GREEN: 'whatsapp-round-green',
  SHARE: 'share',
  SOCIAL_SQUARE_X: 'social-square-x',
  SOCIAL_SQUARE_LINKEDIN: 'social-square-linked-in',
  SOCIAL_SQUARE_EMAIL: 'social-square-email',
  SOCIAL_SQUARE_TELEGRAM: 'social-square-telegram',
  SOCIAL_SQUARE_FACEBOOK: 'social-square-facebook',
  SOCIAL_SQUARE_WHATSAPP: 'social-square-whatsapp',
  SOCIAL_SQUARE_REDDIT: 'social-square-reddit',
  SOCIAL_SQUARE_LEARN_TERM: 'social-square-document',
  SOCIAL_SQUARE_FOLLOW_TAG: 'social-square-follow-tag',
  SOCIAL_SQUARE_SAVE: 'social-square-save',
  LOCK: 'lock',
  THUMB_UP: 'thumb-up',
  SAVE_ROUND_BLACK: 'save-round-black',
  STAR: 'star',
  WALKING_FINGERS_ROUND_BLACK: 'walking-fingers-round-black',
  GOOGLE: 'google',
  PENCIL: 'pencil',
  PENCIL_ROUND_BLACK: 'pencil-round-black',
  PRODUCTS_EMPTY: 'products-empty',
  SEARCH: 'search',
  MAGNIFIER: 'magnifier',
  MAGNIFIER_2: 'magnifier-2',
  HEADER_MAGNIFIER: 'header-magnifier',
  MEGAPHONE_ACTIVE: 'megaphone-active',
  MEGAPHONE_INACTIVE: 'megaphone-inactive',
  MAGNIFIER_STARRED: 'magnifier-starred',
  FLAME: 'flame',
  PIN: 'pin',
  FM: 'fm',
  FM_MOBILE: 'fm-mobile',
  FL: 'fl',
  FL_DARK: 'forex-live-logo-dark',
  FM_DIR: 'fmd-logo',
  QUESTION: 'question',
  GEAR_ROUND_BLACK: 'gear-round-black',
  BOOK_ROUND_BLACK: 'book-round-black',
  HANDSHAKE: 'handshake',
  REGISTRATION_COMPLETE: 'registration-complete',
  RSS: 'rss',
  LINK_ROUND_BLACK: 'link-round-black',
  PLUS: 'plus',
  BULL: 'bull',
  BULL_2: 'bull-2',
  DOUBLE_ARROW: 'double-arrow',
  ADD_POST_ROUND_BLACK: 'add-post-round-black',
  ADD_VIDEO_ROUND_BLACK: 'add-video-round-black',
  TRASH_ROUND_BLACK: 'trash-round-black',
  COMMENT_COUNT_ROUND_BLACK: 'comments-count-round-black',
  REDDIT_ROUND_ORANGE: 'reddit-round-orange',
  EYE: 'eye',
  BRIEFCASE_ROUND_BLUE: 'briefcase-round-blue',
  RENEW: 'renew',
  DONT_RENEW: 'dont-renew',
  RENEW_ROUND_BLACK: 'renew-round-black',
  FILE_STARRED: 'file-starred',
  FILE_ROUND_BLACK: 'file-round-black',
  WELCOME_BETTER_ACCOUNT: 'welcome-better-account',
  FINISH_BETTER_ACCOUNT: 'finish-better-account',
  ASSISTANT_TRANSPARENT: 'assistant-transparent',
  DIRECTORY_PROMOTION: 'directory-promotion-banner',
  PLAY: 'play',
  ASSISTANT_ROUND_BLUE: 'assistant-round-blue',
  BELL_ACTIVE: 'bell-active',
  BELL_INACTIVE: 'bell-inactive',
  REDO: 'redo',
  DOWNLOAD: 'download'
}
