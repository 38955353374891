import { getContext, getLinkToFmBucketFile, sliceText } from '@/plugins/helper'
import { ensureUTCForDateWithoutTimezone } from '@fmpedia/helpers'

export const PAGE_SCHEME_TYPE = {
  NEWS_ARTICLE: 'NewsArticle',
  ARTICLE: 'Article',
  WEB_SITE: 'WebSite',
  ORGANIZATION: 'Organization',
  PRODUCT: 'Product',
  SERVICE: 'Service',
  PROFESSIONAL_SERVICE: 'ProfessionalService',
  WEB_PAGE: 'WebPage',
  CONTACT_PAGE: 'ContactPage',
  EVENT: 'Event',
  PERSON: 'Person',
  VIDEO_GENERAL: 'VideoObjectGeneral'
}

const VIDEO_DESCRIPTION_LIMIT = 300
const SCHEME_TEXT_LIMIT = 1000

function getDomain() {
  return `${getContext().store.$env.DOMAIN_URL}/`
}

const LOGO_WIDTH = '263'
const LOGO_HEIGHT = '110'

function getLogoPath() {
  return getLinkToFmBucketFile('fm-logo-black.png')
}

function getCurrentYear() {
  return new Date().getFullYear()
}

function formatVideoDescription(description) {
  return sliceText({ text: description, limit: VIDEO_DESCRIPTION_LIMIT }).trim()
}

function formatText(text) {
  return sliceText({ text, limit: SCHEME_TEXT_LIMIT })
}

export const PAGE_SCHEME = {
  [PAGE_SCHEME_TYPE.WEB_SITE]: ({
    genre = 'financial news website',
    headline = 'Financial and Business News | Finance Magnates',
    isBasedOnUrl = getDomain(),
    text = 'Finance Magnates - the world’s only multi-asset online trading knowledge hub - offers financial news, industry research, international events and more',
    thumbnailUrl = getLogoPath(),
    description = 'Finance Magnates - the world’s only multi-asset online trading knowledge hub - offers financial news, industry research, international events and more'
  }) =>
    `"@type": "WebSite",
	   "genre": "${genre}",
	   "headline": "${headline}",
	   "isBasedOnUrl": "${isBasedOnUrl}",
	   "keywords": [
	   	"Finance news",
	   	"finance",
	   	"finance magnates"
	   ],
	   "text": "${formatText(text)}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${description}"`,
  [PAGE_SCHEME_TYPE.NEWS_ARTICLE]: ({
    dateline = '',
    articleBody = '',
    articleSection = '',
    wordCount = '',
    alternativeHeadline = '',
    copyrightYear = getCurrentYear(),
    datePublished = '',
    dateModified = '',
    genre = '',
    headline = '',
    isFamilyFriendly = 'true',
    text = '',
    thumbnailUrl = '',
    description = '',
    author = '',
    authorUrl = '',
    mainEntityOfPage = ''
  }) =>
    `"@type": "NewsArticle",
     "dateline": "${dateline}",
	   "articleBody": "${articleBody}",
	   "articleSection": "${articleSection}",
	   "wordCount": "${wordCount}",
	   "alternativeHeadline": "${alternativeHeadline}",
	   "copyrightYear": "${copyrightYear}",
	   "datePublished": "${datePublished}",
	   "dateModified": "${dateModified}",
	   "genre": "${genre}",
	   "headline": "${headline}",
	   "isFamilyFriendly": "${isFamilyFriendly}",
	   "text": "${formatText(text)}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${description}",
	   "author": {
	      "@type": "Person",
        "name": "${author}",
        "url": "${authorUrl}"
     },
	   "image": "${thumbnailUrl}",
	   "publisher": {
	      "@type": "Organization",
	      "name": "Finance Magnates",
	      "logo": {
	          "@type": "ImageObject",
            "url": "${getLogoPath()}",
            "width": "${LOGO_WIDTH}",
            "height": "${LOGO_HEIGHT}"
	      }
	   },
	   "mainEntityOfPage": "${mainEntityOfPage}"`,
  [PAGE_SCHEME_TYPE.ARTICLE]: ({
    articleBody = '',
    articleSection = '',
    wordCount = '',
    alternativeHeadline = '',
    copyrightYear = getCurrentYear(),
    datePublished = '',
    dateModified = '',
    discussionUrl = '',
    headline = '',
    keywords = '',
    thumbnailUrl = '',
    timeRequired = '',
    alternateName = '',
    description = '',
    author = '',
    authorUrl = '',
    mainEntityOfPage = ''
  }) =>
    `"@type": "Article",
	   "articleBody": "${articleBody}",
	   "articleSection": "${articleSection}",
	   "wordCount": "${wordCount}",
	   "alternativeHeadline": "${alternativeHeadline}",
	   "copyrightYear": "${copyrightYear}",
	   "datePublished": "${datePublished}",
	   "dateModified": "${dateModified}",
	   "discussionUrl": "${discussionUrl}",
	   "headline": "${headline}",
	   "keywords": "${keywords}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "timeRequired": "${timeRequired}",
	   "alternateName": "${alternateName}",
	   "description": "${description}",
	   "author": {
	      "@type": "Person",
        "name": "${author}",
        "url": "${authorUrl}"
     },
	   "image": "${thumbnailUrl}",
	   "publisher": {
	      "@type": "Organization",
	      "name": "Finance Magnates",
	      "logo": {
	          "@type": "ImageObject",
            "url": "${getLogoPath()}",
            "width": "${LOGO_WIDTH}",
            "height": "${LOGO_HEIGHT}"
	      }
	   },
	   "mainEntityOfPage": "${mainEntityOfPage}"`,
  /** DO NOT REMOVE - leave until implemented pages schemes approved by client **/
  // [PAGE_SCHEME_TYPE.NEWS_ARTICLE]: ({
  //   id,
  //   headline,
  //   imageUrl,
  //   datePublished,
  //   dateModified,
  //   authorName,
  //   description
  // }) =>
  //   `"@type": "WebPage",
  //    "@id": "${id}",
  //    "mainEntityOfPage": {
  //        "@type": "NewsArticle",
  //        "@id": "${id}"
  //     },
  //    "headline": "${headline}",
  //    "image": {
  //        "@type": "ImageObject",
  //        "url": "${imageUrl}",
  //        "height": 100,
  //        "width": 180
  //     },
  //    "datePublished": "${datePublished}",
  //    "dateModified": "${dateModified}",
  //    "author": {
  //        "@type": "Person",
  //        "name": "${authorName}"
  //     },
  //    "publisher": {
  //        "@type": "Organization",
  //        "name": "Finance Magnates",
  //        "logo": {
  //            "@type": "ImageObject",
  //            "url": "https://www.financemagnates.com/wp-content/uploads/sites/8/2016/02/financemagnates.png",
  //            "width": 263,
  //            "height": 110
  //        }
  //     },
  //    "description": "${description}"
  //   }`,
  [PAGE_SCHEME_TYPE.ORGANIZATION]: () =>
    `"@type": "Organization",
	   "brand": "${getDomain()}",
	   "founder": {
	   	"@type": "Person",
	   	"familyName": "Greenberg",
	   	"givenName": "Michael ",
	   	"jobTitle": "CEO"
	   },
	   "description": "Finance Magnates - the world’s only multi-asset online trading knowledge hub - offers financial news, industry research, international events and more",
	   "name": "Finance Magnates"`,
  [PAGE_SCHEME_TYPE.PRODUCT]: ({
    productID = 'Intelligence',
    alternateName = 'Intelligence Products',
    description = 'Our Quarterly Report incorporates unique data on trading volumes and traders, in-depth insights, special guest articles, regional information, regulations, updates and much more.',
    mainEntityOfPage = 'intelligence',
    brand = 'Finance Magnates',
    image = '',
    name = 'intelligence'
  }) =>
    `"@type": "Product",
     "productID": "${productID}",
     "alternateName": "${alternateName}",
     "description": "${description}",
     "mainEntityOfPage": "${mainEntityOfPage}",
     "brand": "${brand}",
     "image": "${image}",
     "name": "${name}"`,
  [PAGE_SCHEME_TYPE.SERVICE]: ({
    serviceType = 'Intelligence',
    alternateName = 'Quarterly Industry Report',
    description = 'Our Quarterly Report incorporates unique data on trading volumes and traders, in-depth insights, special guest articles, regional information, regulations, updates and much more.  The last issue of the report summarizes the trends of the second quarter of 2018 and their implications on multi-asset trading industry. It also features some new unique benchmarks, providing you with exclusive insights on the industry.',
    mainEntityOfPage = 'Main intelligence page',
    name = 'Finance Magnates Intelligence Products'
  }) =>
    `"@type": "Service",
	   "serviceType": "${serviceType}",
	   "alternateName": "${alternateName}",
	   "description": "${description}",
	   "mainEntityOfPage": "${mainEntityOfPage}",
    "name": "${name}"`,
  [PAGE_SCHEME_TYPE.PROFESSIONAL_SERVICE]: ({
    legalName = 'Finance Magnates',
    logoUrl = '',
    alternateName = 'QIR',
    description = 'QIR',
    imageUrl = '',
    mainEntityOfPage = 'Finance Magnates Products',
    name = 'finance Magnates Quarterly reports',
    url = ''
  }) =>
    `"@type": "ProfessionalService",
	   "legalName": "${legalName}",
	   "logo": {
	   	"@type": "ImageObject",
	   	"contentUrl": "${logoUrl}",
	   	"embedUrl": "${logoUrl}",
	   	"discussionUrl": "${logoUrl}",
	   	"isBasedOnUrl": "${logoUrl}",
	   	"thumbnailUrl": "${logoUrl}"
	   },
	   "alternateName": "${alternateName}",
	   "description": "${description}",
	   "image": {
	   	"@type": "ImageObject",
	   	"contentUrl": "${imageUrl}",
	   	"embedUrl": "${imageUrl}",
	   	"discussionUrl": "${imageUrl}",
	   	"url": "${imageUrl}"
	   },
	   "mainEntityOfPage": "${mainEntityOfPage}",
	   "name": "${name}",
	   "url": "${url}"`,
  [PAGE_SCHEME_TYPE.WEB_PAGE]: ({
    relatedLink = '',
    significantLink = '',
    alternativeHeadline = '',
    copyrightYear = getCurrentYear(),
    headline = '',
    isAccessibleForFree = true,
    keywords = '',
    text = '',
    thumbnailUrl = '',
    description = '',
    mainEntityOfPage = '',
    name = ''
  }) =>
    `"@type": "WebPage",
	   "relatedLink": "${relatedLink}",
	   "significantLink": "${significantLink}",
	   "alternativeHeadline": "${alternativeHeadline}",
	   "copyrightYear": "${copyrightYear}",
	   "headline": "${headline}",
	   "isAccessibleForFree": "${isAccessibleForFree}",
	   "keywords": "${keywords}",
	   "text": "${formatText(text)}",
	   "thumbnailUrl": "${thumbnailUrl}",
	   "description": "${description}",
	   "mainEntityOfPage": "${mainEntityOfPage}",
	   "name": "${name}"`,
  [PAGE_SCHEME_TYPE.CONTACT_PAGE]: ({
    relatedLink = '',
    significantLink = '',
    headline = 'Contact Us',
    isBasedOnUrl = '',
    keywords = 'Contact Us',
    text = 'Thank you for using Finance Magnates. Please fill out the quick form and we will be in touch with lightning speed',
    description = 'contact us page',
    name = '/contact-us'
  }) =>
    `"@type": "ContactPage",
	   "relatedLink": "${relatedLink}",
	   "significantLink": "${significantLink}",
	   "headline": "${headline}",
	   "isBasedOnUrl": "${isBasedOnUrl}",
	   "keywords": "${keywords}",
	   "text": "${formatText(text)}",
	   "description": "${description}",
	   "name": "${name}"`,
  [PAGE_SCHEME_TYPE.EVENT]: ({
    endDate = '',
    isAccessibleForFree = false,
    previousStartDate = '',
    startDate = '',
    alternateName = '',
    description = '',
    name = ''
  }) =>
    `"@type": "Event",
	   "endDate": "${endDate}",
	   "isAccessibleForFree": "${isAccessibleForFree}",
	   "previousStartDate": "${previousStartDate}",
	   "startDate": "${startDate}",
	   "alternateName": "${alternateName}",
	   "description": "${description}",
	   "name": "${name}"`,
  [PAGE_SCHEME_TYPE.PERSON]: ({ name = '', jobTitle = '', url = '' }) =>
    `"@type": "Person",
	   "name": "${name}",
	   "jobTitle": "${jobTitle}",
	   "url": "${url}"`,
  [PAGE_SCHEME_TYPE.VIDEO_GENERAL]: ({
    name = '',
    description = '',
    thumbnailUrl = '',
    uploadDate = '',
    embedUrl = ''
  }) => {
    const uploadDateFormatted = ensureUTCForDateWithoutTimezone(uploadDate)

    return `"@type": "VideoObject",
    "name": "${name}",
    "description": "${formatVideoDescription(description) || name}",
    "thumbnailUrl": "${thumbnailUrl}",
    "uploadDate": "${uploadDateFormatted}",
	  "embedUrl": "${embedUrl}"`
  }
}
