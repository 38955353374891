import render from 'posthtml-render'
import { MEDIA_ID_DATA_ATTRIBUTE } from '@fmpedia/enums'
import {
  generateParsedDomElementsHandlerDeep,
  getAltTextForMediaCenterImage
} from '@/plugins/helper'
import { HTML_TAG } from '@/utils/helpers/processHtml/enums'
import { ImageHtml } from '@/utils/helpers/processHtml/imageHtml'
import { parseHtml } from '@/utils/helpers/processHtml/parseHtml'
import {
  checkIfParsedElementIsFigure,
  checkIfParsedElementIsIframe
} from '@/utils/helpers/processHtml'
import mixins from '@/utils/mixins'

export const getMediaIdsFromHtml = html => {
  const imageMediaIds = []
  const videoMediaIds = []

  const parsedHtml = parseHtml(html)

  const populateMediaIdsHandlerSettings = [
    {
      conditionFn: el => el && el.tag && el.tag === HTML_TAG.FIGURE,
      handlerFn: el => {
        const mediaId = el.attrs[MEDIA_ID_DATA_ATTRIBUTE]

        if (mediaId) {
          imageMediaIds.push(el.attrs[MEDIA_ID_DATA_ATTRIBUTE])
        }
      }
    },
    {
      conditionFn: el => el && el.tag && el.tag === HTML_TAG.IFRAME,
      handlerFn: el => {
        const mediaId = el.attrs[MEDIA_ID_DATA_ATTRIBUTE]

        if (mediaId) {
          videoMediaIds.push(el.attrs[MEDIA_ID_DATA_ATTRIBUTE])
        }
      }
    }
  ]

  generateParsedDomElementsHandlerDeep(populateMediaIdsHandlerSettings)(
    parsedHtml
  )

  return {
    imageMediaIds,
    videoMediaIds
  }
}

export function getParsedHtmlWithReplacedMediaAttrs(
  html,
  { videos, images, getDefaultImageAlt }
) {
  const parsedHtml = parseHtml(html)

  const handlerSettings = [
    {
      conditionFn: el => checkIfParsedElementIsIframe(el) && el.attrs,
      handlerFn: el => {
        const { attrs } = el

        const dataMediaId = attrs[MEDIA_ID_DATA_ATTRIBUTE]
        if (dataMediaId) return el

        const videoMatch = (videos || []).find(
          video =>
            video.ExternalId ===
            mixins.urlFormatters.methods.getYoutubeVideoId(attrs.src)
        )

        if (!videoMatch) return el

        return {
          ...el,
          attrs: {
            ...attrs,
            [MEDIA_ID_DATA_ATTRIBUTE]: videoMatch.Id
          }
        }
      }
    },
    {
      conditionFn: el => checkIfParsedElementIsFigure(el),
      handlerFn: el => {
        const elemHtml = render([el])
        const newImage = new ImageHtml(elemHtml)

        const targetImageData = (images || []).find(
          img => img.MediaId === newImage.dataMediaId
        )

        if (!targetImageData) return el

        const defaultAlt =
          (getDefaultImageAlt &&
            typeof getDefaultImageAlt === 'function' &&
            getDefaultImageAlt(targetImageData)) ||
          ''

        const { Caption } = targetImageData

        newImage.altText =
          getAltTextForMediaCenterImage(targetImageData) || defaultAlt
        newImage.caption = Caption

        return newImage.getFinalImageHtml()
      }
    }
  ]

  return render(
    generateParsedDomElementsHandlerDeep(handlerSettings)(parsedHtml)
  )
}
