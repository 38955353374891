import { TAB_NAME } from 'enums/personal-area'

export const NEWS_TYPE = {
  COMPANY_NEWS: 'CompanyNews',
  TRADING_NEWS: 'TradingNews'
}

export const NEWS_TYPE_NAME = {
  [NEWS_TYPE.COMPANY_NEWS]: 'Company News',
  [NEWS_TYPE.TRADING_NEWS]: 'Trading News'
}

export const COMPANY_FEATURE = {
  SUGGEST_COMPANY_NEWS: 'SuggestCompanyNews',
  SUGGEST_TRADING_NEWS: 'SuggestTradingNews',
  SUGGEST_COMPANY_VIDEOS: 'SuggestCompanyVideos'
}

export const COMPANY_FEATURE_EXPIRATION_STATUS = {
  NONE: 'None',
  EXPIRING: 'Expiring',
  EXPIRED: 'Expired',
  SCHEDULED: 'Scheduled'
}

export const COMPANY_FEATURE_BY_NEWS_TYPE = {
  [NEWS_TYPE.COMPANY_NEWS]: COMPANY_FEATURE.SUGGEST_COMPANY_NEWS,
  [NEWS_TYPE.TRADING_NEWS]: COMPANY_FEATURE.SUGGEST_TRADING_NEWS
}

export const NEWS_TYPE_BY_COMPANY_FEATURE = Object.entries(
  COMPANY_FEATURE_BY_NEWS_TYPE
).reduce((acc, [key, value]) => ({ ...acc, [value]: key }), {})

export const COMPANY_NEWS_FEATURES = Object.values(COMPANY_FEATURE_BY_NEWS_TYPE)

export const COMPANY_ACTION = {
  VIEW: 'VIEW',
  VIEW_EDIT: 'VIEW_EDIT',
  RENEW: 'RENEW',
  REDIRECT_TO_COMPANY_NEWS: 'REDIRECT_TO_COMPANY_NEWS',
  REDIRECT_TO_COMPANY_VIDEOS: 'REDIRECT_TO_COMPANY_VIDEOS'
}

export const ALLOWED_COMPANY_ACTIONS_BY_ACTIVE_TAB = {
  [TAB_NAME.FM]: Object.values(COMPANY_ACTION),
  [TAB_NAME.FL]: [
    COMPANY_ACTION.VIEW,
    COMPANY_ACTION.VIEW_EDIT,
    COMPANY_ACTION.RENEW
  ]
}
