import { ROUTE_NAME } from 'enums/routes'

export default function({ store: { getters }, redirect, route }) {
  if (
    getters['auth/isContributorWithoutDetails'] &&
    route.name !== ROUTE_NAME.PERSONAL_PROFILE
  ) {
    redirect({ name: ROUTE_NAME.PERSONAL_PROFILE })
  }
}
