import { getMediaIdsFromHtml } from '@/utils/helpers/mediaProperties'
import { ROUTE_NAME } from 'enums/routes'
import { omit, pathOr } from 'ramda'
import {
  QUERY_PARAM_NAME,
  QUERY_PARAM_VALUE_BY_TAB_NAME
} from 'enums/personal-area'
import { processHtmlForPayload } from '@fmpedia/html-tools'

export default {
  methods: {
    $_companyProfile_generateBasePayload(companyDetails) {
      const Overview = processHtmlForPayload(companyDetails.fields.Overview)
      const Filters = companyDetails.filters.map(filter => ({
        Id: filter.Id,
        Values: filter.Values
      }))
      const Slug = companyDetails.WasPublished
        ? companyDetails.fields.Slug
        : this.$helper.generateSlugFromName(
            companyDetails.fields.NameSettings.Name
          )
      const { imageMediaIds, videoMediaIds } = getMediaIdsFromHtml(
        companyDetails.fields.Overview
      )
      const OriginalCompanySlug =
        this.$route.params.company || companyDetails.fields.Slug

      return {
        PinnedVideoSlug: companyDetails.fields.PinnedVideoSlug,
        ...omit(['PinnedVideoSlug'], companyDetails.fields),
        ...companyDetails.media,
        Email: companyDetails.media.Email || null,
        ...companyDetails.category,
        Overview,
        Filters,
        Slug,
        OverviewImageIds: imageMediaIds,
        OverviewVideoIds: videoMediaIds,
        ...(OriginalCompanySlug ? { OriginalCompanySlug } : {}),
        ...(this.OriginalCategoryId
          ? { OriginalCategoryId: this.OriginalCategoryId }
          : {})
      }
    },
    $_companyProfile_generatePayloadForCreateOrUpdateCompany(
      companyDetails,
      ImageId
    ) {
      const basePayload = omit(
        ['PinnedVideoSlug'],
        this.$_companyProfile_generateBasePayload(companyDetails)
      )

      return {
        ...basePayload,
        LogoSettings: {
          ...omit(['Image'], companyDetails.fields.LogoSettings),
          ImageId
        }
      }
    },
    $_companyProfile_generatePayloadForCompanyPreview(companyDetails) {
      const basePayload = this.$_companyProfile_generateBasePayload(
        companyDetails
      )

      const logoFile = pathOr(
        null,
        ['file'],
        companyDetails.fields.LogoSettings.Image
      )

      const formData = new FormData()
      formData.append('company', JSON.stringify(basePayload))
      if (logoFile) {
        formData.append('logo', logoFile)
      }
      return formData
    },
    $_companyProfile_redirectToMyCompanies(tabName, successMessage) {
      const query = {
        [QUERY_PARAM_NAME]: QUERY_PARAM_VALUE_BY_TAB_NAME[tabName]
      }

      this.$router.push({
        name: ROUTE_NAME.PERSONAL_PROFILE_MY_COMPANIES,
        params: {
          ...(successMessage
            ? { showSuccessMessage: true, successMessage }
            : {})
        },
        query
      })
    }
  }
}
