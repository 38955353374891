import { getImageSuffixFromUrlRegexp } from '@/plugins/helper'

export const IMAGE_BASE_64_REGEXP = /^data:image\/.+;base64,/

export const IMAGE_SIZE = {
  ORIGINAL: 'original',
  WIDTH_50: 'WIDTH_50',
  WIDTH_150: 'WIDTH_150',
  WIDTH_260: 'WIDTH_260',
  WIDTH_360: 'WIDTH_360',
  WIDTH_500: 'WIDTH_500',
  WIDTH_775: 'WIDTH_775',
  WIDTH_900: 'WIDTH_900',
  WIDTH_975: 'WIDTH_975'
}

export const IMAGE_SIZE_VALUE = {
  [IMAGE_SIZE.WIDTH_50]: 50,
  [IMAGE_SIZE.WIDTH_150]: 150,
  [IMAGE_SIZE.WIDTH_260]: 260,
  [IMAGE_SIZE.WIDTH_360]: 360,
  [IMAGE_SIZE.WIDTH_500]: 500,
  [IMAGE_SIZE.WIDTH_775]: 775,
  [IMAGE_SIZE.WIDTH_900]: 900,
  [IMAGE_SIZE.WIDTH_975]: 975
}

export const IMAGE_SUFFIX = {
  [IMAGE_SIZE.ORIGINAL]: '_original.',
  [IMAGE_SIZE.WIDTH_50]: '_size50.',
  [IMAGE_SIZE.WIDTH_150]: '_size150.',
  [IMAGE_SIZE.WIDTH_260]: '_size260.',
  [IMAGE_SIZE.WIDTH_360]: '_size360.',
  [IMAGE_SIZE.WIDTH_500]: '_size500.',
  [IMAGE_SIZE.WIDTH_775]: '_size775.',
  [IMAGE_SIZE.WIDTH_900]: '_size900.',
  [IMAGE_SIZE.WIDTH_975]: '_size975.'
}

export const BREAKPOINT_RANGE = {
  [IMAGE_SIZE.WIDTH_50]: [0, 50],
  [IMAGE_SIZE.WIDTH_150]: [51, 150],
  [IMAGE_SIZE.WIDTH_260]: [151, 260],
  [IMAGE_SIZE.WIDTH_360]: [261, 360],
  [IMAGE_SIZE.WIDTH_500]: [361, 500],
  [IMAGE_SIZE.WIDTH_775]: [501, 775],
  [IMAGE_SIZE.WIDTH_900]: [776, 900],
  [IMAGE_SIZE.WIDTH_975]: [901, 975],
  [IMAGE_SIZE.ORIGINAL]: [976, Infinity]
}

export const OVER_CONTENT_IMAGE_WIDTH = 2000

export const SRCSET_LOCATION = {
  ARTICLE_PAGE: 'ARTICLE_PAGE',
  AMP_ARTICLE_PAGE: 'AMP_ARTICLE_PAGE',
  HOMEPAGE_TOP_ARTICLE: 'HOMEPAGE_TOP_ARTICLE',
  HOMEPAGE_LATEST_NEWS: 'HOMEPAGE_LATEST_NEWS',
  AUTHOR_PAGE: 'AUTHOR_PAGE'
}

function getExtension(path) {
  if (!path) return ''

  return path.slice(path.lastIndexOf('.') + 1)
}

function generateSrcsetBreakpointData(imageSize, extension) {
  const width = BREAKPOINT_RANGE[imageSize][1]

  return {
    width,
    srcsetBaseLine: `${IMAGE_SUFFIX[imageSize]}${extension} ${width}w`
  }
}

function generateSrcsetDataForAllBreakpoints(url) {
  const extension = getExtension(url)
  return [
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_50, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_150, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_260, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_360, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_500, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_775, extension),
    generateSrcsetBreakpointData(IMAGE_SIZE.WIDTH_900, extension)
  ]
}

const SIZE_DATA_BY_SRCSET_LOCATION = {
  [SRCSET_LOCATION.HOMEPAGE_TOP_ARTICLE]: [
    { minWidth: 1920, imageWidth: 500 },
    { minWidth: 1434, maxWidth: 1919, imageWidth: 775 },
    { minWidth: 771, maxWidth: 1433, imageWidth: 500 },
    { minWidth: 768, maxWidth: 770, imageWidth: 360 },
    { minWidth: 521, maxWidth: 767, imageWidth: 775 },
    { minWidth: 381, maxWidth: 520, imageWidth: 500 },
    { minWidth: 281, maxWidth: 380, imageWidth: 360 },
    { minWidth: 171, maxWidth: 280, imageWidth: 260 },
    { minWidth: 71, maxWidth: 170, imageWidth: 150 },
    { maxWidth: 70, imageWidth: 50 }
  ],
  [SRCSET_LOCATION.HOMEPAGE_LATEST_NEWS]: [
    { minWidth: 1789, imageWidth: 360 },
    { minWidth: 1600, maxWidth: 1788, imageWidth: 260 },
    { minWidth: 1574, maxWidth: 1599, imageWidth: 500 },
    { minWidth: 1299, maxWidth: 1573, imageWidth: 360 },
    { minWidth: 1200, maxWidth: 1298, imageWidth: 260 },
    { minWidth: 1119, maxWidth: 1199, imageWidth: 360 },
    { minWidth: 1024, maxWidth: 1118, imageWidth: 260 },
    { minWidth: 771, maxWidth: 1023, imageWidth: 500 },
    { minWidth: 768, maxWidth: 770, imageWidth: 360 },
    { minWidth: 521, maxWidth: 767, imageWidth: 775 },
    { minWidth: 381, maxWidth: 520, imageWidth: 500 },
    { minWidth: 281, maxWidth: 380, imageWidth: 360 },
    { minWidth: 171, maxWidth: 280, imageWidth: 260 },
    { minWidth: 71, maxWidth: 170, imageWidth: 150 },
    { maxWidth: 70, imageWidth: 50 }
  ],
  [SRCSET_LOCATION.AMP_ARTICLE_PAGE]: [
    { minWidth: 521, imageWidth: 775 },
    { minWidth: 381, maxWidth: 520, imageWidth: 500 },
    { minWidth: 281, maxWidth: 380, imageWidth: 360 },
    { minWidth: 171, maxWidth: 280, imageWidth: 260 },
    { minWidth: 171, maxWidth: 280, imageWidth: 150 },
    { maxWidth: 170, imageWidth: 50 }
  ],
  [SRCSET_LOCATION.ARTICLE_PAGE]: [
    { minWidth: 1877, imageWidth: 975 },
    { minWidth: 1752, maxWidth: 1851, imageWidth: 900 },
    { minWidth: 1442, maxWidth: 1726, imageWidth: 775 },
    { minWidth: 1400, maxWidth: 1416, imageWidth: 500 },
    { minWidth: 1310, maxWidth: 1399, imageWidth: 975 },
    { minWidth: 1200, maxWidth: 1309, imageWidth: 900 },
    { minWidth: 1130, maxWidth: 1199, imageWidth: 975 },
    { minWidth: 1024, maxWidth: 1129, imageWidth: 900 },
    { minWidth: 946, maxWidth: 1023, imageWidth: 975 },
    { minWidth: 821, maxWidth: 945, imageWidth: 900 },
    { minWidth: 521, maxWidth: 820, imageWidth: 775 },
    { minWidth: 381, maxWidth: 520, imageWidth: 500 },
    { minWidth: 281, maxWidth: 380, imageWidth: 360 },
    { minWidth: 171, maxWidth: 280, imageWidth: 260 },
    { minWidth: 186, maxWidth: 170, imageWidth: 150 }
  ],
  [SRCSET_LOCATION.AUTHOR_PAGE]: [
    { minWidth: 768, imageWidth: 360 },
    { maxWidth: 767, imageWidth: 260 }
  ]
}

const IMAGE_PLACEHOLDER_SIZE = IMAGE_SIZE.WIDTH_50
const IMAGE_PLACEHOLDER_WIDTH = BREAKPOINT_RANGE[IMAGE_PLACEHOLDER_SIZE][1]

export class Srcset {
  constructor(settings) {
    const {
      originalUrl,
      srcsetLocation,
      maxWidth,
      isImagePlaceholder
    } = settings
    this._originalUrl = originalUrl || null
    this._srcsetBreakpointData = generateSrcsetDataForAllBreakpoints(
      this._originalUrl
    )
    this._srcsetLocation = srcsetLocation
    this._maxWidth = maxWidth || null
    this._isImagePlaceholder = isImagePlaceholder || false
  }
  get sizeData() {
    if (!this._srcsetLocation) return null

    return SIZE_DATA_BY_SRCSET_LOCATION[this._srcsetLocation]
  }
  get maxImageBreakpoint() {
    if (!this._maxWidth) return null

    const breakpoints = this._srcsetBreakpointData.map(({ width }) => width)

    return breakpoints
      .sort((a, b) => a - b)
      .find(breakpoint => this._maxWidth <= breakpoint)
  }
  getBinaryStringFromBooleanArray(arr) {
    return arr.reduce((acc, value) => acc + +!!value, '')
  }
  generateSrcsetLine() {
    if (!this._originalUrl) return null

    const mainUrlPart = this.getMainUrlPart(this._originalUrl)

    const maxImageBreakpoint = this._isImagePlaceholder
      ? IMAGE_PLACEHOLDER_WIDTH
      : this.maxImageBreakpoint

    const srcsetBaseLines = maxImageBreakpoint
      ? this._srcsetBreakpointData
          .filter(({ width }) => width <= maxImageBreakpoint)
          .map(({ srcsetBaseLine }) => srcsetBaseLine)
      : this._srcsetBreakpointData.map(({ srcsetBaseLine }) => srcsetBaseLine)

    return srcsetBaseLines
      .map(baseLine => `${mainUrlPart}${baseLine}`)
      .join(', ')
  }
  getMainUrlPart(url) {
    const regexp = getImageSuffixFromUrlRegexp(
      IMAGE_SUFFIX[IMAGE_SIZE.ORIGINAL]
    )
    return url.replace(regexp, '$1')
  }
  generateSizesLine() {
    if (!this.sizeData) return null

    const sizeData = this.maxImageBreakpoint
      ? this.sizeData.filter(({ imageWidth }) => {
          return imageWidth <= this.maxImageBreakpoint
        })
      : this.sizeData

    if (!sizeData.length) return `${IMAGE_PLACEHOLDER_WIDTH}px`

    return sizeData
      .map(({ minWidth, maxWidth, imageWidth }) => {
        const minWidthLine = minWidth ? `(min-width: ${minWidth}px)` : null
        const maxWidthLine =
          maxWidth && sizeData.length > 1 ? `(max-width: ${maxWidth}px)` : null
        const boolString = this.getBinaryStringFromBooleanArray([
          minWidthLine,
          maxWidthLine
        ])
        const resultMap = {
          '00': `${imageWidth}px`,
          '01': `${maxWidthLine} ${imageWidth}px`,
          '10': `${minWidthLine} ${imageWidth}px`,
          '11': `(${minWidthLine} and ${maxWidthLine}) ${imageWidth}px`
        }
        return resultMap[boolString]
      })
      .join(', ')
  }
}
