export const ARTICLE_METADATA_MODEL = {
  Id: '',
  Slug: '',
  TableData: null,
  PublishedOn: '',
  IsSaved: false,
  IsMigrated: false,
  IsDisclaimerVisible: false,
  Author: {
    Id: '',
    Slug: '',
    Name: '',
    Bio: '',
    PhotoUrl: '',
    Status: '',
    Role: '',
    IsFollowed: false,
    FollowersCount: 0,
    ArticlesCount: 0
  },
  SponsorshipCompany: null,
  Category: {
    Id: '',
    Name: '',
    Slug: '',
    ParentId: '',
    ParentName: '',
    ParentSlug: ''
  },
  AutomaticLink: null,
  Sticker: null,
  BodyImages: [],
  BodyVideos: [],
  Terms: [],
  Tags: []
}

export const ARTICLE_CONTENT_MODEL = {
  Body: '',
  Brief: '',
  Topic: '',
  TLDR: '',
  FeaturedImage: null,
  FeaturedImageLink: null,
  FeaturedVideo: null,
  PublishedOn: '',
  ModifiedOn: ''
}
