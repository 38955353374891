import api from '~/utils/api'
import * as types from '~/store/mutation-types'
import { SOURCE } from '@/utils/enums/source'

export const state = () => ({
  newNotificationCount: 0,
  isInitialNotificationCountCompleted: {
    [SOURCE.FM]: false,
    [SOURCE.FL]: false
  }
})

export const getters = {
  newNotificationCount: ({ newNotificationCount }) => newNotificationCount,
  isInitialNotificationCountReceived: ({
    isInitialNotificationCountCompleted
  }) =>
    isInitialNotificationCountCompleted[SOURCE.FM] &&
    isInitialNotificationCountCompleted[SOURCE.FL]
}

export const actions = {
  async requestBellIconItems() {
    try {
      const { data } = await this.$axios.get(
        api.notifications.getBellIconItems()
      )
      return data
    } catch (e) {
      throw e
    }
  },
  async requestMarkReadBellIcon() {
    try {
      await this.$axios.get(api.notifications.markReadBellIcon())
    } catch (e) {
      throw e
    }
  },
  async requestDeleteBellIconItem(_, payload) {
    try {
      await this.$axios.delete(api.notifications.deleteBellIconItem(), {
        data: payload
      })
    } catch (e) {
      throw e
    }
  },
  async requestClearAllBellIcon() {
    try {
      await this.$axios.delete(api.notifications.clearAllBellIcon())
    } catch (e) {
      throw e
    }
  },
  requestSetNewNotificationCount({ commit }, payload) {
    commit(types.SET_NEW_NOTIFICATION_COUNT, payload)
  },
  startInitialNotificationCountRequest({ commit }, { args }) {
    const { source } = args
    commit(types.SET_IS_INITIAL_NOTIFICATION_COUNT_REQUEST_COMPLETED, {
      source,
      value: false
    })
  },
  completeInitialNotificationCountRequest({ commit }, { args }) {
    const { source } = args
    commit(types.SET_IS_INITIAL_NOTIFICATION_COUNT_REQUEST_COMPLETED, {
      source,
      value: true
    })
  }
}

export const mutations = {
  [types.SET_NEW_NOTIFICATION_COUNT](state, count) {
    state.newNotificationCount = count
  },
  [types.SET_IS_INITIAL_NOTIFICATION_COUNT_REQUEST_COMPLETED](
    state,
    { source, value }
  ) {
    state.isInitialNotificationCountCompleted[source] = value
  }
}
