/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'bell-active': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data:
      '<path pid="0" class="cls-1" _fill="none" d="M4.86 0h22.28A4.93 4.93 0 0 1 32 5v22a4.93 4.93 0 0 1-4.85 5H4.86A4.92 4.92 0 0 1 0 27V5a4.92 4.92 0 0 1 4.86-5z"/><path pid="1" class="cls-2" _fill="#fff" d="M27.14 32H4.86A4.94 4.94 0 0 1 0 27V5a4.94 4.94 0 0 1 4.86-5h22.28A4.94 4.94 0 0 1 32 5v22a4.94 4.94 0 0 1-4.86 5zM4.86 1A3.93 3.93 0 0 0 1 5v22a3.93 3.93 0 0 0 3.86 4h22.28A3.93 3.93 0 0 0 31 27V5a3.93 3.93 0 0 0-3.86-4z"/><path pid="2" class="cls-2" _fill="#fff" d="M27.34 14.62c-.15.15-.28.35-.47.42a.65.65 0 0 1-.89-.63c-.06-.65-.08-1.3-.2-1.93a9.73 9.73 0 0 0-2.59-5c-.49-.51-.43-.92.18-1.25h.36a3.47 3.47 0 0 1 .42.34 11.09 11.09 0 0 1 3 5.77c.09.47.16.94.23 1.41zM8.63 6.22c.57.38.61.75.14 1.25A9.85 9.85 0 0 0 6 14.26c0 .44-.19.73-.5.8s-.58 0-.82-.44v-1.18a2.1 2.1 0 0 0 .06-.24 10.59 10.59 0 0 1 2-5.33 18.23 18.23 0 0 1 1.53-1.65zM19.44 22.94a3.53 3.53 0 0 1-6.93 0H9.8c-.83 0-1.09-.43-.71-1.19s.79-1.57 1.18-2.36a1 1 0 0 0 .07-.42c0-1.28-.05-2.57.07-3.83a5.34 5.34 0 0 1 2.94-4.27 1.17 1.17 0 0 0 .68-.74 2 2 0 0 1 2.1-1.35A2.12 2.12 0 0 1 18 10.34a.65.65 0 0 0 .29.36 5.48 5.48 0 0 1 2.94 3.18 8.35 8.35 0 0 1 .39 2.12 25.41 25.41 0 0 1 0 2.76A2.64 2.64 0 0 0 22 20c.3.63.62 1.25.93 1.88a.65.65 0 0 1-.62 1h-2.87zm1.85-1.34c-.23-.48-.44-.94-.68-1.38a2.43 2.43 0 0 1-.33-1.32v-3.25a4.33 4.33 0 0 0-3-3.9.68.68 0 0 1-.58-.77c0-.52-.31-.87-.74-.87a.78.78 0 0 0-.78.83c0 .53-.15.64-.66.84a4.1 4.1 0 0 0-2.83 3.52c-.12 1.22-.06 2.46-.06 3.68a2.16 2.16 0 0 1-.29 1.16c-.25.46-.47.95-.71 1.46zM18 23h-4a2.09 2.09 0 0 0 1.7 1.48A2.17 2.17 0 0 0 18 23zM24.51 14.13c0 .67-.23.95-.7.95a.66.66 0 0 1-.67-.71 6.18 6.18 0 0 0-.74-3.08 17.11 17.11 0 0 0-1.29-1.86c-.28-.36-.33-.74-.06-1a.68.68 0 0 1 1 .08 7.93 7.93 0 0 1 2.13 3.83c.16.58.23 1.19.33 1.79zM7.47 14.24A8.48 8.48 0 0 1 9.8 8.6a1.17 1.17 0 0 1 .4-.31.61.61 0 0 1 .8.25.65.65 0 0 1-.11.84 7.42 7.42 0 0 0-1.63 2.54 8.76 8.76 0 0 0-.47 2.52.6.6 0 0 1-.62.64.66.66 0 0 1-.69-.58 2.26 2.26 0 0 1-.01-.26z"/>'
  }
})
