import { mapGetters, mapActions } from 'vuex'
import { isNil } from 'ramda'

export const countryInfo = {
  computed: {
    ...mapGetters({
      countryInfo: 'countries/countryInfo'
    })
  },
  beforeMount() {
    this.$_countryInfo_requestCurrentCountryInfo()
  },
  methods: {
    ...mapActions({
      requestCurrentCountryInfo: 'countries/requestCurrentCountryInfo'
    }),

    async $_countryInfo_requestCurrentCountryInfo() {
      if (!isNil(this.countryInfo.Id)) return

      try {
        await this.requestCurrentCountryInfo()
      } catch (err) {
        this.$errorHandler(err, this)
      }
    }
  }
}
