import api from '@/utils/api'
import apiV2 from '@/utils/apiV2'

export const state = () => ({})

export const getters = {}

export const actions = {
  async requestUploadFmFile(_, { payload }) {
    try {
      const { data } = await this.$axiosV2.post(
        apiV2.images.uploadFmImage(),
        payload
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestUploadFmDirFile(_, { payload }) {
    try {
      const { data } = await this.$axios.post(
        api.directory.uploadFmDirFile(),
        payload
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestUploadFlFile(_, { payload }) {
    try {
      const { data } = await this.$flAxios.post(
        api.media.uploadFlFile(),
        payload
      )

      return data
    } catch (err) {
      throw err
    }
  },
  async requestUploadFlDirFile(_, { payload }) {
    try {
      const { data } = await this.$flAxios.post(
        api.directory.uploadFlDirFile(),
        payload
      )

      return data
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {}
