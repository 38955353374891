import { checkIfFunction } from '../helper'

const DRAGOVER_CLASS = 'dragover-in-progress'

const handler = new Map()

export function handleDragAndDrop(el, binding) {
  const boundElements = new Set()
  handler.set(el, checkIfFunction(binding.value) ? binding.value : null)

  function getHandler() {
    if (!handler.has(el)) return null

    return handler.get(el)
  }

  function onDragEnter(e) {
    if (!getHandler()) return

    e.preventDefault()
    const target = e.target

    if (!boundElements.size) {
      el.classList.add(DRAGOVER_CLASS)
    }
    boundElements.add(target)
  }

  function onDragLeave(e) {
    if (!getHandler()) return

    e.preventDefault()
    const target = e.target
    boundElements.delete(target)

    if (!boundElements.size) {
      el.classList.remove(DRAGOVER_CLASS)
    }
  }

  function preventDragoverDefault(e) {
    e.preventDefault()
  }

  function onDrop(e) {
    const handler = getHandler()
    if (!handler) return

    e.preventDefault()
    const file = e.dataTransfer.files[0]
    boundElements.clear()
    el.classList.remove(DRAGOVER_CLASS)

    if (!file) return

    handler(file)
  }

  el.addEventListener('dragenter', onDragEnter)
  el.addEventListener('dragleave', onDragLeave)
  el.addEventListener('drop', onDrop)
  el.addEventListener('dragover', preventDragoverDefault, false)
}

export function updateDragAndDropHandler(el, binding) {
  handler.set(el, checkIfFunction(binding.value) ? binding.value : null)
}
