import {
  COMPANY_FEATURE,
  COMPANY_NEWS_FEATURES,
  COMPANY_FEATURE_EXPIRATION_STATUS
} from '@/components/_sections/profile/DirectoryListings/enums'

export default {
  methods: {
    $_companyFeatures_getNewsFeaturesSettings(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return this.companyState.AutomatedFeatures.filter(({ Type }) =>
        COMPANY_NEWS_FEATURES.includes(Type)
      )
    },
    $_companyFeatures_getVideoFeatureSettings(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return this.companyState.AutomatedFeatures.find(
        ({ Type }) => Type === COMPANY_FEATURE.SUGGEST_COMPANY_VIDEOS
      )
    },
    $_companyFeatures_getFeaturesAvailability(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return Object.values(COMPANY_FEATURE).reduce(
        (acc, feature) => ({
          ...acc,
          [feature]: this.$_companyFeatures_isFeatureAvailable(
            companyState.AutomatedFeatures.find(({ Type }) => Type === feature)
          )
        }),
        {}
      )
    },
    $_companyFeatures_getNewsFeaturesAvailability(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return Object.entries(
        this.$_companyFeatures_getFeaturesAvailability(companyState)
      ).reduce(
        (acc, [feature, value]) => ({
          ...acc,
          ...(COMPANY_NEWS_FEATURES.includes(feature)
            ? { [feature]: value }
            : {})
        }),
        {}
      )
    },
    $_companyFeatures_isVideoFeatureAvailable(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return this.$_companyFeatures_getFeaturesAvailability(companyState)[
        COMPANY_FEATURE.SUGGEST_COMPANY_VIDEOS
      ]
    },
    $_companyFeatures_getAvailableNewsFeaturesCount(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return Object.values(
        this.$_companyFeatures_getNewsFeaturesAvailability(companyState)
      ).filter(value => value).length
    },
    $_companyFeatures_getAvailableNewsFeaturesSettings(
      companyState = this.companyState
    ) {
      if (!companyState) return

      return this.$_companyFeatures_getNewsFeaturesSettings(
        companyState
      ).filter(this.$_companyFeatures_isFeatureAvailable)
    },
    $_companyFeatures_isFeatureHasQuota(AvailableQuota) {
      return AvailableQuota > 0 || AvailableQuota === null
    },
    $_companyFeatures_isFeatureExpiring(ExpirationStatus) {
      return ExpirationStatus === COMPANY_FEATURE_EXPIRATION_STATUS.EXPIRING
    },
    $_companyFeatures_isFeatureExpiredOrScheduled(ExpirationStatus) {
      return [
        COMPANY_FEATURE_EXPIRATION_STATUS.EXPIRED,
        COMPANY_FEATURE_EXPIRATION_STATUS.SCHEDULED
      ].includes(ExpirationStatus)
    },
    $_companyFeatures_isFeatureAvailable({
      IsActive,
      AvailableQuota,
      ExpirationStatus
    } = {}) {
      return (
        IsActive &&
        this.$_companyFeatures_isFeatureHasQuota(AvailableQuota) &&
        !this.$_companyFeatures_isFeatureExpiredOrScheduled(ExpirationStatus)
      )
    },
    $_companyFeatures_isFeatureCanBeRenewed({
      IsActive,
      AvailableQuota,
      ExpirationStatus
    }) {
      return (
        !IsActive ||
        !this.$_companyFeatures_isFeatureHasQuota(AvailableQuota) ||
        this.$_companyFeatures_isFeatureExpiring(ExpirationStatus) ||
        this.$_companyFeatures_isFeatureExpiredOrScheduled(ExpirationStatus)
      )
    },
    $_companyFeatures_getIdsForNewsRenewal(companyState = this.companyState) {
      if (!companyState) return

      const newsFeaturesIds = this.$_companyFeatures_getNewsFeaturesSettings(
        companyState
      ).map(({ Id }) => Id)

      return [this.companyState.Id, ...newsFeaturesIds]
    },
    $_companyFeatures_getIdsForVideoRenewal(companyState = this.companyState) {
      if (!companyState) return

      const videoFeatureId = this.$_companyFeatures_getVideoFeatureSettings(
        companyState
      ).Id

      return [this.companyState.Id, videoFeatureId]
    }
  }
}
