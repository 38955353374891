import me from './me'
import directoryMe from './directoryMe'
import users from './users'
import socials from './socials'
import countries from './countries'
import tags from './tags'
import terms from './terms'
import categories from './categories'
import intelligence from './intelligence'
import marketing from './marketing'
import seo from './seo'
import videos from './videos'
import images from './images'
import layout from './layout'
import pages from './pages'
import articles from './articles'
import homepage from './homepage'

export default {
  me,
  directoryMe,
  users,
  socials,
  countries,
  tags,
  terms,
  categories,
  intelligence,
  marketing,
  seo,
  videos,
  articles,
  images,
  layout,
  pages,
  homepage
}
