import { LEGACY_SLUGS } from 'enums/articles'

function replaceFunction(match, encodedGroup, nonEncodedGroup) {
  if (encodedGroup) {
    // Match for "%XX" format
    return encodedGroup.toUpperCase()
  } else if (nonEncodedGroup) {
    // Match for single character
    return nonEncodedGroup.toLowerCase()
  } else {
    return match
  }
}

function convertToLowercaseExceptEncodedToUppercase(str) {
  if (!str) return str

  const regexpString = '(%\\w{2})|(.)'
  const regexp = new RegExp(regexpString, 'g')

  return str.replace(regexp, replaceFunction)
}

export function handleArticleSlug(slug) {
  const legacySlugIndex = LEGACY_SLUGS.findIndex(
    legacySlug => slug.toLowerCase() === legacySlug.toLowerCase()
  )

  if (legacySlugIndex >= 0) {
    return LEGACY_SLUGS[legacySlugIndex]
  }

  return convertToLowercaseExceptEncodedToUppercase(slug)
}
