import apiV2 from '@/utils/apiV2'
import { forceUrlFetch } from '@/plugins/helper'

import * as types from '@/store/mutation-types'

const COUNTRY_INFO_MODEL = {
  Id: null,
  Name: null,
  IsoCode: null,
  CallingCode: null
}

export const state = () => ({
  countryInfo: { ...COUNTRY_INFO_MODEL }
})

export const getters = {
  countryInfo: state => state.countryInfo
}

export const actions = {
  async requestCurrentCountryInfo({ commit }) {
    try {
      const {
        data: { Country }
      } = await this.$axiosV2.get(
        forceUrlFetch(apiV2.countries.getCurrentCountyInfo())
      )

      commit(types.SET_CURRENT_COUNTRY_INFO, Country || {})
      return Country
    } catch (err) {
      /**
       * In case of error, we assume it is not possible to fetch country code
       * for a provided IP address, so we set it as an empty string
       */
      commit(types.SET_CURRENT_COUNTRY_INFO, {})
      throw err
    }
  },
  async requestCountriesAutocomplete(_, { pathParams }) {
    try {
      const {
        data: { Countries }
      } = await this.$axiosV2.get(apiV2.countries.getCountriesAutocomplete(), {
        params: pathParams
      })

      return Countries
    } catch (err) {
      throw err
    }
  }
}

export const mutations = {
  [types.SET_CURRENT_COUNTRY_INFO](
    state,
    countryInfo = { ...COUNTRY_INFO_MODEL }
  ) {
    state.countryInfo = countryInfo
  }
}
