export const MIGRATED_CATEGORY_SLUG = {
  CRYPTOCURRENCY: 'Cryptocurrency',
  EDUCATION: 'Education',
  ORDERS: 'Orders',
  CENTRAL_BANKS: 'CentralBanks',
  TECHNICAL_ANALYSIS: 'technical-analysis',
  NEWS: 'News'
}

export const SESSION_WRAPS = 'session-wraps'

const MIGRATED_CATEGORY_SLUG_VALUES = Object.values(MIGRATED_CATEGORY_SLUG)

export function findMigratedCategorySlug(category) {
  return MIGRATED_CATEGORY_SLUG_VALUES.find(
    specialCategory =>
      specialCategory.toLowerCase() === (category && category.toLowerCase())
  )
}

export const ARTICLE_CATEGORY_SLUG_BY_CATEGORY_SLUG = {
  [MIGRATED_CATEGORY_SLUG.CRYPTOCURRENCY]: 'cryptocurrency',
  [MIGRATED_CATEGORY_SLUG.EDUCATION]: 'education',
  [MIGRATED_CATEGORY_SLUG.ORDERS]: 'orders',
  [MIGRATED_CATEGORY_SLUG.CENTRAL_BANKS]: 'centralbank',
  [MIGRATED_CATEGORY_SLUG.NEWS]: 'news'
}

const ARTICLE_CATEGORY_SLUG_BY_LOWERCASE_CATEGORY_SLUG = Object.entries(
  ARTICLE_CATEGORY_SLUG_BY_CATEGORY_SLUG
).reduce((acc, [key, val]) => ({ ...acc, [key.toLowerCase()]: val }), {})

export function findArticleCategorySlugByCategorySlug(category = '') {
  return ARTICLE_CATEGORY_SLUG_BY_LOWERCASE_CATEGORY_SLUG[
    category.toLowerCase()
  ]
}
