/**
 * This utility ensures banners are requested in SRA (single request architecture) mode.
 * The main idea is to postpone registering banner slots until all ABanner components
 * are initialized. Once all instances are ready, we can request banners in SRA by
 * calling "googletag.display" for a given chunk of slots.
 * To ensure banners are refreshed in SRA as well, we postpone refreshing in a similar way.
 */
import { debounce } from 'debounce'
import { splitEvery } from 'ramda'
import { BIDS, PREBID_TIMEOUT } from 'enums/banners/header-bidding'

const DEBOUNCE_DELAY = 50
const MAX_BANNERS_ALLOWED_FOR_SINGLE_REQUEST = 30

export const sraBannerHandler = {
  settingsForBannersToDisplay: [],
  bannerSlotsToRefresh: [],
  registerRefreshBannerSlot(slot) {
    this.bannerSlotsToRefresh.push(slot)
    this.refreshBannersDebounced()
  },
  refreshBannersDebounced: debounce(function() {
    const slotChunksToRefresh = splitEvery(
      MAX_BANNERS_ALLOWED_FOR_SINGLE_REQUEST,
      this.bannerSlotsToRefresh
    )

    slotChunksToRefresh.forEach(chunk => {
      this.refreshBannersChunkInSRA(chunk)
    })

    this.bannerSlotsToRefresh = []
  }, DEBOUNCE_DELAY),
  refreshBannersChunkInSRA(slotChunk) {
    const adUnits = this.createAdUnits(slotChunk)
    this.refreshBids({ adUnits, slots: slotChunk })
  },
  registerDisplayBannerHandler(payload) {
    if (
      this.settingsForBannersToDisplay.find(
        settings => settings.bannerId === payload.bannerId
      )
    ) {
      return
    }

    this.settingsForBannersToDisplay.push(payload)
    this.displayBannersDebounced()
  },
  displayBannersDebounced: debounce(function() {
    const payloadsChunksToDisplay = splitEvery(
      MAX_BANNERS_ALLOWED_FOR_SINGLE_REQUEST,
      this.settingsForBannersToDisplay
    )

    payloadsChunksToDisplay.forEach(chunk => {
      this.displayBannersChunkInSRA(chunk)
    })

    this.settingsForBannersToDisplay = []
  }, DEBOUNCE_DELAY),
  createAdUnits(slots) {
    return slots.map(slot => {
      const { width, height } = slot.getSizes()[0]

      return {
        code: slot.getAdUnitPath(),
        mediaTypes: {
          banner: {
            sizes: [width, height]
          }
        },
        bids: BIDS
      }
    })
  },
  refreshBids({ adUnits, slots }) {
    const pbjs = window.pbjs || {}
    pbjs.que = pbjs.que || []

    pbjs.que.push(function() {
      pbjs.addAdUnits(adUnits)
    })

    pbjs.que.push(function() {
      pbjs.requestBids({
        timeout: PREBID_TIMEOUT,
        adUnitCodes: slots.map(slot => slot.getAdUnitPath()),
        bidsBackHandler: function() {
          pbjs.setTargetingForGPTAsync(slots.map(slot => slot.getAdUnitPath()))
          window.googletag.pubads().refresh(slots)
        }
      })
    })
  },
  displayBannersChunkInSRA(chunk) {
    const slots = []

    chunk.forEach(payload => {
      const slot = payload.handler()

      if (!slot) return

      slots.push(slot)
    })

    const adUnits = this.createAdUnits(slots)

    this.refreshBids({ adUnits, slots })
  }
}
