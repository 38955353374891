import { mapActionsLazy, mapGettersLazy } from '@/plugins/helper'
import { FOLLOWED_ITEM } from '@/utils/enums/personal-area'
import { SOURCE } from 'enums/source'
import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'

export const followInterests = {
  data() {
    return {
      followFmActions: {
        [FOLLOWED_ITEM.TAGS]: {
          FOLLOW: this.requestFollowFmTags,
          UNFOLLOW: this.requestUnfollowFmTags
        },
        [FOLLOWED_ITEM.CATEGORIES]: {
          FOLLOW: this.requestFollowFmCategories,
          UNFOLLOW: this.requestUnfollowFmCategories
        },
        [FOLLOWED_ITEM.AUTHORS]: {
          FOLLOW: this.requestFollowFmAuthors,
          UNFOLLOW: this.requestUnfollowFmAuthors
        },
        [FOLLOWED_ITEM.COMPANIES]: {
          FOLLOW: this.requestFollowFmCompanies,
          UNFOLLOW: this.requestUnfollowFmCompanies
        }
      },
      followFlActions: {
        [FOLLOWED_ITEM.TAGS]: {
          FOLLOW: this.requestFollowFlTags,
          UNFOLLOW: this.requestUnfollowFlTags
        },
        [FOLLOWED_ITEM.CATEGORIES]: {
          FOLLOW: this.requestFollowFlCategories,
          UNFOLLOW: this.requestUnfollowFlCategories
        },
        [FOLLOWED_ITEM.AUTHORS]: {
          FOLLOW: this.requestFollowFlAuthors,
          UNFOLLOW: this.requestUnfollowFlAuthors
        },
        [FOLLOWED_ITEM.COMPANIES]: {
          FOLLOW: this.requestFollowFlCompanies,
          UNFOLLOW: this.requestUnfollowFlCompanies
        }
      }
    }
  },
  computed: {
    ...mapGettersLazy({
      profileSettings: 'personal-area/profileSettings'
    }),
    $_followInterests_followedTagCount() {
      return this.$_followInterests_getFollowedItemsCount(
        this.profileSettings.Tags
      )
    },
    $_followInterests_followedAuthorCount() {
      return this.$_followInterests_getFollowedItemsCount(
        this.profileSettings.Authors
      )
    },
    $_followInterests_followedCompanyCount() {
      return this.$_followInterests_getFollowedItemsCount(
        this.profileSettings.Companies
      )
    },
    $_followInterests_followedCategoryCount() {
      return this.profileSettings.ParentCategories.reduce(
        (acc, parentCategory) =>
          acc +
          this.$_followInterests_getFollowedItemsCount(
            parentCategory.Categories
          ),
        0
      )
    },
    $_followInterests_countArray() {
      return [
        this.$_followInterests_followedTagCount,
        this.$_followInterests_followedAuthorCount,
        this.$_followInterests_followedCompanyCount,
        this.$_followInterests_followedCategoryCount
      ]
    },
    $_followInterests_isFollowedCountsReceived() {
      return this.$_followInterests_countArray.every(value => value !== null)
    },
    $_followInterests_isNoFollowedItems() {
      return this.$_followInterests_countArray.every(value => value === 0)
    },
    $_followInterests_followedActionsBySource() {
      return {
        [SOURCE.FM]: this.followFmActions,
        [SOURCE.FL]: this.followFlActions
      }
    }
  },
  methods: {
    ...mapActionsLazy({
      requestFollowFmTags: 'personal-area/requestFollowFmTags',
      requestFollowFlTags: 'personal-area/requestFollowFlTags',
      requestFollowFmCompanies: 'personal-area/requestFollowFmCompanies',
      requestFollowFlCompanies: 'personal-area/requestFollowFlCompanies',
      requestFollowFmAuthors: 'personal-area/requestFollowFmAuthors',
      requestFollowFlAuthors: 'personal-area/requestFollowFlAuthors',
      requestFollowFmCategories: 'personal-area/requestFollowFmCategories',
      requestFollowFlCategories: 'personal-area/requestFollowFlCategories',
      requestUnfollowFmTags: 'personal-area/requestUnfollowFmTags',
      requestUnfollowFlTags: 'personal-area/requestUnfollowFlTags',
      requestUnfollowFmCompanies: 'personal-area/requestUnfollowFmCompanies',
      requestUnfollowFlCompanies: 'personal-area/requestUnfollowFlCompanies',
      requestUnfollowFmAuthors: 'personal-area/requestUnfollowFmAuthors',
      requestUnfollowFlAuthors: 'personal-area/requestUnfollowFlAuthors',
      requestUnfollowFmCategories: 'personal-area/requestUnfollowFmCategories',
      requestUnfollowFlCategories: 'personal-area/requestUnfollowFlCategories'
    }),
    $_followInterests_getFollowedItemsCount(items) {
      return items.filter(({ IsFollowed }) => IsFollowed).length
    },
    async $_followInterests_toggleFmCategoryItem(
      parentCategory,
      category,
      isFollowed
    ) {
      const selectedCategoriesCount = parentCategory.Categories.filter(
        ({ IsFollowed }) => IsFollowed
      ).length
      const isAddedFirstCategory = selectedCategoriesCount === 0 && isFollowed
      const isRemovedLastCategory = selectedCategoriesCount === 1 && !isFollowed

      try {
        const Ids = [
          category.Id,
          ...(isAddedFirstCategory || isRemovedLastCategory
            ? [parentCategory.ParentCategoryId]
            : [])
        ]
        await (isFollowed
          ? this.followFmActions[FOLLOWED_ITEM.CATEGORIES].FOLLOW({
              payload: { Ids }
            })
          : this.followFmActions[FOLLOWED_ITEM.CATEGORIES].UNFOLLOW({
              payload: { Ids }
            }))
        this.$emit('refresh-profile-summary')
      } catch (err) {
        throw err
      }
    },
    $_followInterests_getIdsOnParentCategoryToggle(parentCategory, isFollowed) {
      return [
        ...parentCategory.Categories.filter(
          ({ IsFollowed }) => IsFollowed !== isFollowed
        ).map(({ Id }) => Id),
        ...(parentCategory.IsFollowed === isFollowed
          ? []
          : [parentCategory.ParentCategoryId])
      ]
    },
    async $_followInterests_toggleFmParentCategoryItem(
      parentCategory,
      isFollowed
    ) {
      try {
        const Ids = this.$_followInterests_getIdsOnParentCategoryToggle(
          parentCategory,
          isFollowed
        )
        await (isFollowed
          ? this.followFmActions[FOLLOWED_ITEM.CATEGORIES].FOLLOW({
              payload: { Ids }
            })
          : this.followFmActions[FOLLOWED_ITEM.CATEGORIES].UNFOLLOW({
              payload: { Ids }
            }))
        this.$emit('refresh-profile-summary')
      } catch (err) {
        throw err
      }
    },
    async $_followInterests_toggleFlCategoryItem(category, isFollowed) {
      try {
        const Ids = [category.ParentCategoryId]
        await (isFollowed
          ? this.followFlActions[FOLLOWED_ITEM.CATEGORIES].FOLLOW({
              payload: { Ids }
            })
          : this.followFlActions[FOLLOWED_ITEM.CATEGORIES].UNFOLLOW({
              payload: { Ids }
            }))
        this.$emit('refresh-profile-summary')
      } catch (err) {
        throw err
      }
    },
    async $_followInterests_toggleFmFollowingItem(itemName, id, isFollowed) {
      try {
        const Ids = Array.isArray(id) ? [...id] : [id]
        await (isFollowed
          ? this.followFmActions[itemName].FOLLOW({
              payload: { Ids }
            })
          : this.followFmActions[itemName].UNFOLLOW({
              payload: { Ids }
            }))
        this.$emit('refresh-profile-summary')
      } catch (err) {
        throw err
      }
    },
    async $_followInterests_toggleFlFollowingItem(itemName, id, isFollowed) {
      try {
        const Ids = Array.isArray(id) ? [...id] : [id]
        await (isFollowed
          ? this.followFlActions[itemName].FOLLOW({
              payload: { Ids }
            })
          : this.followFlActions[itemName].UNFOLLOW({
              payload: { Ids }
            }))
        this.$emit('refresh-profile-summary')
      } catch (err) {
        throw err
      }
    }
  }
}

export const tabs = {
  mixins: [hydrationHelpers],
  methods: {
    $_tabs_generateTabName(baseText, itemCount) {
      if (itemCount && !this.$_hydrationHelpers_isLayoutMobile) {
        return `${baseText} (${itemCount})`
      }
      return baseText
    }
  }
}
