import { flatten } from 'ramda'

import { breakpointNames } from '@/utils/scss'
import { WIDE_SKY_BANNERS_BREAKPOINT_NAME } from 'enums/banners/banner-settings'

/**
 * This function checks "sizes" key in banner settings and returns the dimensions
 * that fit all the provided sizes (max width and max height).
 * Sizes value can be an Array of Arrays:
 * - [
 *     [120, 600],
 *     [160, 600],
 *     [300, 600]
 *   ]
 * In such a case we calculate max width and max height: 300x600
 *
 * Sizes value can be an Array of numbers:
 * sizes: [320, 50]
 * In such a case we get Array elements as width and height
 *
 * If we faced "fluid" width, we additionally return fluid: true
 *
 */
function getDimensionsToFit(arrayOfSizes = []) {
  return arrayOfSizes.reduce(
    (acc, sizes) => {
      if (!sizes || !Array.isArray(sizes)) {
        return acc
      }

      const [width, height] = sizes
      if (!Number.isInteger(width) || !Number.isInteger(height)) {
        return {
          ...acc,
          fluid: acc.fluid || width === 'fluid'
        }
      }

      return {
        width: Math.max(acc.width, width),
        height: Math.max(acc.height, height),
        fluid: acc.fluid
      }
    },
    { width: 0, height: 0, fluid: false }
  )
}

function generateBannersSizeStringsByBreakpoint(bannerSettings) {
  const result = Object.entries(bannerSettings).reduce(
    (acc, [location, settings]) => {
      const resultPerLocation = Object.entries(settings).reduce(
        (locationAcc, [instanceName, locationSettings]) => {
          const resultPerBreakpoint = Object.entries(locationSettings).reduce(
            (breakpointAcc, [breakpoint, breakpointSettings]) => {
              const sizes = breakpointSettings.sizes.every(
                size => !Array.isArray(size)
              )
                ? [breakpointSettings.sizes]
                : breakpointSettings.sizes
              const { width, height, fluid } = getDimensionsToFit(sizes)

              const fluidString = `${breakpoint}|fluid`

              if (!width || !height) {
                if (fluid) {
                  return [...breakpointAcc, fluidString]
                }
                return breakpointAcc
              }

              const sizeString = `${width}|${height}`
              const resultString = `${breakpoint}|${sizeString}`
              return [
                ...breakpointAcc,
                resultString,
                ...(fluid ? [fluidString] : [])
              ]
            },
            []
          )
          return new Set([...locationAcc, ...resultPerBreakpoint])
        },
        new Set()
      )
      return new Set([...acc, ...resultPerLocation])
    },
    new Set()
  )
  const sortedResult = [...result].sort()

  return sortedResult.reduce((acc, bannerString) => {
    const [breakpointName, width, height] = bannerString.split('|')
    const sizeString = width === 'fluid' ? 'Fluid' : `${width}x${height}`
    return {
      ...acc,
      [breakpointName]: [...(acc[breakpointName] || []), sizeString]
    }
  }, {})
}

export function createScssBannerVars(parsedResult) {
  const flattened = flatten(Object.values(parsedResult))
  const withoutDuplicates = new Set(flattened)
  const final = [...withoutDuplicates].filter(
    sizeString => sizeString !== 'Fluid'
  )

  return (
    '\n' +
    final
      .map(sizeString => {
        return `$size${sizeString}: 'size${sizeString}';`
      })
      .join('\n')
  )
}

function createScssBannerSizeMap(parsedResult) {
  const flattened = flatten(Object.values(parsedResult))
  const withoutDuplicates = new Set(flattened)
  const final = [...withoutDuplicates].filter(
    sizeString => sizeString !== 'Fluid'
  )
  const scssBannerSizes = final
    .map(sizeString => {
      const [width, height] = sizeString.split('x')
      return `  $size${sizeString}: setSizeData(${width}, ${height}),`
    })
    .join('\n')

  const specificSizes = `\n  $sizeFluid: setSizeData(100%, auto),
  $size1x1: setSizeData(100%, auto)`

  return `\n$bannerSize: (\n${scssBannerSizes}${specificSizes}\n)`
}

export function createScssMapForBannerClasses(parsedResult) {
  const scssParseMap = {
    '$wide-sky-banners': WIDE_SKY_BANNERS_BREAKPOINT_NAME,
    '$above-desktop-xl': breakpointNames.aboveDesktopXl,
    '$desktop-xl': breakpointNames.desktopXl,
    '$desktop-lg': breakpointNames.desktopLg,
    '$desktop-md': breakpointNames.desktopMd,
    '$desktop-sm': breakpointNames.desktopSm,
    $tablet: breakpointNames.tablet,
    $mobile: breakpointNames.mobile
  }

  const resultString = Object.entries(scssParseMap)
    .map(([scssBreakpointName, breakpointName]) => {
      const settings = parsedResult[breakpointName]
      const valuesString = settings
        .map(sizeString => `    $size${sizeString}`)
        .join(',\n')
      const rangeScssString = `\n ${scssBreakpointName}: (\n`
      const endString = `\n )`
      return `${rangeScssString}${valuesString}${endString}`
    })
    .join(',')
  return `\n$bannerUsage: (${resultString}
);`
}

const logBannerSettings = false
export function printScssBannerSettings(bannerSettings) {
  const parsedResult = generateBannersSizeStringsByBreakpoint(bannerSettings)

  const scssVars = createScssBannerVars(parsedResult)
  const bannerSizeMap = createScssBannerSizeMap(parsedResult)
  const bannerUsageMap = createScssMapForBannerClasses(parsedResult)

  if (logBannerSettings) {
    console.log('scssVars (_banner.scss):', scssVars)
    console.log('bannerSizeMap (_banner.scss): ', bannerSizeMap)
    console.log('bannerUsageMap: (ABanner/index.vue)', bannerUsageMap)
  }
}
