import { mapGetters } from 'vuex'

import { hydrationHelpers } from '@/utils/mixins/hydrationHelpers'
import { SIDE_BANNERS_SETTINGS_BY_PAGE } from '@/utils/helpers/sideBannersMovement/enums'

export default {
  mixins: [hydrationHelpers],
  data() {
    return {
      isResizeInProgress: false
    }
  },
  computed: {
    ...mapGetters({
      rectDataByDataRef: 'dom/rectDataByDataRef'
    }),
    $_sideBannerMovement_settings() {
      return SIDE_BANNERS_SETTINGS_BY_PAGE[this.$route.name]
    },
    $_sideBannerMovement_containerHeights() {
      if (!process.client || !this.$_sideBannerMovement_settings) return []

      const { handlers, prepareRectData } = this.$_sideBannerMovement_settings
      const rectData = prepareRectData(this.rectDataByDataRef)

      return handlers.map(handler => handler(rectData))
    }
  }
}
