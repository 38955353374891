import * as types from '@/store/mutation-types'
import Vue from 'vue'

/**
 * This module's purpose is to always have an easy access to the current state of
 * any Jodit instance to define if it requires some time to update its data.
 * The issue that implies the need for such approach can be found here:
 * https://adraba.atlassian.net/browse/FMP-10556
 */
const state = () => ({
  readyStateByJoditId: {}
})

const getters = {
  allJoditInstancesReady: ({ readyStateByJoditId }) => () =>
    Promise.all(Object.values(readyStateByJoditId)),
  getJoditInstanceReadyById: ({ readyStateByJoditId }) => joditId =>
    readyStateByJoditId[joditId]
}

const mutations = {
  [types.SET_JODIT_READY_STATE_PROMISE](state, payload) {
    state.readyStateByJoditId[payload.id] = payload.promise
  },
  [types.REMOVE_JODIT_READY_STATE_PROMISE](state, id) {
    Vue.delete(state.readyStateByJoditId, id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
