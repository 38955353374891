export const DFP_BANNER = {
  TOP_SIDE_1920x368: {
    path: '/150277392/FMPedia_FM/Super_Leaderboard',
    sizes: [
      [1920, 368],
      [1140, 90],
      [1100, 100],
      [980, 90],
      [980, 120],
      [970, 90],
      [970, 250],
      [970, 120],
      [950, 90],
      [930, 180],
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100],
      ['fluid']
    ],
    id: 'div-gpt-ad-Top_Side-1920x368'
  },
  TOP_SIDE_1140x280: {
    path: '/150277392/FMPedia_FM/Super_Leaderboard',
    sizes: [
      [1140, 90],
      [1100, 100],
      [980, 90],
      [980, 120],
      [970, 90],
      [970, 250],
      [970, 120],
      [950, 90],
      [930, 180],
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100],
      ['fluid']
    ],
    id: 'div-gpt-ad-Top_Side-1140x100'
  },
  TOP_SIDE_980x280: {
    path: '/150277392/FMPedia_FM/Super_Leaderboard',
    sizes: [
      [980, 90],
      [980, 120],
      [970, 90],
      [970, 250],
      [970, 120],
      [950, 90],
      [930, 180],
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100],
      ['fluid']
    ],
    id: 'div-gpt-ad-Top_Side-980x250'
  },
  TOP_SIDE_750x280: {
    path: '/150277392/FMPedia_FM/Super_Leaderboard',
    sizes: [
      [750, 200],
      [750, 100],
      [728, 90],
      [728, 250],
      [728, 100],
      ['fluid']
    ],
    id: 'div-gpt-ad-Top_Side-750x250'
  },
  TOP_SIDE_320x100: {
    path: '/150277392/FMPedia_FM/Super_Leaderboard',
    sizes: [[320, 50], [320, 100], ['fluid']],
    id: 'div-gpt-ad-Top_Side-320_100'
  },
  RIGHT_SIDE_1_300x600: {
    path: '/150277392/FMPedia_FM/Super_Right_1',
    sizes: [
      [120, 600],
      [160, 600],
      [300, 600]
    ],
    id: 'div-gpt-ad-Super_Right_1-300x600'
  },
  RIGHT_SIDE_1_160x600: {
    path: '/150277392/FMPedia_FM/Super_Right_1',
    sizes: [
      [120, 600],
      [160, 600]
    ],
    id: 'div-gpt-ad-Super_Right_1-160x600'
  },
  RIGHT_SIDE_1_120x600: {
    path: '/150277392/FMPedia_FM/Super_Right_1',
    sizes: [[120, 600]],
    id: 'div-gpt-ad-Super_Right_1-120x600'
  },
  RIGHT_SIDE_2_300x600: {
    path: '/150277392/FMPedia_FM/Super_Right_2',
    sizes: [
      [120, 600],
      [160, 600],
      [300, 600]
    ],
    id: 'div-gpt-ad-Super_Right_2-300x600'
  },
  RIGHT_SIDE_2_160x600: {
    path: '/150277392/FMPedia_FM/Super_Right_2',
    sizes: [
      [120, 600],
      [160, 600]
    ],
    id: 'div-gpt-ad-Super_Right_2-160x600'
  },
  RIGHT_SIDE_2_120x600: {
    path: '/150277392/FMPedia_FM/Super_Right_2',
    sizes: [[120, 600]],
    id: 'div-gpt-ad-Super_Right_2-120x600'
  },
  TOP_LEADERBOARD_320x50: {
    path: '/150277392/FMPedia_FM/Top_Leaderboard',
    sizes: [320, 50],
    id: 'div-gpt-ad-Top_Leaderboard-320x50'
  },
  CUSTOM_BOX_1_215x155: {
    path: '/150277392/FMPedia_FM/Custom_Box_1',
    sizes: [215, 155],
    id: 'div-gpt-ad-Custom_Box_1-215x155'
  },
  CUSTOM_BOX_1_250x180: {
    path: '/150277392/FMPedia_FM/Custom_Box_1',
    sizes: [250, 180],
    id: 'div-gpt-ad-Custom_Box_1-250x180'
  },
  CUSTOM_BOX_1_300x250: {
    path: '/150277392/FMPedia_FM/Custom_Box_1',
    sizes: [300, 250],
    id: 'div-gpt-ad-Custom_Box_1-300x250'
  },
  LEADERBOARD_1_728x90: {
    path: '/150277392/FMPedia_FM/Leaderboard_1',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_1-728x90'
  },
  LEADERBOARD_1_468x60: {
    path: '/150277392/FMPedia_FM/Leaderboard_1',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_1-468x60'
  },
  LEADERBOARD_1_320x50: {
    path: '/150277392/FMPedia_FM/Leaderboard_1',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_1-320x50'
  },
  LEADERBOARD_2_728x90: {
    path: '/150277392/FMPedia_FM/Leaderboard_2',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_2-728x90'
  },
  LEADERBOARD_2_468x60: {
    path: '/150277392/FMPedia_FM/Leaderboard_2',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_2-468x60'
  },
  LEADERBOARD_2_320x50: {
    path: '/150277392/FMPedia_FM/Leaderboard_2',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_2-320x50'
  },
  LEADERBOARD_3_728x90: {
    path: '/150277392/FMPedia_FM/Leaderboard_3',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_3-728x90'
  },
  LEADERBOARD_3_468x60: {
    path: '/150277392/FMPedia_FM/Leaderboard_3',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_3-468x60'
  },
  LEADERBOARD_3_320x50: {
    path: '/150277392/FMPedia_FM/Leaderboard_3',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_3-320x50'
  },
  LEADERBOARD_4_728x90: {
    path: '/150277392/FMPedia_FM/Leaderboard_4',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_4-728x90'
  },
  LEADERBOARD_4_468x60: {
    path: '/150277392/FMPedia_FM/Leaderboard_4',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_4-468x60'
  },
  LEADERBOARD_4_320x50: {
    path: '/150277392/FMPedia_FM/Leaderboard_4',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_4-320x50'
  },
  LEADERBOARD_5_728x90: {
    path: '/150277392/FMPedia_FM/Leaderboard_5',
    sizes: [728, 90],
    id: 'div-gpt-ad-Leaderboard_5-728x90'
  },
  LEADERBOARD_5_468x60: {
    path: '/150277392/FMPedia_FM/Leaderboard_5',
    sizes: [468, 60],
    id: 'div-gpt-ad-Leaderboard_5-468x60'
  },
  LEADERBOARD_5_320x50: {
    path: '/150277392/FMPedia_FM/Leaderboard_5',
    sizes: [320, 50],
    id: 'div-gpt-ad-Leaderboard_5-320x50'
  },
  MOBILE_STICKY_320x50: {
    path: '/150277392/FMPedia_FM/Mobile_Sticky',
    sizes: [320, 50],
    id: 'div-gpt-ad-Mobile_Sticky-320x50'
  },
  MPU_1_300x250: {
    path: '/150277392/FMPedia_FM/MPU_1',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_1-320x250'
  },
  // this banner is not in use at the moment, but we kept it in case
  // client decides to bring it back
  MPU_2_300x250: {
    path: '/150277392/FMPedia_FM/MPU_2',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_2-320x250'
  },
  INTERSTITIAL_970x330: {
    path: '/150277392/FMPedia_FM/Interstitial',
    sizes: [970, 330],
    id: 'div-gpt-ad-Interstitial-970x330'
  },
  INTERSTITIAL_300x250: {
    path: '/150277392/FMPedia_FM/Interstitial',
    sizes: [300, 250],
    id: 'div-gpt-ad-Interstitial-300x250'
  },
  MPU_MIDDLE_ARTICLE_300x250: {
    path: '/150277392/FM_AMP_articles/MPU_middle_article',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_middle_article-300x250'
  },
  // this banner is not in use at the moment, but we kept it in case
  // client decides to bring it back
  MPU_BELOW_ARTICLE_300x250: {
    path: '/150277392/FM_AMP_articles/MPU_below_article',
    sizes: [300, 250],
    id: 'div-gpt-ad-MPU_below_article-300x250'
  },
  MOBILE_STICKY_BANNER_320x50: {
    path: '/150277392/FM_AMP_articles/Mobile_sticky_banner',
    sizes: [320, 50],
    id: 'div-gpt-ad-Mobile_sticky_banner-320x50'
  },
  ARTICLE_BELOW_CONTENT_FLUID: {
    path: '/150277392/FMPedia_FM/FM_BelowContent',
    sizes: ['fluid'],
    id: 'div-gpt-ad-1645534589317-0'
  }
}

export const DFP_BANNER_MOCKED = {
  MOCKED_320X50: {
    path: '',
    sizes: [320, 50],
    id: ''
  },
  MOCKED_728X90: {
    path: '',
    sizes: [728, 90],
    id: ''
  },
  MOCKED_970X90: {
    path: '',
    sizes: [970, 90],
    id: ''
  },
  MOCKED_120x600: {
    path: '',
    sizes: [120, 600],
    id: ''
  },
  MOCKED_160x600: {
    path: '',
    sizes: [160, 600],
    id: ''
  },
  MOCKED_300x600: {
    path: '',
    sizes: [300, 600],
    id: ''
  },
  MOCKED_300x250: {
    path: '',
    sizes: [300, 250],
    id: ''
  },
  MOCKED_180x150: {
    path: '',
    sizes: [180, 150],
    id: ''
  }
}

// const breakpoints = Object.keys(scss.breakpoints)
//
// function createProxy(bannerObject) {
//   return new Proxy(bannerObject, {
//     get: (obj, key) => {
//       if (breakpoints.includes(key)) {
//         return {
//           width: obj[key][0],
//           height: obj[key][1],
//           id: obj.DFP_SETTINGS[`${obj[key][0]}x${obj[key][1]}`].id
//         }
//       }
//
//       if (typeof obj[key] === 'object') return createProxy(obj[key])
//
//       return obj[key]
//     }
//   })
// }
