const me = {
  slug: 'f/me',
  changePassword: () => `${me.slug}/password`,
  resetPassword: () => `${me.slug}/password/reset`,
  confirmPassword: () => `${me.slug}/password/confirm`,
  deactivateUserProfile: () => `${me.slug}/profile/deactivate`,
  getProfileInfo: () => `${me.slug}/profile`,
  uploadProfilePhoto: () => `${me.slug}/profile/photo`,
  completeUserOnboardingFlow: () => `${me.slug}/onboarding/complete`,
  registerUserSession: () => `${me.slug}/sessions`,
  requestUserProfileReactivation: () => `${me.slug}/profile/reactivation/email`,
  reactivateUserProfile: () => `${me.slug}/profile/reactivation/email/confirm`,
  confirmBecomeAContributorFlow: () =>
    `${me.slug}/promotion/contributor/confirm`,
  confirmBecomeASponsoredContributorFlow: () =>
    `${me.slug}/promotion/sponsored-contributor/confirm`,
  updateAnnouncementSubscription: () => `${me.slug}/announcements`,
  reactivateFacebookUserProfile: () =>
    `${me.slug}/profile/reactivation/facebook`,
  reactivateTwitterUserProfile: () => `${me.slug}/profile/reactivation/twitter`,
  reactivateGoogleUserProfile: () => `${me.slug}/profile/reactivation/google`,
  getIntelligencePurchases: () => `${me.slug}/intelligence/purchases`,
  getSearchHistory: () => `${me.slug}/search/history`,
  saveSearchHistory: () => `${me.slug}/search/history`,
  deleteSearchHistoryRecords: () => `${me.slug}/search/history`,
  clearSearchHistory: () => `${me.slug}/search/history/all`,
  followTags: () => `${me.slug}/followings/tags`,
  followCategories: () => `${me.slug}/followings/categories`,
  followAuthors: () => `${me.slug}/followings/authors`,
  unfollowTags: () => `${me.slug}/followings/tags`,
  unfollowCategories: () => `${me.slug}/followings/categories`,
  unfollowAuthors: () => `${me.slug}/followings/authors`,
  saveArticleForLater: articleId => `${me.slug}/saved-articles/${articleId}`,
  deleteSavedArticle: articleId => `${me.slug}/saved-articles/${articleId}`,
  clearAllSavedArticles: () => `${me.slug}/saved-articles`,
  getPostBySlug: slug => `${me.slug}/posts/${slug}`,
  savePost: () => `${me.slug}/posts`,
  updatePost: slug => `${me.slug}/posts/${slug}`,
  deletePost: slug => `${me.slug}/posts/${slug}`
}

export default me
