<template>
  <div v-if="!isCalculationComplete" class="char-width__wrapper">
    <span
      v-for="char in CHAR_LIST"
      :key="`${FONT.SANS_13_BOLD}${char}`"
      :ref="FONT.SANS_13_BOLD"
      class="char-width__char bold"
      >{{ char }}</span
    >
    <span
      v-for="char in CHAR_LIST"
      :key="`${FONT.SANS_13}${char}`"
      :ref="FONT.SANS_13"
      class="char-width__char"
      >{{ char }}</span
    >
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

import * as types from '@/store/mutation-types'
import { FONT } from 'shared/ACharWidth/enums'

/* ASCII CODES */
const START_CODE = 32
const END_CODE = 126

const CHAR_LIST = new Array(END_CODE - START_CODE)
  .fill()
  .map((_, index) => String.fromCharCode(index + START_CODE))

export default {
  name: 'ACharWidth',
  data() {
    return { CHAR_LIST, FONT, isCalculationComplete: false }
  },
  methods: {
    ...mapMutations({
      setCharSizeMap: types.SET_CHAR_SIZE
    }),
    getSizeMapByFont(font) {
      return this.$refs[font]
        .map(ref => parseFloat(window.getComputedStyle(ref).width))
        .reduce(
          (acc, width, index) => ({
            ...acc,
            [CHAR_LIST[index]]: width
          }),
          {}
        )
    }
  },
  mounted() {
    const sans13Bold = this.getSizeMapByFont(FONT.SANS_13_BOLD)
    const sans13 = this.getSizeMapByFont(FONT.SANS_13)

    const resultMap = {
      [FONT.SANS_13_BOLD]: sans13Bold,
      [FONT.SANS_13]: sans13
    }
    this.setCharSizeMap(resultMap)
    this.isCalculationComplete = true
  }
}
</script>

<style lang="scss" scoped>
.char-width__wrapper {
  position: absolute;
  visibility: hidden;
  top: 0;
  left: 0;

  .char-width__char {
    font-size: 13px;
    line-height: 17px;
    display: inline-block;
    white-space: pre;
  }
}
</style>
